import { IAssetList, IAsset } from '../../../types/maptrac/Assets'
import { ITag } from '../../../types/maptrac/Tags'
import { getType } from '../../ux/ServicePopover'
import { IMaintenance } from '../filters/FilterMaptrac'
import { AssetTabAsset } from '../../inventory/AssetsTabTypes'

export function FilterAssetsByCategory(
  list: IAssetList[],
  searchString: string
) {
  searchString = searchString.toLowerCase()
  return list.filter((assetList) =>
    assetList.assets.find((asset) =>
      asset.asset_info?.asset_name.toLowerCase().includes(searchString)
    )
  )
}

export function FilterAssetsByName(list: IAssetList[], searchString: string) {
  searchString = searchString.toLowerCase()
  return list.map((assetList) => {
    return {
      category: assetList.category,
      assets: assetList.assets.filter((asset) =>
        asset.asset_info?.asset_name.toLowerCase().includes(searchString)
      ),
      tags: assetList.tags,
    }
  })
}

export function FilterAssetsByMaintenance(
  list: IAssetList[],
  maintenance: IMaintenance
) {
  return list.map((assetList) => {
    return {
      category: assetList.category,
      assets: filterAssetsMaintenanceMarker(
        assetList.assets,
        maintenance.red.filter,
        maintenance.orange.filter,
        maintenance.blue.filter
      ),
      tags: assetList.tags,
    }
  })
}

/**
 * This filter Function handles the Asset Filtering on the Drawer
 */
export function filterAssetsDrawer(
  list: IAssetList[],
  searchString: string,
  maintenance: IMaintenance
) {
  return FilterAssetsByMaintenance(
    FilterAssetsByName(
      FilterAssetsByCategory(list, searchString),
      searchString
    ),
    maintenance
  )
}

/**
 * This filter Function handles the Asset Filtering on the Map also used as the length calc
 */
export function filterAssetsMarker(assets: AssetTabAsset[], searchString: string) {
  searchString = searchString.toLowerCase()
  return assets.filter((asset) =>
    asset.asset_info?.asset_name.toLowerCase().includes(searchString)
  )
}

export function filterAssetsMaintenanceMarker(
  assets: AssetTabAsset[],
  red: boolean,
  orange: boolean,
  blue: boolean
) {
  const All = red && orange && blue
  return assets.filter((asset) => {
    const tasks = asset.maintenance_task_performeds
    return tasks.length > 0
      ? tasks.find((task) =>
          All
            ? true
            : getType(task.scheduleRatio) === 'red'
            ? red
              ? true
              : All
            : getType(task.scheduleRatio) === 'orange'
            ? orange
              ? true
              : All
            : getType(task.scheduleRatio) === 'blue'
            ? blue
              ? true
              : All
            : All
        )
      : All
  })
}

/**
 * Filter out Tags
 */
export function filterTagMarkers(list: Array<ITag[]>, searchString: string) {
  let fakeTags = [] as Array<ITag[]>
  list.forEach((tagList: ITag[]) =>
    fakeTags.push(
      tagList.filter((a) =>
        a.tagName.toLowerCase().includes(searchString.toLowerCase())
      )
    )
  )
  return fakeTags
}
