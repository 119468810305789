import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Button from '../buttons/Button'
import EllipsisText from '../ux/EllipsisText'
import { backend_api, headersAPI } from '../../constants/api'
import { LoadingIcon } from '../LoadingOverlay'
import ReturnToInventoryDialog from './dialogs/ReturnToInventoryDialog'

interface IAssetArchivedSold {
  name: string
  id: string
}

interface IProps {}

interface IState {
  loadingSold: boolean
  ArchiveDataReinstate: IAssetArchivedSold[]
  returnDialogOpen: boolean
  assetSelected?: IAssetArchivedSold
}

class SoldEquipmentTable extends React.Component<IProps, IState> {
  state: IState = {
    loadingSold: true,
    ArchiveDataReinstate: [],
    returnDialogOpen: false,
  }

  async grabSoldData() {
    await fetch(backend_api + '/devices/sold', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          ArchiveDataReinstate: response,
          loadingSold: false,
        })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.grabSoldData()
  }

  render() {
    return (
      <>
        <Typography
          variant="h6"
          style={{ paddingTop: 10, paddingLeft: 20, display: 'flex' }}
        >
          Sold Equipment
          {this.state.loadingSold ? (
            <div style={{ marginLeft: 10 }}>
              <LoadingIcon loading />
            </div>
          ) : null}
        </Typography>

        <Paper style={{ margin: 10, overflow: 'hidden' }}>
          <List disablePadding>
            {this.state.ArchiveDataReinstate.map((val, key) => (
              <List disablePadding key={key}>
                <ListItem>
                  <ListItemText
                    primary={
                      <EllipsisText text={val.name}>
                        <Link href="/">{val.name}</Link>
                      </EllipsisText>
                    }
                  />

                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() =>
                      this.setState({
                        assetSelected: val,
                        returnDialogOpen: true,
                      })
                    }
                  >
                    Send back to inventory
                  </Button>
                </ListItem>
                <Divider />
              </List>
            ))}
          </List>
        </Paper>
        {this.state.assetSelected ? (
          <ReturnToInventoryDialog
            dialogOpen={this.state.returnDialogOpen}
            onClose={() =>
              this.setState({
                returnDialogOpen: false,
                assetSelected: undefined,
              })
            }
            assetId={this.state.assetSelected.id}
            assetName={this.state.assetSelected.name}
          />
        ) : null}
      </>
    )
  }
}

export default SoldEquipmentTable
