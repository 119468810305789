import React, {useState} from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Avatar from '@mui/material/Avatar'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import { IUser } from '../../../types/account/user'
import ButtonSideIcon from '../../buttons/ButtonSideIcon'
import { backend_api, headersAPI } from '../../../constants/api'

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxHeight: 400,
    },
    avatar: {
      background: theme.palette.primary.main,
      color: 'white',
    },
  })

const AssignUserDialog = (props: {
  includeUnassignOption: boolean
  selectedAssetIds: string[]
  users: IUser[]
  classes: any
  onSubmit: (args: {assetIds: string[], userId: string|null}) => void
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  function handleClose() {
    setDialogOpen(false)
  }

  function handleOpen() {
    setDialogOpen(true)
  }

  function handleSubmit(userId: string|null) {
    props.onSubmit({
      userId,
      assetIds: props.selectedAssetIds,
    })
    handleClose()
  }

  return (
    <>
      <ButtonSideIcon
        startIcon="user"
        label="Assign User"
        onClick={handleOpen}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        PaperProps={{
          className: props.classes.paper,
        }}
      >
        <DialogTitle>Assign User</DialogTitle>
        <div>
          <List>
            {props.includeUnassignOption && (
              <ListItem button onClick={() => {handleSubmit(null)}}>
                <ListItemText primary="- No User -" />
              </ListItem>
            )}
            {props.users.map((user) => (
              <ListItem
                key={user.id}
                button
                onClick={() => {handleSubmit(user.id)}}
              >
                <ListItemAvatar>
                  <Avatar className={props.classes.avatar}>
                    {user.first_name?.charAt(0) + user.last_name?.charAt(0)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${user.first_name} ${user.last_name}`}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Dialog>
    </>
  )
}

export default withStyles(styles)(AssignUserDialog)
