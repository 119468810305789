import React, { useState } from 'react'
import '../../styles/components/BranchMenu.scss'
import ButtonPopover from '../buttons/ButtonPopover'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import BranchList from './BranchList'
import { ERoutes } from '../routes/CheckRoutes'
import { Branch } from '../../types/tenant'

const BranchMenu = (props: {
  title: string
  branches: Branch[]
  currentBranch?: Branch
  setCurrentBranchId: (branchId: string) => void
}) => {
  return (
    <ButtonPopover
      title={props.title}
      icon="account"
      variant="text"
      buttonMinWidth={180}
    >
      <div className="branchMenu">
        <ListSubheader color="inherit">{props.title}</ListSubheader>

        <Divider />

        {
          props.currentBranch
          ? (<BranchList
              branches={props.branches}
              currentBranch={props.currentBranch}
              setCurrentBranchId={props.setCurrentBranchId}
            />)
          : null
        }

        <Divider />

        <List className="branchList">
          <ListSubheader>Manage</ListSubheader>
          <ListItem
            button
            href={ERoutes.account}
            id="roviBranchMenuManageAccount"
          >
            <ListItemText primary="Account" />
          </ListItem>
          <ListItem
            button
            href={ERoutes.archive}
            id="roviBranchMenuManageArchive"
          >
            <ListItemText primary="Archive" />
          </ListItem>
          <ListItem
            button
            href={ERoutes.attribute}
            id="roviBranchMenuManageAttributes"
          >
            <ListItemText primary="Attributes" />
          </ListItem>
          <ListItem
            button
            href={ERoutes.categories}
            id="roviBranchMenuManageCategories"
          >
            <ListItemText primary="Categories" />
          </ListItem>
          <ListItem
            button
            href={ERoutes.flag}
            id="roviBranchMenuManageFlags"
          >
            <ListItemText primary="Flags" />
          </ListItem>
          <ListItem
            button
            href={ERoutes.subscriptions}
            id="roviBranchMenuManageSubscriptions"
          >
            <ListItemText primary="Subscriptions" />
          </ListItem>
          <ListItem
            button
            href={ERoutes.tutorials}
            id="roviBranchMenuManageTutorials"
          >
            <ListItemText primary="Tutorials" />
          </ListItem>
        </List>
      </div>
    </ButtonPopover>
  )
}

export default BranchMenu
