import { gql, useQuery, useMutation } from '@apollo/client'
import {
  CognitoUserSession,
} from 'amazon-cognito-identity-js'
import moment from 'moment'

import {
  IAssetList,
} from '../types/maptrac/Assets'
import {
  AssetTabAsset,
} from '../components/inventory/AssetsTabTypes'

import { ILatLng } from '../types/maptrac/LatLng'
import {
  AmplifyUser,
} from '../types/account/user'

import {
  getAssets,
} from './asset'

import {
  getBranches,
  getCurrentBranch,
} from './tenant'

import {
  getCategoriesWithAssetCount,
} from './category'

import {
  getGeofences,
} from './geofence'

import {
  getCurrentUser,
} from './user'

export function getMaptracData(
  amplifyUser: AmplifyUser|null,
  amplifySession: CognitoUserSession|null,
  date: Date,
  isLive: boolean,
) {
  const {user} = getCurrentUser(amplifySession)

  const {
    categories,
    loadingCategories,
  } = getCategoriesWithAssetCount(amplifyUser)

  // Assets
  const {branches} = getBranches(amplifyUser)
  const {currentBranch, setNewCurrentBranch} = getCurrentBranch(user, branches, amplifyUser)
  const {assets, assetsLoading} = getAssets(amplifyUser, currentBranch?.id)

  const assetMapByCategory: Record<string, IAssetList> = [
    ...categories,
    {
      id: null,
      name: 'Uncategorized',
      icon: null,
      color: 'grey',
    }
  ].reduce<Record<string, IAssetList>>(
    (map, category) => {
      return {
        ...map,
        [category.id]: {
          category,
          assets: [],
          tags: [],
        },
      }
    }, {}
  )

  const assetMapByCategoryWithAssets = assets.reduce<Record<string, IAssetList>>(
    (map, asset) => {
      const categoryId = asset.asset_info?.category?.id || null
      const assetList = map[categoryId] || map[null]
      return {
        ...map,
        [categoryId]: {
          ...assetList,
          assets: [
            ...assetList.assets,
            asset,
          ],
        }
      }
    }, assetMapByCategory
  )
  const assetsList = Object.values(assetMapByCategoryWithAssets)
  const assetMarkers = []

  const assetLatLng = assets.reduce<ILatLng[]>((array, asset) => {
    const snapshot = asset.snapshots?.[0]
    if (!snapshot) {
      return array
    }
    const gps = snapshot?.estimated_lat_lng
    if (!gps) {
      return array
    }

    return [
      ...array,
      gps,
    ]
  }, [])

  const startOfDay = moment(date).startOf('day').toDate()
  const endOfDay = moment(date).endOf('day').toDate()
  const {
    timeline,
    loading: loadingTimeline,
  } = getAssetMetricsByDate(
    amplifyUser,
    currentBranch?.id,
    startOfDay,
    endOfDay,
    assets,
    !isLive,
  )

  // Geofences
  const {geofences, loadingGeofences} = getGeofences(amplifyUser, currentBranch?.id)

  return {
    geofences,
    loadingGeofences,
    assets,
    assetsLoading,
    assetsList,
    assetLatLng,
    branches,
    currentBranch,
    categories,
    timeline,
    loadingTimeline,
  }
}

const GET_ASSET_METRICS = gql`
query GetAssetMetricsByDay(
  $tenant_id: uuid!
  $startDate: timestamptz!
  $endDate: timestamptz!
) {
  messages_gps(where: {
    tenant_id: {_eq: $tenant_id}
    timestamp: {
      _gt: $startDate
      _lt: $endDate
    }
  }) {
    asset_id
    device_id
    latitude
    longitude
    altitude
    speed
    heading
    satellites
    gps_accuracy
    timestamp
  }

  messages_battery_message(where: {
    tenant_id: {_eq: $tenant_id}
    timestamp: {
      _gt: $startDate
      _lt: $endDate
    }
  }) {
    asset_id
    device_id
    batteryvoltage
    batterypercentage
    timestamp
  }

  messages_inputs(where: {
    tenant_id: {_eq: $tenant_id}
    timestamp: {
      _gt: $startDate
      _lt: $endDate
    }
  }) {
    asset_id
    device_id
    ignition
    inputone
    inputtwo
    inputthree
    inputfour
    timestamp
  }
}
`

function getAssetMetricsByDate(
  amplifyUser: AmplifyUser|null,
  branchId: string,
  startOfDay: Date,
  endOfDay: Date,
  assets: AssetTabAsset[],
  shouldLoad: boolean,
) {
  const { loading, error, data } = useQuery(GET_ASSET_METRICS, {
    variables: {
      tenant_id: amplifyUser?.['custom:tenantID'],
      branch_id: branchId,
      startDate: startOfDay,
      endDate: endOfDay,
    },
    skip: !amplifyUser
      || !branchId
      || !shouldLoad
  })

  if (!data) {
    return {
      timeline: [],
      loading,
    }
  }

  const timeline = assets.reduce<Record<string, {asset: any, timeline: any[]}>>(
    (map, asset) => {
      return {
        ...map,
        [asset.id]: {
          asset,
          timeline: {},
        },
      }
    },
    {}
  )

  const ATTRIBUTES_TO_IGNORE = [
    'timestamp',
    'asset_id',
    'device_id',
  ]

  function addEventToTimeline(event: {
    asset_id: string,
    device_id: string,
    timestamp: string,
  }) {
    const timelineAsset = timeline[event.asset_id]
    const timelineEvent = timelineAsset.timeline[event.timestamp]
    for (const [attribute, value] of Object.entries(event)) {
      if (ATTRIBUTES_TO_IGNORE.includes(attribute)) {
        continue
      }
      timelineEvent[attribute] = value
    }
  }

  for (const event of data.messages_gps) {
    addEventToTimeline(event)
  }

  for (const event of data.messages_battery_message) {
    addEventToTimeline(event)
  }

  for (const event of data.messages_inputs) {
    addEventToTimeline(event)
  }

  return {
    timeline,
    loading,
  }
}
