import React, {useState} from 'react'
import '../../styles/components/userProfile/NotificationsForm.scss'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import MUICheckbox from '@mui/material/Checkbox'
import TextField from '../textBoxs/TextField'
import Button from '../buttons/Button'

import {
  IUser,
  UserSettings,
} from '../../types/account/user'

const NotificationsForm = (props: {
  user: IUser
  onSave: (settings: {
    isEmailChecked: boolean
    isSmsChecked: boolean
    smsNumber: string
  }) => void
}) => {
  const settings = props.user?.settings || {} as UserSettings
  const [isEmailChecked, setIsEmailChecked] = useState(settings.isEmailChecked || false)
  const [isSmsChecked, setIsSmsChecked] = useState(settings.isSmsChecked || false)
  const [smsNumber, setSmsNumber] = useState(settings.smsNumber || '')

  function save(newSettings: UserSettings) {
    props.onSave(newSettings)
  }

  return (
    <div className="NotificationsFormContainer">
      <FormControl className="NotificationsFormCheckBox">
        <FormLabel>Email</FormLabel>
        <FormControlLabel
          control={
            <MUICheckbox
              checked={isEmailChecked}
              onChange={() => {
                // There appears to be a timing problem where isEmailChecked
                // isn't updated when setIsEmailChecked() is run
                setIsEmailChecked(!isEmailChecked)
                save({
                  ...settings,
                  isEmailChecked: !isEmailChecked,
                })
              }}
              color="primary"
            />
          }
          label={`Send notifications via email to: ${props.user.email}`}
        />
      </FormControl>
      <FormControl className="NotificationsFormCheckBox">
        <FormLabel>Text Messages (SMS)</FormLabel>
        <FormControlLabel
          control={
            <MUICheckbox
              checked={isSmsChecked}
              onChange={() => {
                // There appears to be a timing problem where isEmailChecked
                // isn't updated when isSmsChecked() is run
                setIsSmsChecked(!isSmsChecked)
                save({
                  ...settings,
                  isSmsChecked: !isSmsChecked,
                })
              }}
              color="primary"
            />
          }
          label={`Send notifications via text messages to: ${smsNumber}`}
        />
      </FormControl>

      {isSmsChecked ? (
        <div className="NotifSMSNumber">
          to
          <TextField
            required
            name="tel"
            label="Phone Number"
            autoComplete="tel"
            type="tel"
            style={{ marginLeft: 5 }}
            value={smsNumber}
            onChange={(event) => {
              setSmsNumber(event.target.value)
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => save({
              ...settings,
              smsNumber,
            })}
          >
            Save
          </Button>
        </div>
      ) : null}
    </div>
  )
}

export default NotificationsForm
