import React from 'react'
import Button from '../buttons/Button'
import Popover from '@mui/material/Popover'
import Icon from '../Icon'
import IconButton from '../buttons/IconButton'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { IEquipmentProfile } from '../../types/equipment/equipmentProfile'
import { ILatLng } from '../../types/maptrac/LatLng'
import { Weather } from '../../types/weather'
import moment from 'moment'

interface IProps {
  className?: string
  weather: {
    summary: string
    temperature: number
    sunlightTime: string | null
    cloudCover: number | null
  } | null
  useMetric: boolean
  equipmentProfile: IEquipmentProfile
}

export default class WeatherPopup extends React.Component<IProps> {
  state = {
    anchorEl: null,
    open: false,
  }

  handlePopoverOpen = (event: { currentTarget: any }) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    return (
      <>
        <Button
          size="small"
          onClick={this.handlePopoverOpen}
          startIcon={<Icon icon="cloud" />}
        >
          <Typography variant="caption">
            {this.props.weather ? (
              <>71.6 {this.props.useMetric ? '℃' : '℉'}</>
            ) : (
              'No forcast'
            )}
          </Typography>
        </Button>

        <Popover
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ padding: 10, maxWidth: 300 }}>
            {this.props.weather ? (
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-around' }}
                >
                  <IconButton icon="cloud" variant="row" />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography>
                      {this.props.weather.summary} / 71.6{' '}
                      {this.props.useMetric ? '℃' : '℉'}
                    </Typography>
                    <Typography variant="caption" component="p">
                      Weather
                    </Typography>
                  </div>
                </div>

                {this.props.weather.sunlightTime && (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    <IconButton icon="cloud" variant="row" />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography>10:57 AM hours</Typography>
                      <Typography variant="caption" component="p">
                        Todays Sunlight
                      </Typography>
                    </div>
                  </div>
                )}

                {this.props.weather.cloudCover != null && (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    <IconButton icon="cloud" variant="row" />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography>{this.props.weather.cloudCover}%</Typography>
                      <Typography variant="caption" component="p">
                        Cloud Coverage
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Typography>no forcast</Typography>
            )}
          </div>
        </Popover>
      </>
    )
  }
}

interface ITState {
  weatherData?: Weather
}

export class WeatherTooltip extends React.Component<IProps, ITState> {
  state: ITState = {}
  weatherBalloon(gps: ILatLng) {
    let key = '77ed9f3692dc34abbd133c1af58d6d93'
    fetch(
      `https://api.openweathermap.org/data/2.5/onecall?lat=${gps.lat}&lon=${gps.lng}&appid=${key}`,
      {
        method: 'GET',
        redirect: 'follow',
      }
    )
      .then((response) => response.json())
      .then((res) => this.setState({ weatherData: res }))
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    const defaultGps = {
      lat: 0,
      lng: 0,
    }
    this.weatherBalloon(this.props.equipmentProfile?.gps || defaultGps)
  }
  render() {
    const weatherData = this.state.weatherData
    return weatherData ? (
      <div>
        {this.props.weather && (
          <Tooltip
            title={`
              ${weatherData.current.weather[0].description}
              ${
                this.props.weather.sunlightTime &&
                ' / ' +
                  moment(weatherData.current.dt)
                    .utcOffset(weatherData.timezone_offset)
                    .format('LT')
              }
              ${this.props.weather.cloudCover && ' / '}
              ${
                this.props.weather.cloudCover &&
                weatherData.current.clouds + '% CloudCoverage'
              }
            `}
          >
            <Typography
              variant="caption"
              style={{ paddingRight: 10, cursor: 'default' }}
            >
              71.6 {this.props.useMetric ? '℃' : '℉'}
            </Typography>
          </Tooltip>
        )}
      </div>
    ) : null
  }
}
