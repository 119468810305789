import React, {useState} from 'react'
import '../styles/components/UserMenu.scss'
import IconButtonPopover from './buttons/IconButtonPopover'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import Icon from './Icon'
import { signOut } from './signOut'
import { IUser } from '../types/account/user'

const UserMenu = (props: {
  title: string
  accountName: string
  user: IUser
}) => {
  const [anchorEl, setAnchorEl] = useState(null as any|null)

  return (
    <IconButtonPopover icon="session" title={props.title}>
      <div>
        <ListSubheader>{props.accountName}</ListSubheader>
        <div className="userInfoStyle">
          {props.user.first_name} {props.user.last_name}
          <br />
          {props.user.email}
        </div>

        <Divider />

        <List className="sessionMenu">
          <ListItem
            button
            href="/profile"
            style={{ color: 'rgba(0, 0, 0, 0.87)' }} // needed to avoid the color change that was created globally for this
          >
            <ListItemIcon>
              <Icon icon="userProfile" />
            </ListItemIcon>
            <ListItemText primary="User Profile" />
          </ListItem>
          <ListItem
            button
            onClick={() => signOut()}
            style={{ color: 'rgba(0, 0, 0, 0.87)' }} // needed to avoid the color change that was created globally for this
          >
            <ListItemIcon>
              <Icon icon="signOut" />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItem>
        </List>
      </div>
    </IconButtonPopover>
  )
}

export default UserMenu
