import { gql, useQuery, useMutation } from '@apollo/client'
import {
  AmplifyUser,
} from '../types/account/user'
import {
  AssetTabAsset,
  AssetTabAssetImage,
} from '../components/inventory/AssetsTabTypes'
import { Flag } from '../constants/flagRules'

const ASSET_DATA = `
id
active

asset_info {
  asset_name
  asset_vin
  asset_make
  asset_model
  serial_number
  associated_device_ids
  manufacture_year
  serial_number
  purchase_price
  purchase_date

  user {
    id
    email
    first_name
    last_name
  }

  category {
    id
    name
    icon
    color
  }

  asset_flags {
    is_flagged
    flag {
      id
      name
      type
    }
  }

  category_flags {
    is_flagged
    flag {
      id
      name
      type
    }
  }

}

asset_devices {
  device_id
  device {
    device_esn
  }
  device_info {
    model_id
    sim
    carrier
    description
    model_map {
      id
      manufacturer_model_name
    }
    manufacturer_map {
      name
    }
  }
}

asset_images {
  image_url
  is_main_image
}

maintenance_task_performeds {
  maintenance_task_id
  performed_date
  performed_engine_hour
  performed_odometer
  maintenance_one_time {
    interval_type
    interval_value
    task_name
    task_type
  }
}

snapshots {
  device_id
  fuel
  ignition
  input1
  input2
  input3
  input4
  battery
  solar_battery1
  solar_battery2
  device_battery
  device_battery_level
  current1
  current2
  in_motion
  cab_temperature
  estimated_lat_lng
}
`

export const GET_ASSETS_BY_TENANT_ID = gql`
query GetAssetsByTenantId(
  $tenant_id: uuid!
  $branch_id: uuid!
) {
  assets_assets(where: {
    tenant_id: {_eq: $tenant_id}
    branch_id: {_eq: $branch_id}
  }) {
    ${ASSET_DATA}
  }
}
`

const GET_ASSET = gql`
query GetAssetsByTenantId($id: uuid!) {
  assets_assets(where: {id: {_eq: $id}}) {
    ${ASSET_DATA}
  }
}
`

const INSERT_ASSET = gql`
mutation($asset: assets_assets_insert_input!) {
  insert_assets_assets_one(object: $asset) {
    id
  }
}
`

const UPDATE_ASSIGNED_USER_FOR_ASSET = gql`
mutation($assetIds: [uuid!]!, $userId: uuid) {
  update_assets_asset_info(
    where: {asset_id: {_in: $assetIds}}
    _set: {
      assigned_user: $userId
    }
  ) {
    returning {
      asset_id
    }
  }
}
`

const ATTACH_DEVICES_TO_ASSET = gql`
mutation($assetDevices: [assets_asset_devices_insert_input!]!, $assetId: uuid!) {
  update_assets_asset_devices(
    where: {asset_id: {_eq: $assetId}}
    _set: {
      date_removed: "now()"
    }
  ) {
    affected_rows
  }
  insert_assets_asset_devices(
    objects: $assetDevices
    on_conflict: {
      constraint: asset_devices_pkey
      update_columns: [
        date_assigned
        date_removed
      ]
    }
  ) {
    returning {
      asset_id
      device_id
    }
  }
}
`

function processAsset(asset: any): AssetTabAsset {
  const snapshots = asset.snapshots.map((snapshot: any) => {
    const estimatedLatLng = JSON.parse(snapshot.estimated_lat_lng)
    return {
      ...snapshot,
      estimated_lat_lng: {
        lat: estimatedLatLng[0],
        lng: estimatedLatLng[1],
      }
    }
  })
  return {
    ...asset,
    snapshots,
  }
}

export function getAssets(
  amplifyUser: AmplifyUser|null,
  branchId?: string,
): {
  assets: AssetTabAsset[]
  assetsLoading: boolean
} {
  const { loading, error, data } = useQuery(GET_ASSETS_BY_TENANT_ID, {
    variables: {
      tenant_id: amplifyUser?.['custom:tenantID'],
      branch_id: branchId,
    },
    skip: !amplifyUser
      || !branchId
  })
  if (!data) {
    return {
      assets: [],
      assetsLoading: loading,
    }
  }

  const assets = data.assets_assets.map((asset: AssetTabAsset) => processAsset(asset))
  return {
    assets,
    assetsLoading: loading,
  }
}

export function getAsset(id: string): {
  asset: AssetTabAsset|null
} {
  const { loading, error, data } = useQuery(GET_ASSET, {
    variables: {
      id: id,
    },
    skip: !id
  })

  const assets = data?.assets_assets.map((asset: AssetTabAsset) => processAsset(asset)) || null
  return {
    asset: assets || null,
  }
}

export function queryInsertAsset(amplifyUser: AmplifyUser|null) {
  const [insertAsset, { loading, error, data }] = useMutation(INSERT_ASSET, {
    refetchQueries: [
      'GetAssetsByTenantId',
    ],
  })
  return insertAsset
}

export function queryAttachDevicesToAsset(amplifyUser: AmplifyUser|null) {
  const [attachDevicesToAsset, { loading, error, data }] = useMutation(
    ATTACH_DEVICES_TO_ASSET,
    {
      refetchQueries: [
        'GetAssetsByTenantId',
      ],
    }
  )
  return attachDevicesToAsset
}

export function querySetAssignedUserForAsset(amplifyUser: AmplifyUser|null) {
  const [setAssignedUserForAsset, { loading, error, data }] = useMutation(
    UPDATE_ASSIGNED_USER_FOR_ASSET,
    {
      refetchQueries: [
        'GetAssetsByTenantId',
      ],
    }
  )
  return setAssignedUserForAsset
}

export function getAssetImage(asset: AssetTabAsset): AssetTabAssetImage|null {
  const assetImages = asset?.asset_images
  if (!assetImages?.length) {
    return null
  }

  const assetImage = assetImages.reduce(
    (
      image: AssetTabAssetImage,
      previousImage: AssetTabAssetImage
    ) => {
      // Return the image if it is the main one
      if (previousImage.is_main_image) {
        return previousImage
      }

      // If the image is the main image, return that
      if (image.is_main_image) {
        return image
      }

      // Use the first available image if there is no main image
      if (previousImage) {
        return previousImage
      }

      // Set the first available image
      return image
    },
    assetImages[0] // initial value
  )
  return assetImage
}

export function getAssetFlags(asset: AssetTabAsset, globalFlags: Flag[]): Flag[] {
  const flags = [
    ...(asset?.asset_info?.category_flags || []),
    ...(asset?.asset_info?.asset_flags || []),
    ...globalFlags,
  ].reduce<Flag[]>((allFlags, flag) => {
    if (
      allFlags.includes(flag)
      || !flag.is_flagged
    ) {
      return allFlags
    }
    return [
      ...allFlags,
      flag,
    ]
  }, [])
  return flags
}

export function getAssetGps(asset: AssetTabAsset) {
  const snapshot = asset.snapshots?.[0]
  const gps = snapshot?.estimated_lat_lng || {
    lat: 0,
    lng: 0,
  }
  return gps
}

const UPDATE_ASSET_BRANCH = gql`
mutation($assetIds: [uuid!]!, $newBranchId: uuid) {
  update_assets_assets(
    where: {id: {_in: $assetIds}}
    _set: {
      branch_id: $newBranchId
    }
  ) {
    returning {
      id
    }
  }
}
`

export function queryUpdateAssetsBranch(amplifyUser: AmplifyUser|null) {
  const [updateAssetsBranch, { loading, error, data }] = useMutation(
    UPDATE_ASSET_BRANCH,
  )

  return {
    updateBranchIdOnAssets: (
      assetIds: string[],
      newBranchId: string,
      oldBranchId: string
    ) => {
      updateAssetsBranch({
        variables: {
          assetIds,
          newBranchId,
        },
        refetchQueries: [
          {
            query: GET_ASSETS_BY_TENANT_ID,
            variables: {
              tenant_id: amplifyUser?.['custom:tenantID'],
              branch_id: oldBranchId,
            },
          },
        ],
      })
    },
    error,
    isUpdating: loading,
  }
}
