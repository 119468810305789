import React, {useState} from 'react'
import '../../styles/components/accountComponents/AccountBranch.scss'
import Icon from '../../components/Icon'
import Button from '../../components/buttons/Button'
import Typography from '@mui/material/Typography'
import AccountBranchCard from './AccountBranchCard'
import Paper from '@mui/material/Paper'
import AddEditBranchDialog from './dialogs/AddEditBranchDialog'
import {
  Branch,
  NewBranch,
} from '../../types/tenant'

interface IState {
  addBranchDialogOpen: boolean
}

const AccountBranches = (props: {
  branches: Branch[]
  loadingData: boolean
  isMobile: boolean
  createBranch: (branch: NewBranch) => void
  updateBranch: (branch: NewBranch) => void
  deleteBranch: (branchId: string) => void
}) => {
  const [showAddBranchDialog, setShowAddBranchDialog] = useState(false)

  return (
    <>
      <div>
        <Paper className="accountBranchCardLongContainer">
          <div className="accountBranchCardHeaderLong">
            <Typography variant="h5">Branches</Typography>
            <Button
              onClick={() => setShowAddBranchDialog(true)}
              disabled={props.loadingData}
            >
              <Icon icon="add" variant="button" />
              Branch
            </Button>
          </div>

          {props.branches
            ? props.branches.map((branch: Branch) => (
                <AccountBranchCard
                  key={branch.id}
                  branch={branch}
                  isMobile={props.isMobile}
                  onUpdate={props.updateBranch}
                  onDelete={props.deleteBranch}
                />
              ))
            : null}
        </Paper>
      </div>

      { showAddBranchDialog
        && <AddEditBranchDialog
          dialogOpen={showAddBranchDialog}
          onClose={() => setShowAddBranchDialog(false)}
          onSubmit={props.createBranch}
        />
      }
    </>
  )
}

export default AccountBranches
