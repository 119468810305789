import React from 'react'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import MUIFormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'

interface IState {}

interface IProps {
  id?: string
  style?: React.CSSProperties
  className?: string
  formClassName?: string
  disabled?: boolean
  autoFocus?: boolean
  color?: 'primary' | 'secondary'
  defaultValue?: any
  error?: boolean
  fullWidth?: boolean
  helperText?: React.ReactNode
  variant?: 'filled' | 'outlined' | 'standard'
  value?: any
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  placeholder?: string
  required?: boolean
  label?: any
  name?: string
  autoComplete?: string
  type?: string
  endAdornment?: JSX.Element
  startAdornment?: JSX.Element
  margin?: 'none' | 'dense'
  rows?: number | string
  multiline?: boolean
  minRows?: number | string
  maxRows?: number | string
  step?: number | string
  min?: number
  maxLength?: number
  width?: string | number
  noMargin?: boolean
  onEnter?: () => void
}

/**
 * The Switch from Materialv4 to Materialv5 required us to switch the Button to our own controlled Button
 * - Leave color undefined for default color
 */
class TextField extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    const modifiedProps = { ...this.props }
    delete modifiedProps.onEnter
    delete modifiedProps.helperText
    delete modifiedProps.formClassName

    const labelForId = this.props.id || crypto.randomUUID()

    return (
      <FormControl
        variant={this.props.variant ? this.props.variant : 'standard'}
        disabled={this.props.disabled}
        error={this.props.error}
        sx={{
          width: this.props.width ? this.props.width : 256,
          m: this.props.noMargin ? undefined : 1,
        }}
        className={this.props.formClassName}
      >
        {this.props.label ? (
          <InputLabel
            htmlFor={labelForId}
            required={this.props.required}
            placeholder={this.props.placeholder}
          >
            {this.props.label}
          </InputLabel>
        ) : null}
        <Input
          id={labelForId}
          disabled={this.props.disabled}
          style={{
            marginTop: this.props.label ? undefined : 14,
            ...this.props.style,
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && this.props.onEnter) {
              this.props.onEnter()
            }
          }}
          className={this.props.className}
          autoFocus={this.props.autoFocus}
          color={this.props.color}
          defaultValue={this.props.defaultValue}
          error={this.props.error}
          fullWidth={this.props.fullWidth}
          variant={this.props.variant ? this.props.variant : 'standard'}
          value={this.props.value}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          required={this.props.required}
          label={this.props.label}
          name={this.props.name}
          autoComplete={this.props.autoComplete}
          type={this.props.type}
          margin={this.props.margin}
          startAdornment={this.props.startAdornment}
          endAdornment={this.props.endAdornment}
          inputProps={{
            step: this.props.step,
            min: this.props.min,
            maxLength: this.props.maxLength,
          }}
          rows={this.props.rows}
          multiline={this.props.multiline}
          minRows={this.props.minRows}
          maxRows={this.props.maxRows}
          {...modifiedProps}
        />
        {this.props.helperText ? (
          <MUIFormHelperText>{this.props.helperText}</MUIFormHelperText>
        ) : null}
      </FormControl>
    )
  }
}

export default TextField
