import React, {useState} from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import EllipsisText from '../ux/EllipsisText'
import Divider from '@mui/material/Divider'
import IconButton from '../buttons/IconButton'
import {
  ICategory,
  TCategoryIcons,
  CategoryWithAssetCount,
} from '../../types/category'
import CategoryAvatar from '../ux/CategoryAvatar'
import AddEditCategoryDialog from './dialogs/AddEditCategoryDialog'
import DeleteCategoryDialog from './dialogs/DeleteCategoryDialog'

interface IState {
  categoryDialogOpen: boolean
  categoryDeleteDialogOpen: boolean
}

const CategoryRow = (props: {
  category: CategoryWithAssetCount
  onUpdate: (category: ICategory) => void
  onDelete: (categoryId: string) => void
  isMobile: boolean
}) => {
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false)
  const [categoryDeleteDialogOpen, setCategoryDeleteDialogOpen] = useState(false)

  function showTools() {
    if (!props.category.id) {
      return (<div/>)
    }

    const assetCount = props.category.asset_infos_aggregate.aggregate.count
    return (
      <div>
        <IconButton
          icon="edit"
          variant="toolbar"
          tooltip="Edit Category"
          onClick={() => setCategoryDialogOpen(true)}
        />
        <IconButton
          icon="delete"
          variant="toolbar"
          disabled={assetCount > 0}
          tooltip="Delete Category"
          onClick={() => {
            if (!assetCount) {
              setCategoryDeleteDialogOpen(true)
            }
          }}
        />
      </div>
    )
  }

  return (
    <List disablePadding key={props.category.id}>
      <ListItem>
        <CategoryAvatar
          color={props.category.color}
          icon={props.category.icon}
          size={40}
        />
        <ListItemText
          primary={
            <EllipsisText text={props.category.name}>
              {props.category.name}
            </EllipsisText>
          }
          secondary={`things: ${props.category.asset_infos_aggregate.aggregate.count}`}
          style={{ marginLeft: 16 }}
        />
        {showTools()}
      </ListItem>
      <Divider />
      {
        categoryDialogOpen
        && <AddEditCategoryDialog
          dialogOpen={categoryDialogOpen}
          onSave={props.onUpdate}
          onClose={() => setCategoryDialogOpen(false)}
          type="edit"
          isMobile={props.isMobile}
          category={props.category}
        />
      }
      {
        categoryDeleteDialogOpen
        && <DeleteCategoryDialog
          dialogOpen={categoryDeleteDialogOpen}
          onDelete={props.onDelete}
          onClose={() => setCategoryDeleteDialogOpen(false)}
          category={props.category}
        />
      }
    </List>
  )
}

export default CategoryRow
