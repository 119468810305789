import React, {ChangeEvent, useState, useEffect} from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'

import '../../styles/components/inventory/AssetsTab.scss'
import AddEquipmentDialog from './inventoryDialogs/AddEquipmentDialog'
import WidthViewport from '../ux/WidthViewport'
import ThingRow from './ThingRow'
import ThingToolbar from './inventoryFilters/ThingToolbar'
import { backend_api, headersAPI } from '../../constants/api'
import {
  IAssetMeta,
  IAssetInfo,
  AssetTabAsset,
  AssetTabAssetImage,
  AssetTabDeviceModelMap,
  AssetTabDeviceModel,
  Device,
  NewAsset,
} from './AssetsTabTypes'
import { ERoutes } from '../../components/routes/CheckRoutes'
import { Virtuoso } from 'react-virtuoso'
import StartByText from '../ux/StartByText'
import {
  EMonitoring,
  TMonitorTypes,
  ERentStatus,
  TRentStatusTypes,
  ERepairStatus,
  TRepairStatus,
} from '../../types/inventory'
import { IInventoryHash } from '../../constants/inventoryHashController'
import HashStateManager from '../../rx-js/HashStateManager'
import allFilters from './inventoryFilters/FilterAssets'
import {
  sortInventoryUsers,
  sortInventoryFlags,
  sortInventoryAgreements,
} from './inventorySorting/inventorySorting'
import {
  ICategory,
  ICategoryMap,
} from '../../types/category'
import {
  IUser,
  AmplifyUser,
} from '../../types/account/user'
import { IFlag } from '../../types/flag'
import { IAgreement } from '../../types/agreements'

import TransferEquipmentDialog from './inventoryDialogs/TransferEquipmentDialog'

import {
  getAmplifyUser,
  getAmplifySession,
  getUsers,
  getCurrentUser,
} from '../../api/user'

import {
  getCategories,
} from '../../api/category'

import {
  getAssets,
  queryAttachDevicesToAsset,
  queryInsertAsset,
  querySetAssignedUserForAsset,
  queryUpdateAssetsBranch,
} from '../../api/asset'

import {
  getBranches,
  getCurrentBranch,
} from '../../api/tenant'

import {
  getFlags,
} from '../../api/flag'

const defaultVal = 'all'

const GET_DEVICES_BY_TENANT_ID = gql`
query GetDevicesByTenantId($tenant_id: uuid!) {
  devices_devices(where: {tenant_id: {_eq: $tenant_id}}) {
    id
    device_esn
    device_info {
      model_id
      sim
      carrier
      description
      model_map {
        id
        manufacturer_model_name
      }
      manufacturer_map {
        name
      }
    }
  }
}
`

function getDevices(amplifyUser: AmplifyUser|null): [Device[], any] {
  const [devices, setDevices] = useState([] as Device[])
  const [loadingDevices, setLoadingDevices] = useState(true)

  const { loading, error, data } = useQuery(GET_DEVICES_BY_TENANT_ID, {
    variables: {
      tenant_id: amplifyUser?.['custom:tenantID'],
    },
    skip: !amplifyUser
  })
  if (amplifyUser && loadingDevices && data) {
    setDevices(data.devices_devices)
    setLoadingDevices(false)
  }
  return [devices, setDevices]
}

function groupDevicesByModel(devices: Device[]): AssetTabDeviceModelMap {
  const devicesByModel = devices.reduce(
    (map, device) => {
      let modelMap = device?.device_info?.model_map
      if (!modelMap) {
        modelMap = {
          id: 0,
          manufacturer_model_name: 'No Model Set',
        }
      }

      if (!map[modelMap.id]) {
        map[modelMap.id] = {
          ...modelMap,
          devices: [],
        }
      }
      map[modelMap.id].devices.push(device)
      return map
    },
    {} as AssetTabDeviceModelMap
  )
  return devicesByModel
}

const AssetsTab = (props: {
  hash: HashStateManager<IInventoryHash>
  addThingDialog: boolean
  closeThingDialog: () => void
}) => {
  const [amplifyUser, setAmplifyUser] = getAmplifyUser()
  const [select, setSelect] = useState([] as string[])
  const [searchValue, setSearchValue] = useState('')

  const {amplifySession} = getAmplifySession()
  const {user} = getCurrentUser(amplifySession)
  const {branches} = getBranches(amplifyUser)
  const {currentBranch, setNewCurrentBranch} = getCurrentBranch(user, branches, amplifyUser)
  const {assets, assetsLoading: loading} = getAssets(amplifyUser, currentBranch?.id)

  const [devices, setDevices] = getDevices(amplifyUser)
  const {flags} = getFlags(amplifyUser)
  const flagsThatApplyToAll = flags.filter(
    (flag) => flag.flag_rules.some(
      (rule) => rule.apply_to_all_assets
    )
  ).map((flag) => ({
    is_flagged: false,
    flag: {
      id: flag.id,
      name: flag.name,
      type: flag.type,
    },
  }))
  const [allMetrics, setAllMetrics] = useState([] as any[])
  /** Filters */
  const [monitor, setMonitor] = useState(EMonitoring.all)
  const [rentStatus, setRentStatus] = useState(ERentStatus.all)
  const [repairStatus, setRepairStatus] = useState(ERepairStatus.all)
  const {categories} = getCategories(amplifyUser)
  const [categoryMap, setCategoryMap] = useState({} as ICategoryMap)
  const [categoryId, setCategoryId] = useState(defaultVal)
  const {users} = getUsers(amplifyUser)
  const [userId, setUserId] = useState(defaultVal)
  const [flagList, setFlagList] = useState([] as IFlag[])
  const [flagId, setFlagId] = useState(defaultVal)
  const [agreementList, setAgreementList] = useState([] as IAgreement[])
  const [clientId, setClientId] = useState(defaultVal)
  const [siteId, setSiteId] = useState(defaultVal)
  const [transferDialogOpen, setTransferDialogOpen] = useState(false)

  const devicesByModel = groupDevicesByModel(devices)
  const attachDevicesToAsset = queryAttachDevicesToAsset(amplifyUser)
  const insertAsset = queryInsertAsset(amplifyUser)
  const setAssignedUserForAsset = querySetAssignedUserForAsset(amplifyUser)
  const {updateBranchIdOnAssets} = queryUpdateAssetsBranch(amplifyUser)

  /**
   * When Select all button is clicked, it will trigger
   * @param bool
   */
  function switchAllAssets(bool: boolean, allAssets: AssetTabAsset[]) {
    let newArr: string[] = []
    if (bool) {
      allAssets.forEach((asset) => {
        newArr.push(asset.id)
      })
    }
    setSelect(newArr)
  }

  function changeChecked(val: string, bool: boolean, original: string[]) {
    let newArr = original
    if (bool) {
      newArr.push(val)
    } else {
      newArr.splice(original.indexOf(val), 1)
    }
    setSelect([...newArr])
  }

  function findDivSize(assets: AssetTabAsset[]) {
    let largestMetricSet: any[] = []
    assets.forEach((asset) =>
      asset.metrics
      ? asset.metrics.forEach((metric) => {
        if (!largestMetricSet.find((l) => metric.field === l.field)) {
          largestMetricSet.push(metric)
        }
      })
      : undefined
    )
    setAllMetrics(largestMetricSet)
  }

  /**
   * Quick filter
   */
  function setCurrentFilters() {
    const hashgrabbed: IInventoryHash = props.hash.value
    if (hashgrabbed?.filtersThing) {
      let monitoring = hashgrabbed.filtersThing.monitoring
      let rentStatus = hashgrabbed.filtersThing.rentStatus
      let repairStatus = hashgrabbed.filtersThing.repairStatus
      let categoryId = hashgrabbed.filtersThing.categoryId
      let userId = hashgrabbed.filtersThing.userId
      let flagId = hashgrabbed.filtersThing.flagId
      let clientId = hashgrabbed.filtersThing.clientId
      let siteId = hashgrabbed.filtersThing.siteId
      setMonitor(monitoring ? monitoring : EMonitoring.all)
      setRentStatus(rentStatus ? rentStatus : ERentStatus.all)
      setRepairStatus(repairStatus ? repairStatus : ERepairStatus.all)
      setCategoryId(categoryId ? categoryId : defaultVal)
      setUserId(userId ? userId : defaultVal)
      setFlagId(flagId ? flagId : defaultVal)
      setClientId(clientId ? clientId : defaultVal)
      setSiteId(siteId ? siteId : defaultVal)
    }
  }

  useEffect(() => {
    setCurrentFilters()
  })

  function addDevicesToAsset(assetId: string, devices: Device[]) {
    const assetDevices = devices.map((device) => {
      return {
        asset_id: assetId,
        device_id: device.id,
        date_removed: null,
        date_assigned: 'now()',
      }
    })
    attachDevicesToAsset({
      variables: {
        assetDevices,
        assetId,
      }
    })
  }

  /**
   * This helps the rendering process of more than a certain amount of assets loaded.
   */
  function reactVirtual(assets: AssetTabAsset[], width: number, height: number) {
    return (
      <Virtuoso
        totalCount={assets?.length || 0}
        style={{ height, width }}
        overscan={assets.length > 100 ? 0 : 4150} // this number was selected because it essentially prerenders a total of 100 assets since all of them have a height of 50px
        isScrolling={(bool) =>
          assets.length > 100 ? setLoading(bool) : undefined
        }
        itemContent={(index) => {
          const asset = assets[index]
          if (!asset) {
            return (<div>Something Went Wrong</div>)
          }

          return (
            <ThingRow
              key={asset.id}
              checked={select.includes(asset.id)}
              onChecked={(event) =>
                  changeChecked(
                    asset.id,
                    event.target.checked,
                    select
                  )
              }
              asset={asset}
              inForService={asset.inForService}
              hasCamera={asset.hasCamera}
              flags={flagsThatApplyToAll}
              metrics={asset.metrics}
              fakeMetrics={allMetrics}
              agreement={asset.agreement}
              clientFiltered={clientId !== defaultVal}
              devicesByModel={devicesByModel}
              addDevicesToAsset={addDevicesToAsset}
            />
          )
        }}
      />
    )
  }

  function filters() {
    return allFilters(
      assets,
      searchValue,
      monitor,
      rentStatus,
      repairStatus,
      categoryId,
      userId,
      flagId,
      clientId,
      siteId
    )
  }

  function assignUserToAsset(
    {
      userId,
      assetIds
    }: {
      userId: string|null,
      assetIds: string[]
    }
  ) {
    setAssignedUserForAsset({
      variables: {
        userId,
        assetIds,
      }
    })
  }

  return (
    <WidthViewport>
      {({ isMobile, width, height }) => (
        <>
          <ThingToolbar
            assets={filters()}
            openTransferDialog={() => {
              console.log(select)
              setTransferDialogOpen(true)
            }}
            selectAll={
              assets.length > 0
                ? select.length === filters().length
                : false
            }
            switchAllAssets={(val) =>
              switchAllAssets(val, filters())
            }
            selectArr={select}
            searchValue={searchValue}
            changeSearchVal={(val) => setSearchValue(val)}
            loading={loading}
            isMobile={isMobile}
            /** Filter */
            categories={categories}
            monitor={monitor}
            rentStatus={rentStatus}
            repairStatus={repairStatus}
            changeMonitor={(val: TMonitorTypes) => {
              props.hash.set({
                ...props.hash.value,
                filtersThing: {
                  ...props.hash.value.filtersThing,
                  monitoring: val,
                },
              })
              setMonitor(val)
            }}
            changeRent={(val: TRentStatusTypes) => {
              props.hash.set({
                ...props.hash.value,
                filtersThing: {
                  ...props.hash.value.filtersThing,
                  rentStatus: val,
                },
              })
              setRentStatus(val)
            }}
            changeRepair={(val: TRepairStatus) => {
              props.hash.set({
                ...props.hash.value,
                filtersThing: {
                  ...props.hash.value.filtersThing,
                  repairStatus: val,
                },
              })
              setRepairStatus(val)
            }}
            categoryId={categoryId}
            changeCategory={(val: string) => {
              props.hash.set({
                ...props.hash.value,
                filtersThing: {
                  ...props.hash.value.filtersThing,
                  categoryId: val,
                },
              })
              setCategoryId(val)
            }}
            users={users}
            userId={userId}
            onAssignUser={assignUserToAsset}
            changeUser={(val: string) => {
              props.hash.set({
                ...props.hash.value,
                filtersThing: {
                  ...props.hash.value.filtersThing,
                  userId: val,
                },
              })
              setUserId(val)
            }}
            agreementList={agreementList}
            clientId={clientId}
            changeClient={(val: string) => {
              props.hash.set({
                ...props.hash.value,
                filtersThing: {
                  ...props.hash.value.filtersThing,
                  clientId: val,
                },
              })
              setClientId(val)
            }}
            siteId={siteId}
            changeSite={(val: string) => {
              props.hash.set({
                ...props.hash.value,
                filtersThing: {
                  ...props.hash.value.filtersThing,
                  siteId: val,
                },
              })
              setSiteId(val)
            }}
            flagList={flagList}
            flagId={flagId}
            changeFlag={(val: string) => {
              props.hash.set({
                ...props.hash.value,
                filtersThing: {
                  ...props.hash.value.filtersThing,
                  flagId: val,
                },
              })
              setFlagId(val)
            }}
            clearFilters={() =>
              props.hash.set({
                ...props.hash.value,
                filtersThing: undefined,
              })
            }
          />
          <div
            style={{
              marginTop: isMobile ? 48 : 50,
              marginBottom: isMobile ? 56 : 0,
            }}
          >
            {assets.length > 0 ? (
              reactVirtual(
                filters(),
                isMobile ? width : width - 80,
                isMobile ? height : height - 114
              )
            ) : (
              <StartByText
                id="categoriesStartByText"
                containerStyle={{ marginTop: 180 }}
                clickableText="creating a category"
                href={ERoutes.categories}
              />
            )}
          </div>
          { amplifyUser
            && currentBranch
            && <AddEquipmentDialog
              amplifyUser={amplifyUser}
              currentBranch={currentBranch}
              dialogOpen={props.addThingDialog}
              closeDialog={props.closeThingDialog}
              onSubmit={(asset: NewAsset) => {
                insertAsset({variables: {asset}})
              }}
              isMobile={isMobile}
            />
          }
          <TransferEquipmentDialog
            open={transferDialogOpen}
            onClose={() => setTransferDialogOpen(false)}
            onSubmit={(
              selectedAssetIds: string[],
              newBranchId: string,
              oldBranchId: string,
              comment: string
            ) => {
              updateBranchIdOnAssets(
                selectedAssetIds,
                newBranchId,
                oldBranchId,
              )
              setSelect([])
            }}
            selectedAssets={select}
            allAssets={assets}
            branches={branches}
            currentBranch={currentBranch}
            isMobile={isMobile}
          />
        </>
      )}
    </WidthViewport>
  )
}
export default AssetsTab
