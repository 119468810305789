import React, {lazy, useState, useEffect} from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
// import { withAuthenticator } from '@aws-amplify/ui-react'
import { ApolloProvider, ApolloClient } from '@apollo/client'
import { createApolloClient } from './api/api'
import {
  CognitoUserSession,
} from 'amazon-cognito-identity-js'
import {
  getAmplifySession,
} from './api/user'

import { Authenticator } from 'aws-amplify-react'
import './aws-exports'
import RoviAppbar from './components/appbar/RoviAppbar'
import awsExports from './aws-exports'
import LoadingOverlay from './components/LoadingOverlay'
import { ERoutes } from './components/routes/CheckRoutes'
import Dashboard from './pages/Dashboard' // DO NOT LAZY LOAD - Will break site
import Inventory from './pages/Inventory'
import Service from './pages/Service'
import ServiceTicketInfo from './pages/service/ServiceTicketInfo'
import ServiceTicketLog from './pages/service/ServiceTicketLog'
import NoAccessPage from './pages/NoAccessPage'
import SignIn from './pages/Login'
import Registration from './pages/Registration'
import Forgot from './pages/Forgot'
import Verify from './pages/Verify'
import Profile from './pages/UserProfile'
import Account from './pages/Account'
import Report from './pages/Report'
import Yard from './pages/Yard'
import Notifications from './pages/Notifications' // DO NOT LAZY LOAD - Will break site
import FlagsPage from './pages/FlagsPage'
import ClientsPage from './pages/ClientsPage'
import MaptracPage from './pages/MaptracPage'
import ArchivePage from './pages/ArchivePage'
import AttributesPage from './pages/AttributesPage'
import CategoriesPage from './pages/CategoriesPage'
import SubscriptionsPage from './pages/SubscriptionsPage'
import HistoryPage from './pages/HistoryPage'
import Equipment from './pages/Equipment'
import MarketPlace from './pages/MarketPlace'
import BranchPage from './pages/account/BranchPage'
import OtherUser from './pages/OtherUser'
import Onboarding from './pages/Onboarding'
import { Auth } from 'aws-amplify'
import { darkMode } from './components/ux/roviTheme'
import * as Sentry from '@sentry/react'

// Introducing lazy loading so that we can get code splitting
// const Inventory = lazy(() => import('./pages/Inventory'))
// const Service = lazy(() => import('./pages/Service'))
// const ServiceTicketInfo = lazy(() => import('./pages/service/ServiceTicketInfo'))
// const ServiceTicketLog = lazy(() => import('./pages/service/ServiceTicketLog'))
// const NoAccessPage = lazy(() => import('./pages/NoAccessPage'))
// const Profile = lazy(() => import('./pages/UserProfile'))
// const Account = lazy(() => import('./pages/Account'))
// const Report = lazy(() => import('./pages/Report'))
// const Yard = lazy(() => import('./pages/Report'))
// const FlagsPage = lazy(() => import('./pages/FlagsPage'))
// const ClientsPage = lazy(() => import('./pages/ClientsPage'))
// const MaptracPage = lazy(() => import('./pages/MaptracPage'))
// const ArchivePage = lazy(() => import('./pages/ArchivePage'))
// const CategoriesPage = lazy(() => import('./pages/CategoriesPage'))
// const SubscriptionsPage = lazy(() => import('./pages/SubscriptionsPage'))
// const HistoryPage = lazy(() => import('./pages/HistoryPage'))
// const Equipment = lazy(() => import('./pages/Equipment'))
// const MarketPlace = lazy(() => import('./pages/MarketPlace'))
// const BranchPage = lazy(() => import('./pages/account/BranchPage'))
// const OtherUser = lazy(() => import('./pages/OtherUser'))
// const AttributesPage = lazy(() => import('./pages/AttributesPage'))

interface ICurrentUser {
  id: string
  username: string
  attributes: any
}

const App = (props: {
}) => {
  const [AuthState, setAuthState] = useState('')
  if (AuthState === 'verifyContact') {
    setAuthState('signedIn')
  }

  const [loadingNewPage, setLoadingNewPage] = useState(false)
  const [CurrentUser, setCurrentUser] = useState(undefined as ICurrentUser|undefined)
  const {amplifySession} = getAmplifySession()

  useEffect(() => {
    setLoadingNewPage(false)
    if (!CurrentUser) {
      Auth.currentUserInfo().then((user) => setCurrentUser(user))
    }
    return function cleanup() {
      setLoadingNewPage(true)
    }
  }, [])

  useEffect(() => {
    let oldPushState = history.pushState
    history.pushState = function pushState() {
        let ret = oldPushState.apply(this, arguments);
        window.dispatchEvent(new Event('pushstate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
    };

    let oldReplaceState = history.replaceState
    history.replaceState = function replaceState() {
        let ret = oldReplaceState.apply(this, arguments);
        window.dispatchEvent(new Event('replacestate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
    };

    window.addEventListener('popstate', () => {
        window.dispatchEvent(new Event('locationchange'));
    })

    return function cleanup() {
    }
  }, [])

  const appPathName = window.location.pathname.split('/app')[1]
  const AuthBoolean = AuthState === 'signedIn' ? true : false
  const session = CurrentUser

  if (session) {
    Sentry.configureScope(function (scope) {
      scope.setTag('user.amplifyId', session.id)
      scope.setTag('user.amplifyUsername', session.username)
      // scope.setTag('user.id', session.user.id)
      // scope.setTag('user.name', session.user.name)
      // scope.setTag('user.email', session.user.email)
      // scope.setTag('user.role', session.user.role.label)
      // scope.setTag('account.name', session.account.name)
      // scope.setTag('account.id', session.account.id)

      scope.setTag('darkmode', darkMode ? 'dark' : 'light')
    })
  }

  const [apolloClient, setApolloClient] = useState(null as ApolloClient<any>|null)
  if (!apolloClient && amplifySession) {
    console.log('amplifyUser', amplifySession)
    setApolloClient(
      createApolloClient(
        amplifySession
          .getIdToken()
          .getJwtToken()
      )
    )
  }

  return (
    <BrowserRouter>
      <LoadingOverlay
        loading={loadingNewPage}
        delayedTime={5000}
      />
      <Authenticator
        hideDefault // This Variable removes the default AWS Cognito User interface and we will need to handle the rest
        amplifyConfig={awsExports}
        onStateChange={(val) => setAuthState(val)}
      >
        {AuthBoolean && apolloClient ? (
          <ApolloProvider client={apolloClient}>
            <RoviAppbar userLoggedIn={AuthBoolean} />
            <Switch>
              <Route component={Dashboard} exact path={ERoutes.dashboard} />
              <Route component={Yard} exact path={ERoutes.yard} />
              <Route component={ClientsPage} exact path={ERoutes.client} />
              <Route component={Inventory} exact path={ERoutes.inventory} />
              <Route component={MaptracPage} exact path={ERoutes.maptrac} />
              <Route component={Service} exact path={ERoutes.service} />
              <Route component={Report} path={ERoutes.fleetReport} />
              <Route component={Profile} exact path={ERoutes.profile} />
              <Route component={FlagsPage} exact path={ERoutes.flag} />
              <Route component={ArchivePage} exact path={ERoutes.archive} />
              <Route component={HistoryPage} exact path={ERoutes.history} />
              <Route
                component={MarketPlace}
                exact
                path={ERoutes.marketPlace}
              />
              <Route
                path={`${ERoutes.ticketLog}/:id`}
                exact
                render={({ match }) => {
                  return <ServiceTicketLog id={match.params.id} />
                }}
              />
              <Route
                path={`${ERoutes.ticket}/:id`}
                exact
                render={({ match }) => {
                  return <ServiceTicketInfo id={match.params.id} />
                }}
              />
              <Route
                path={`${ERoutes.branch}/:id`}
                render={({ match }) => {
                  return <BranchPage id={match.params.id} />
                }}
              />
              <Route
                exact
                path={`${ERoutes.equipment}/:id`}
                render={({ match }) => {
                  return <Equipment id={match.params.id} />
                }}
              />

            <Route
              exact
              path={`${ERoutes.user}/:id`}
              render={({ match }) => {
                return <OtherUser id={match.params.id} />
              }}
            />

          <Route
            component={SubscriptionsPage}
            exact
            path={ERoutes.subscriptions}
          />
          <Route
            component={CategoriesPage}
            exact
            path={ERoutes.categories}
          />
          <Route
            component={AttributesPage}
            exact
            path={ERoutes.attribute}
          />
          <Route
            component={Notifications}
            exact
            path={ERoutes.notifications}
          />
          <Route component={Account} path={ERoutes.account} />
          {/* this will fix the compatibility issues from the past to connect with the newer solutions */}
          <Redirect from="/app/*" to={appPathName ? appPathName : '/'} />
          <Route>
            <NoAccessPage Error="This Page Does Not Exist 404" />
          </Route>
        </Switch>
      </ApolloProvider>
    ) : (
      <Switch>
        <Route exact path={ERoutes.signup}>
          <Registration />
        </Route>
        <Route exact path={ERoutes.verify}>
          <Verify />
        </Route>
        <Route exact path={ERoutes.forgot}>
          <Forgot />
        </Route>
      {/* Legacy reports \/ */}
      <Route path={ERoutes.fleetReportLegacy}>
        <RoviAppbar userLoggedIn={AuthBoolean} />
        {/* {this.mappingReportLinks(reports).map((link, key) => (
          <Route exact path={link.href} key={key}>
                    {link.reportPage
                      ? link.reportPage(width, height - 160, isMobile)
                      : link.href}
                    </Route>
                ))} */}
              </Route>
              <Route
                path={ERoutes.onboarding}
                render={({ location }) => {
                  const queryString = new URLSearchParams(location.search)
                  return (
                    <Onboarding
                      userName={
                        queryString.get('userName') ||
                        queryString.get('username') ||
                        queryString.get('name')
                      }
                    />
                    )
                }}
              />

            <Route>
              <SignIn />
            </Route>
          </Switch>
        )}
      </Authenticator>
    </BrowserRouter>
  )
}

export default App
