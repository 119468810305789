import React, {useState, ChangeEvent} from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogRow from '../../dialogComponents/DialogRow'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { backend_api, headersAPI } from '../../../constants/api'
import moment from 'moment'
import { ICategory } from '../../../types/category'
import Select, { ISelectValues } from '../../ux/Select'
import CategoryChip from '../../ux/CategoryChip'
import { countBackwardsInYears } from '../../../constants/yearBackwardsCount'
import TextField from '../../textBoxs/TextField'
import InfoHover from '../../buttons/InfoHover'
import DatePicker from '../../datePickers/DatePicker'
import Button from '../../buttons/Button'
import { IUser, AmplifyUser } from '../../../types/account/user'
import NumberField from '../../textBoxs/NumberField'
import { NewAsset, AssetTabAssetCategory } from '../AssetsTabTypes'
import { getCategories } from '../../../api/category'
import { getUsers } from '../../../api/user'
import { Branch } from '../../../types/tenant'

const styles = () =>
  createStyles({
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    DatePicker: {
      width: 250,
      marginBottom: 0,
    },
  })

function SortCategoriesintoSelectValue(cateogories: AssetTabAssetCategory[]) {
  let selectCategories: ISelectValues[] = []
  cateogories.forEach((category) => {
    selectCategories.push({
      value: category.id,
      label: <CategoryChip
        name={category.name || ''}
        icon={category.icon || 'car'}
        color={category.color || ''}
        button={true}
      />
    })
  })
  return selectCategories
}

function SortUsersintoSelectValue(users: IUser[]) {
  let selectUsers: ISelectValues[] = []
  users.forEach((user) => {
    selectUsers.push({
      value: user.id,
      label: `${user.first_name} ${user.last_name}`,
    })
  })
  return selectUsers
}

const AddEquipmentDialog = (props: {
  amplifyUser: AmplifyUser,
  currentBranch: Branch,
  dialogOpen: boolean
  closeDialog: () => void
  onSubmit: (asset: NewAsset) => void
  isMobile: boolean
  classes: any
}) => {
  const {categories} = getCategories(props.amplifyUser)
  const {users} = getUsers(props.amplifyUser)
  const [asset, setAsset] = useState({
    tenant_id: props.amplifyUser['custom:tenantID'],
    branch_id: props.currentBranch.id,
    asset_info: {
      data: {
        asset_name: '',
        asset_vin: '',
        asset_make: '',
        asset_model: '',
        manufacture_year: '',
        serial_number: '',
        purchase_price: '',
        purchase_date: '',
        asset_category: 0,
        assigned_user: null,
      },
    },
  } as NewAsset)

  if (categories.length && !asset.asset_info.data.asset_category) {
    updateAssetValue('asset_category', categories[0].id)
  }

  if (users.length && !asset.asset_info.data.assigned_user) {
    updateAssetValue('assigned_user', users[0].id)
  }

  function updateAssetValue(
    key: keyof NewAsset['asset_info']['data'],
    value: any
  ) {
    setAsset({
      ...asset,
      asset_info: {
        data: {
          ...asset.asset_info.data,
          [key]: value,
        }
      },
    })
  }

  function handleClose() {
    props.closeDialog()
  }

  function onSubmit() {
    props.onSubmit(asset)
    props.closeDialog()
  }

  function isSaveButtonDisabled() {
    return !(
      !!asset.asset_info.data.asset_category
      && !!asset.asset_info.data.asset_name
      && !!asset.asset_info.data.asset_make
      && !!asset.asset_info.data.asset_model
    )
  }

  return (
    <Dialog open={props.dialogOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Add Equipment</DialogTitle>
      <DialogContent>
        <DialogRow
          FieldLeft={
            categories.length
            ? (<Select
              id="addEquipmentDialogCategory"
              label="Category"
              values={SortCategoriesintoSelectValue(categories)}
              selectedValue={asset.asset_info.data.asset_category}
              onChange={(categoryId: number) => updateAssetValue('asset_category', categoryId)}
              className={props.classes.TextField}
            />)
            : (<>{"No categories loaded."}</>)
          }
        />
        <DialogRow
          FieldLeft={
            <TextField
              id="addEquipmentDialogIdTextInput"
              label="ID"
              required
              value={asset.asset_info.data.asset_name}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                updateAssetValue('asset_name', event.target.value)
              }}
              className={props.classes.TextField}
            />
          }
          FieldRight={
            <TextField
              id="addEquipmentDialogVinTextInput"
              label="VIN"
              className={props.classes.TextField}
              value={asset.asset_info.data.asset_vin}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                updateAssetValue('asset_vin', event.target.value)
              }}
            />
          }
          FieldRightAddOn={
            <InfoHover>
              <div style={{ margin: '8px 0' }}>
                <span style={{ fontWeight: 'bold' }}>
                  Why Is My Vin Invalid?{' '}
                </span>
                Vins should be 17 characters long, avoid the letters I, O, Q,
                and all letters should be uppercase. The vin should also pass
                the{' '}
                <a
                  href="https://vin.dataonesoftware.com/vin_basics_blog/bid/112040/use-vin-validation-to-improve-inventory-quality"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  VIN Checksum Algorithm
                </a>
                . An invalid vin can be saved to an equipment.
              </div>
            </InfoHover>
          }
        />

        <DialogRow
          FieldLeft={
            <TextField
              id="addEquipmentDialogMakeTextInput"
              label="Make"
              required
              className={props.classes.TextField}
              value={asset.asset_info.data.asset_make}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                updateAssetValue('asset_make', event.target.value)
              }}
            />
          }
          FieldRight={
            <TextField
              id="addEquipmentDialogModelTextInput"
              label="Model"
              required
              className={props.classes.TextField}
              value={asset.asset_info.data.asset_model}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                updateAssetValue('asset_model', event.target.value)
              }}
            />
          }
        />

        <DialogRow
          FieldLeft={
            <Select
              id="addEquipmentDialogYearTextInput"
              label="Year Built"
              values={countBackwardsInYears(moment().format('YYYY'), 100)}
              selectedValue={asset.asset_info.data.manufacture_year}
              onChange={(year: number) => updateAssetValue('manufacture_year', year)}
              className={props.classes.TextField}
            />
          }
          FieldRight={
            <TextField
              id="addEquipmentDialogSerialTextInput"
              label="Serial Number"
              className={props.classes.TextField}
              value={asset.asset_info.data.serial_number}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                updateAssetValue('serial_number', event.target.value)
              }}
            />
          }
        />

        <DialogRow
          FieldLeft={
            <NumberField
              id="addEquipmentDialogPurchasePriceInput"
              startAdornment="$"
              label="Purchase Price"
              className={props.classes.TextField}
              value={asset.asset_info.data.purchase_price}
              onChange={(price: number) => updateAssetValue('purchase_price', price)}
            />
          }
          FieldRight={
            <DatePicker
              id="addEquipmentDialogPurchaseDateInput"
              showToolbar
              className={props.classes.DatePicker}
              label="Purchase Date"
              selectedDate={asset.asset_info.data.purchase_date}
              onChange={(date: Date|null) => {
                updateAssetValue('purchase_date', date)
              }}
            />
          }
        />

        <DialogRow
          FieldLeft={
            users.length
            ? (
              <Select
                label="User"
                values={SortUsersintoSelectValue(users)}
                selectedValue={asset.asset_info.data.assigned_user}
                onChange={(userId: string) => updateAssetValue('assigned_user', userId)}
                className={props.classes.TextField}
              />
            ) : (<>{"No users available to select."}</>)
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          id="addEquipmentDialogCancelAddEquipment"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={isSaveButtonDisabled()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(AddEquipmentDialog)
