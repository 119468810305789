import React from 'react'
import '../../../styles/components/inventory/AssetsTab.scss'
import { PageTitle } from '../../routes/CheckRoutes'
import MobileRoviAppbar from '../../appbar/MobileRoviAppbar'
import { EInventoryTabs } from '../../../constants/inventoryHashController'
import { colors } from '../../ux/roviTheme'
import Checkbox from '@mui/material/Checkbox'
import SearchBar from '../../textBoxs/SearchBar'
import IconButton, { IconButtonNonA } from '../../buttons/IconButton'
import ButtonDrawer from '../../buttons/ButtonDrawer'
import { LoadingIcon } from '../../LoadingOverlay'
import FilterDrawer from './FilterDrawer'
import ButtonSideIcon from '../../buttons/ButtonSideIcon'
import Divider from '@mui/material/Divider'
import {
  TMonitorTypes,
  TRentStatusTypes,
  TRepairStatus,
} from '../../../types/inventory'
import { ICategory } from '../../../types/category'
import { IUser } from '../../../types/account/user'
import { IFlag } from '../../../types/flag'
import { IAgreement } from '../../../types/agreements'
import printJS from 'print-js'
import { AssetTabAsset } from '../AssetsTabTypes'
import {
  printJsSortInventory,
  printList,
  csvSortInventory,
} from '../inventorySorting/inventoryPrint'
import { CSVLink } from 'react-csv'
import PriceSheet from '../inventorySorting/PriceSheet'
import AssignUserDialog from '../inventoryDialogs/AssignUserDialog'
import routes from '../../routes/routes'

const ThingToolbar = (props: {
  assets: AssetTabAsset[]
  selectAll: boolean
  switchAllAssets: (val: boolean) => void
  selectArr: string[]
  searchValue: string
  changeSearchVal: (val: string) => void
  loading?: boolean
  isMobile?: boolean
  /** Filters */
  categories: ICategory[]
  monitor: TMonitorTypes
  rentStatus: TRentStatusTypes
  repairStatus: TRepairStatus
  changeMonitor: (val: TMonitorTypes) => void
  changeRent: (val: TRentStatusTypes) => void
  changeRepair: (val: TRepairStatus) => void
  categoryId: string
  changeCategory: (val: string) => void
  users: IUser[]
  onAssignUser: (args: {userId: string|null, assetIds: string[]}) => void
  userId: string
  changeUser: (val: string) => void
  agreementList: IAgreement[]
  clientId: string
  changeClient: (val: string) => void
  siteId: string
  changeSite: (val: string) => void
  flagList: IFlag[]
  flagId: string
  changeFlag: (val: string) => void
  clearFilters: () => void
  openTransferDialog: () => void
}) => {
  function dividerCustom() {
    return <Divider orientation="vertical" flexItem variant="middle" />
  }

  function loadAssetsURL(assets: AssetTabAsset[], selected: string[]) {
    let newAssets: string[] = []
    assets.forEach((asset) => {
      if (selected.includes(asset.id)) {
        newAssets.push(asset.id)
      }
    })
    return newAssets
  }

  function DefaultToolbar() {
    const defaultVal = 'all'
    const clientb = props.clientId !== defaultVal
    const siteb = props.siteId !== defaultVal
    const flagb = props.flagId !== defaultVal
    const userb = props.userId !== defaultVal
    const monitorb = props.monitor !== defaultVal
    const rentStatusb = props.rentStatus !== defaultVal
    const repairStatusb = props.repairStatus !== defaultVal
    const categoryb = props.categoryId !== defaultVal
    const dot =
      clientb ||
      siteb ||
      flagb ||
      monitorb ||
      rentStatusb ||
      repairStatusb ||
      categoryb ||
      userb

    return (
      <div className="inventoryAppbar" style={{ backgroundColor: colors.mbg }}>
        <div className="inventoryLeftSide">
          <Checkbox
            id="thingToolbarAssetsCheckBox"
            checked={props.selectAll}
            onChange={(event) =>
              props.switchAllAssets(event.target.checked)
            }
            color="primary"
          />
          <SearchBar
            id="thingToolbarSearchBar"
            value={props.searchValue}
            onChange={(val) => props.changeSearchVal(val)}
          />
          <ButtonDrawer
            title="Filters"
            icon="filter"
            anchor="right"
            width={290}
            dot={dot}
          >
            <FilterDrawer
              categories={props.categories}
              monitor={props.monitor}
              rentStatus={props.rentStatus}
              repairStatus={props.repairStatus}
              changeMonitor={(val: TMonitorTypes) =>
                props.changeMonitor(val)
              }
              changeRent={(val: TRentStatusTypes) => props.changeRent(val)}
              changeRepair={(val: TRepairStatus) =>
                props.changeRepair(val)
              }
              categoryId={props.categoryId}
              changeCategory={(val: string) => props.changeCategory(val)}
              users={props.users}
              userId={props.userId}
              changeUser={(val: string) => props.changeUser(val)}
              agreementList={props.agreementList}
              clientId={props.clientId}
              changeClient={(val: string) => props.changeClient(val)}
              siteId={props.siteId}
              changeSite={(val: string) => props.changeSite(val)}
              flagList={props.flagList}
              flagId={props.flagId}
              changeFlag={(val: string) => props.changeFlag(val)}
              clearFilters={props.clearFilters}
            />
          </ButtonDrawer>
          <div className="equipmentTotal">
            Total: {props.assets.length}
          </div>
          {props.loading ? (
            <div style={{ marginLeft: 10 }}>
              <LoadingIcon loading />
            </div>
          ) : null}
        </div>
        <div className="equipmentIconButtons">
          <PriceSheet />
          <IconButton
            icon="print"
            variant="toolbar"
            tooltip="Print/PDF"
            onClick={() =>
              printJS({
                printable: printJsSortInventory(props.assets),
                properties: printList,
                header: 'Rovitracker Inc.',
                gridHeaderStyle:
                  'font-weight: bold; font-size: 15px; border: 1px solid lightgray; margin-bottom: -1px;',
                gridStyle:
                  'font-size: 12px; border: 1px solid lightgray; margin-bottom: -1px;',
                type: 'json',
              })
            }
          />
          <CSVLink
            data={csvSortInventory(props.assets)}
            filename="rovitracker-inventory.csv"
          >
            <IconButtonNonA
              id="csvAssetsToolBarButton"
              icon="csv"
              variant="toolbar"
              tooltip="Export as CSV file"
            />
          </CSVLink>
        </div>
      </div>
    )
  }

  function selectToolbar() {
    return (
      <div
        className="inventoryAppbar"
        style={{ backgroundColor: colors.highlightedBackground }}
      >
        <div className="inventoryLeftSide">
          <Checkbox
            checked={props.selectAll}
            onChange={(event) =>
              props.switchAllAssets(event.target.checked)
            }
            color="primary"
          />
          <ButtonSideIcon
            startIcon="syncAlt"
            label="Transfer"
            onClick={props.openTransferDialog}
          />
          {dividerCustom()}
          <AssignUserDialog
            includeUnassignOption
            selectedAssetIds={props.selectArr}
            users={props.users}
            onSubmit={props.onAssignUser}
          />
          {dividerCustom()}
          <ButtonSideIcon
            startIcon="flag"
            label="Create Flag"
            // onClick={() => routes.createFlagWithAssets([])}
            href={routes.createFlagWithAssetsHref(
              loadAssetsURL(props.assets, props.selectArr)
            )}
          />
        </div>
        <div className="equipmentIconButtons">
          <div style={{ color: '#5D96F2', fontSize: 15, marginRight: 20 }}>
            {props.selectArr.length}{' '}
            {props.selectArr.length > 1 ? 'assets ' : 'asset '}selected
          </div>
        </div>
      </div>
    )
  }

  const isMobile = props.isMobile
  return isMobile ? (
    <MobileRoviAppbar
      title={PageTitle(undefined, EInventoryTabs.thing)}
      length={props.assets.length}
    />
  ) : props.selectArr.length > 0 ? (
    selectToolbar()
  ) : (
    DefaultToolbar()
  )
}
export default ThingToolbar
