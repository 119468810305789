import React, {useState} from 'react'
import '../styles/pages/UserProfile.scss'
import ReactHelmet from '../components/ReactHelmet'
import Toolbar from '../components/toolbar/Toolbar'
import Button from '../components/buttons/Button'
import Icon from '../components/Icon'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { CardItem } from '../components/ux/Attribute'
import LoadingOverlay from '../components/LoadingOverlay'
import Panel from '../components/ux/Panel'
import NotificationsForm from '../components/userProfile/NotificationsForm'
import { darkMode } from '../components/ux/roviTheme'
import ChangePasswordDialog from '../components/userProfile/dialogs/ChangePasswordDialog'
import EditUserDialog from '../components/userProfile/dialogs/EditUserDialog'

import {
  getAmplifySession,
  getCurrentUser,
  queryUpdateUser,
  updateCognitoEmail,
} from '../api/user'

const UserProfile = (props: {}) => {
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false)

  const {amplifySession} = getAmplifySession()
  const {user} = getCurrentUser(amplifySession)
  const updateUser = queryUpdateUser()

  /**
   * Changes the website from either light to darkmode.
   * @param event for when the switch component changes it will grab the HTMLInputElement that changed to be passed through.
   */
  const handleDarkModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('darkMode', event.target.checked ? 'dark' : 'light')
    window.location.reload()
  }

  const branchNames = user?.user_branches.map((branch) => {
    return branch.branch.branch_name
  }) || []

  return (
    <div className="userProfilePage">
      <ReactHelmet title="Profile" />
      <LoadingOverlay loading={!user} delayedTime={0} />
      { user
        && <>
          <Toolbar title="User Profile">
            <Button
              startIcon={<Icon icon="edit" />}
              onClick={() => setEditUserDialogOpen(true)}
            >
              Edit User
            </Button>
            <Button
              startIcon={<Icon icon="key" />}
              onClick={() => setPasswordDialogOpen(true)}
            >
              Change Password
            </Button>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  name="DarkMode"
                  checked={darkMode}
                  onChange={(event) => handleDarkModeChange(event)}
                />
              }
              label="DarkMode [BETA]"
            />
          </Toolbar>
          <div className="userProfilePageBody">
            <div className="userProfileAttributes">
              <CardItem title="Name" value={`${user.first_name} ${user.last_name}`}/>
              <CardItem title="Email" value={user.email} />
              <CardItem title="Branches" value={branchNames.join(', ')} />
            </div>
            <Panel title="Notification Preferences">
              <NotificationsForm
                user={user}
                onSave={(notificationSettings) => {
                  updateUser({
                    variables: {
                      ...user,
                      settings: {
                        ...user.settings,
                        ...notificationSettings,
                      },
                      role: undefined,
                      user_branches: undefined,
                    },
                  })
                }}
              />
            </Panel>
          </div>
          {passwordDialogOpen ? (
            <ChangePasswordDialog
              dialogOpen={passwordDialogOpen}
              onClose={() => setPasswordDialogOpen(false)}
            />
          ) : null}
          <EditUserDialog
            dialogOpen={editUserDialogOpen}
            onClose={() => setEditUserDialogOpen(false)}
            onSave={(editedUser) => {
              updateUser({
                variables: {
                  id: editedUser.id,
                  first_name: editedUser.first_name,
                  last_name: editedUser.last_name,
                  email: editedUser.email,
                  settings: editedUser.settings,
                },
              })
              updateCognitoEmail(editedUser.email)
            }}
            user={user}
          />
        </>
      }
      </div>
  )
}

export default UserProfile
