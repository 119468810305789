import { gql, useQuery, useMutation, ApolloError } from '@apollo/client'
import {useState} from 'react'
import {
  AmplifyUser,
} from '../types/account/user'
import {
  Geofence,
  NewGeofence,
} from '../types/geofence'
import { ILatLng } from '../types/maptrac/LatLng'

import { removeTypename } from '../utility/graphQl'

export const GET_GEOFENCES_BY_TENANT_ID =  gql`
  query GetGeofencesByTenantId(
    $tenant_id: uuid!
    $branch_id: uuid!
  ) {
    geofences_geofences(
      where: {
        tenant_id: {_eq: $tenant_id}
        branch_id: {_eq: $branch_id}
        deleted_date: {_is_null: true}
      }
    ) {
      id
      name
      coordinates
      shape
    }
  }
`
export function getGeofences(
  amplifyUser: AmplifyUser|null,
  branchId?: string
): {
  geofences: Geofence[]
  loadingGeofences: boolean
} {
  const { loading, error, data, refetch } = useQuery(GET_GEOFENCES_BY_TENANT_ID, {
    variables: {
      tenant_id: amplifyUser?.['custom:tenantID'],
      branch_id: branchId,
    },
    skip: !amplifyUser
      || !branchId
  })
  if (!data) {
    return {
      geofences: [],
      loadingGeofences: loading,
    }
  }

  const geofences = data?.geofences_geofences
  return {
    geofences,
    loadingGeofences: loading,
  }
}

const INSERT_GEOFENCE = gql`
mutation($geofence: geofences_geofences_insert_input!) {
  insert_geofences_geofences_one(object: $geofence) {
    id
  }
}
`

// Thank you: https://stackoverflow.com/a/1026087/6055465
const capitalizeFirstLetter = ([ first, ...rest ]: [string, string[]], locale = navigator.language) =>
  first === undefined ? '' : first.toLocaleUpperCase(locale) + rest.join('')

export function queryInsertGeofence(amplifyUser: AmplifyUser|null): {
  insertGeofence: any
  isSaving: boolean
  error: ApolloError | undefined
} {
  const [insertGeofence, { loading, error, data }] = useMutation(
    INSERT_GEOFENCE,
    {
      refetchQueries: [
        'GetGeofencesByTenantId',
      ],
    }
  )
  return {
    insertGeofence: (geofence: NewGeofence, branchId: string) => {
      insertGeofence({
        variables: {
          geofence: {
            ...geofence,
            tenant_id: amplifyUser?.['custom:tenantID'],
            branch_id: branchId,
          },
        }
      })
    },
    error,
    isSaving: loading,
  }
}

export const UPDATE_GEOFENCE = gql`
  mutation(
    $id: uuid!
    $geofence: geofences_geofences_set_input!
  ) {
    update_geofences_geofences_by_pk(
      pk_columns: {id: $id}
      _set: $geofence
    ) {
        id
    }
  }
`

export function queryUpdateGeofence() {
  const [updateGeofence, { loading, error, data }] = useMutation(UPDATE_GEOFENCE, {
    refetchQueries: [
      'GetGeofencesByTenantId',
    ],
  })
  return {
    updateGeofence: (geofence: Geofence) => {
      geofence = removeTypename(geofence)
      updateGeofence({
        variables: {
          id: geofence.id,
          geofence: {
            ...geofence,
          },
        }
      })
    },
    error,
    isSaving: loading,
  }
}

export const DELETE_GEOFENCE = gql`
  mutation(
    $id: uuid!
  ) {
    update_geofences_geofences_by_pk(
      pk_columns: {id: $id}
      _set: {
        deleted_date: "now()"
      }
    ) {
        id
    }
  }
`

export function queryDeleteGeofence() {
  const [deleteGeofence, { loading, error, data }] = useMutation(DELETE_GEOFENCE, {
    refetchQueries: [
      'GetGeofencesByTenantId',
    ],
  })
  return {
    deleteGeofence: (id: string) => {
      deleteGeofence({
        variables: {
          id,
        }
      })
    },
    error,
    isDeleting: loading,
  }
}
