export interface IAssetDevice {
  esn: string
  deviceType: EModel
  description: string
}

export interface IDeviceType {
  id: string
  rovitracker_model_name: string
  device_type: {
    label: string
    dimensions: string
    usage: string
    price: string
    show_on_market: number
    description: string
    short_desc: string
    is_gateway: boolean
    is_tag: boolean
    image_url: string
    small_image_url: string
  }
}

export interface IDevice {
  id: string
  device_esn: string
  active: boolean
  device_info: {
    model_map: {
      id: string
      rovitracker_model_name: string
    } | null
  } | null
  asset_device: {
    asset: {
      branch: {
        id: string
        branch_name: string
      }
      asset_info: {
        asset_id: string
        asset_name: string
        asset_make: string
        asset_model: string
      }
    }
  }
}

export interface IDeviceMeta {
  device_id: string
  device_esn: string
  tenant_id: string
  created_date: string
  active: boolean
}

export interface IDeviceInfo {
  id: string
  esn: string
  created_date: string
  sim: string
  carrier: string
  manufacturer: string
  model: string
  description: string
}

export interface IDeviceInfosByModel {
  [deviceModel: string]: IDeviceInfo[]
}

export interface IDevicesGroupedByModel {
  model: string
  devices: IDeviceInfo[]
}

export enum EModel {
  rlgl300ma = 'rl-gl300ma',
  rlgl500ma = 'rl-gl500ma',
  rl300 = 'rl-300',
  rl28 = 'rl-28',
  rl26 = 'rl-26',
  rl400 = 'rl-400',
  rl55 = 'rl-55',
  rltag1001 = 'rl-tag-1001',
  rltag1002 = 'rl-tag-1002',
  rltag1003 = 'rl-tag-1003',
  rltag1004 = 'rl-tag-1004',
  rltag1005 = 'rl-tag-1005',
  rltag1006 = 'rl-tag-1006',
  rlapp = 'rl-app',
}

export enum EManufacture {
  queclink = 'queclink',
  calamp = 'calamp',
  minew = 'minew',
  roviTracker = 'roviTracker',
}

export type TModels = [
  EModel.rlgl300ma,
  EModel.rlgl500ma,
  EModel.rl300,
  EModel.rl28,
  EModel.rl26,
  EModel.rl400,
  EModel.rl55,
  EModel.rltag1001,
  EModel.rltag1002,
  EModel.rltag1003,
  EModel.rltag1004,
  EModel.rltag1005,
  EModel.rltag1006,
  EModel.rlapp,
  ''
]

/**
 * This object looks for the Internal Model ID, than will export the Model ID
 */
export const modelID = {
  [EModel.rlgl300ma]: 'HE6LE8HOCC3UDS1BYA03',
  [EModel.rlgl500ma]: 'LDB7JHXCTXV35FUV285P',
  [EModel.rl300]: '52U82V5C00RI0GPZMYOR',
  [EModel.rl28]: 'O1FSOH3998C1W1MVV69B',
  [EModel.rl26]: 'O1FSOH3998C1W1MVV69B',
  [EModel.rl400]: '5MXRG46K47GN2B2TCRQA',
  [EModel.rl55]: 'GX83SFHVFISPDN44NT2L',
  [EModel.rltag1001]: 'QCQU0SEKLKN38DY76HKM',
  [EModel.rltag1002]: 'K2A6JHWBSNKS4ETLR84O',
  [EModel.rltag1003]: 'G46K47GN2B2TCRQAX9Z2',
  [EModel.rltag1004]: 'OH3998C1W1MVV69B5MXR',
  [EModel.rltag1005]: '75L0HLIRT3IJPWTDO1FS',
  [EModel.rltag1006]: '2V5C00RI0GPZMYOR91ZV',
  [EModel.rlapp]: 'YX0QLQBUKVXZTBVG52U8',
  '': '',
}

/**
 * This object looks for the Internal Model ID, than will export the Manufacture
 */
export const manufacture = {
  [EModel.rlgl300ma]: EManufacture.queclink,
  [EModel.rlgl500ma]: EManufacture.queclink,
  [EModel.rl300]: EManufacture.calamp,
  [EModel.rl28]: EManufacture.calamp,
  [EModel.rl26]: EManufacture.calamp,
  [EModel.rl400]: EManufacture.calamp,
  [EModel.rl55]: EManufacture.calamp,
  [EModel.rltag1001]: EManufacture.minew,
  [EModel.rltag1002]: EManufacture.minew,
  [EModel.rltag1003]: EManufacture.minew,
  [EModel.rltag1004]: EManufacture.minew,
  [EModel.rltag1005]: EManufacture.minew,
  [EModel.rltag1006]: EManufacture.minew,
  [EModel.rlapp]: EManufacture.roviTracker,
  '': '',
}
