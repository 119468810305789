import React, {useState} from 'react'
import { Polygon, Polyline, Circle } from '@react-google-maps/api'
import { colors } from '../ux/roviTheme'
import { ERadiusType, TRadiusType } from './GeofenceDrawingManager'
import { ILatLng } from '../../types/maptrac/LatLng'

const EditablePolygon = (props: {
  points: ILatLng[] | google.maps.LatLng[]
  radius?: number
  type?: TRadiusType
  onEditPoints: (points: ILatLng[]) => void
  onClick?: (paths: ILatLng[] | google.maps.LatLng[]) => void
  editable?: boolean
}) => {
  const [polygon, setPolygon] = useState(undefined as any)

  const polygonOptions = {
    editable: props.editable ? true : false,
    strokeColor: colors.primaryBlue,
    strokeWeight: 4,
    fillOpacity: 0,
    zIndex: 2,
  }

  const points = props.points[0].map(
    (pos: [number, number]) => ({
      lng: pos[0],
      lat: pos[1],
    })
  )

  function onUpdate() {
    if (!props.editable) {
      return
    }

    if (!polygon) {
      return
    }

    const points = polygon
      ?.state
      ?.polygon
      ?.getPath()
      ?.getArray()
      ?.map((point: {lat: () => number, lng: () => number}) => ({
        lat: point.lat(),
        lng: point.lng(),
      }))

    if (!points) {
      return
    }

    const dataLayer = new google.maps.Data()
    dataLayer.add(
      new google.maps.Data.Feature({
        geometry: new google.maps.Data.Polygon([points])
      })
    )

    dataLayer.toGeoJson((newPoints) => {
      props.onEditPoints(newPoints.features[0].geometry)
    })
  }

  /**
   * This component was created to handle different versions of the geofence polygons and polylines
   */
  switch (props.type) {
    case ERadiusType.Rectangle:
    case ERadiusType.Polygon:
      return (
        <Polygon
          ref={(polyref) => {
            setPolygon(polyref)
          }}
          onMouseUp={() => setTimeout(onUpdate, 0)} // delays path update slightly so polygon is updated first
          paths={points}
          options={polygonOptions}
          onClick={() =>
            props.onClick
              ? props.onClick(points)
              : undefined
          }
        />
      )
    case ERadiusType.Circle:
      return (
        <Circle
          ref={(polyref) => {
            setPolygon(polyref)
          }}
          onMouseUp={() => setTimeout(onUpdate, 0)} // delays path update slightly so polygon is updated first
          center={points[0]}
          radius={props.radius ? props.radius : 0}
          options={polygonOptions}
          onClick={() =>
            props.onClick
              ? props.onClick(points)
              : undefined
          }
        />
      )
    default:
      return (
        <Polyline
          ref={(polyref) => {
            setPolygon(polyref)
          }}
          path={points}
          options={polygonOptions}
          onMouseUp={() => setTimeout(onUpdate, 0)} // delays path update slightly so polygon is updated first
          onClick={() =>
            props.onClick
              ? props.onClick(points)
              : undefined
          }
        />
      )
  }
}

export default EditablePolygon
