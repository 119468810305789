import React, {useState} from 'react'
import '../../styles/components/inventory/AssetsTab.scss'
import EllipsisText from '../ux/EllipsisText'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import Link from '@mui/material/Link'
import Icon from '../Icon'
import UserAvatar from '../ux/UserAvatar'
import { colors } from '../../components/ux/roviTheme'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '../buttons/IconButton'
import { ERoutes } from '../routes/CheckRoutes'
import FieldRow from './FieldRow'
import ServiceButton from '../ux/ServiceButton'
import FlagButton from '../ux/FlagButton'
import CategoryChip from '../ux/CategoryChip'
import { ICategory } from '../../types/category'
import { IUser } from '../../types/account/user'
import { ListImageEquip } from '../ux/ListImage'
import Button from '../buttons/Button'
import { ContainerViewSize } from './FieldView'
import { EquipClientSiteRow } from './inventoryClientView'
import { IAgreement } from '../../types/agreements'
import {
  IDeviceMeta,
  IDeviceInfo,
} from '../../types/account/devices'
import {
  AssetTabAsset,
  AssetTabAssetCategory,
  AssetTabDeviceModelMap,
  Device,
} from './AssetsTabTypes'
import {
  getAssetImage,
  getAssetFlags,
} from '../../api/asset'
import AddDeviceComponent from '../equipmentComponents/deviceComponents/AddDeviceComponent'

interface IFieldProps {
  title: string
  info: any
  width?: number
}

const ThingRow = (props: {
  asset: AssetTabAsset
  checked: boolean
  onChecked: (event: React.ChangeEvent<HTMLInputElement>) => void
  category?: AssetTabAssetCategory|null
  inForService?: boolean
  hasCamera?: boolean
  flags: any[]
  width?: number
  agreement?: IAgreement
  clientFiltered?: boolean
  devicesByModel: AssetTabDeviceModelMap
  addDevicesToAsset: (assetId: string, devices: Device[]) => void
}) => {
  const [anchorEl, setAnchorEl] = useState(null as HTMLButtonElement|null)
  const assetInfo = props.asset?.asset_info
  const assetDevices = props.asset.asset_devices
  const snapshots = props.asset.snapshots

  function countFieldTypes(snapshots: any[]) {
    let newWidth = 0
    snapshots.forEach((m) => (newWidth = newWidth + ContainerViewSize(m)))
    return <div style={{ width: newWidth }} />
  }

  function onAddDeviceClicked(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function addDevices(devices: Device[]) {
    props.addDevicesToAsset(props.asset.id, devices)
    setAnchorEl(null)
  }

  const flags = getAssetFlags(props.asset, props.flags)

  return (
    <div
      className="inventoryRow"
      style={{
        borderColor: colors.abc,
        backgroundColor: colors.bg,
        minWidth: props.width ? props.width : '100%',
      }}
    >
      <Checkbox
        checked={props.checked}
        onChange={props.onChecked}
        color="primary"
      />
      <div className="assetImage">
        <ListImageEquip
          imageUrl={getAssetImage(props.asset)?.image_url}
          categoryIcon={assetInfo?.category?.icon || 'car'}
          containerStyle={{ width: 64, height: 40, alignSelf: 'center' }}
        />
        <div className="imageIcons">
          {props.inForService ? (
            <Tooltip title="In for service" placement="right">
              <Avatar className="inService">
                <Icon
                  icon="service"
                  style={{ color: colors.iconColor }}
                  fontSize="inherit"
                />
              </Avatar>
            </Tooltip>
          ) : null}
          {assetInfo?.user ? (
            <UserAvatar
              userId={assetInfo.user.id}
              firstName={assetInfo.user.first_name}
              lastName={assetInfo.user.last_name}
              variant="small"
            />
          ) : null}
        </div>
      </div>
      <div className="nameBlock">
        <EllipsisText text={assetInfo?.asset_name} width={200} className="nametext">
          <Link href={`${ERoutes.equipment}/${props.asset.id}#tab=profile`}>
            {assetInfo?.asset_name}
          </Link>
        </EllipsisText>

        {assetInfo?.asset_vin ? (
          <div style={{ color: colors.textGrey }}>VIN {assetInfo.asset_vin}</div>
        ) : assetInfo?.serial_number ? (
          <div style={{ color: colors.textGrey }}>
            SN {assetInfo.serial_number}
          </div>
        ) : (
          <div>&nbsp;</div>
        )}
      </div>
      <div className="iconsBlock">
        <div className="iconContainer">
          {props.hasCamera ? (
            <IconButton icon="camera" variant="row" tooltip="Cameras" />
          ) : null}
        </div>
        {assetDevices.length ? (
          <>
            <div className="iconContainer">
              <IconButton
                icon="maptrac"
                variant="row"
                tooltip="View on Map"
              />
            </div>
            <div className="iconContainer">
              <IconButton
                icon="timeline"
                variant="row"
                tooltip="View Timeline"
              />
            </div>
            <div className="iconContainer">
              <IconButton icon="events" variant="row" tooltip="View Events" />
            </div>
          </>
        ) : (
          <div style={{ width: 102 }}>
            <Button
              variant="outlined"
              size="small"
              onClick={onAddDeviceClicked}
            >
              <Icon icon="add" variant="button" />
              Device
            </Button>
          </div>
        )}
        <div className="iconContainer">
          <ServiceButton
            assetId={props.asset.id}
            tasks={props.asset.maintenance_task_performeds}
          />
        </div>
      </div>
      <div className="iconContainer">
        <FlagButton assetId={props.asset.id} flags={flags} />
      </div>
      <div style={{ width: 120, margin: '0 14px' }}>
        {assetInfo?.category ? (
          <CategoryChip
            name={assetInfo?.category.name ? assetInfo?.category.name : ''}
            icon={assetInfo?.category.icon ? assetInfo?.category.icon : 'car'}
            color={assetInfo?.category.color ? assetInfo?.category.color : ''}
          />
        ) : null}
      </div>
      {props.clientFiltered ? (
        <div style={{ width: 120, margin: '0 14px' }}>
          {props.agreement ? (
            <EquipClientSiteRow
              clientName={props.agreement.client.name}
              siteName={props.agreement.site.name}
              clientUrl={`/app/clients/${props.agreement.client.id}`}
            />
          ) : null}
        </div>
      ) : null}
      <FieldRow
        device={props.asset.id}
        snapshots={snapshots}
      />
      {
        anchorEl
        && <AddDeviceComponent
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          addDevices={addDevices}
          devicesByModel={props.devicesByModel}
        />
      }
    </div>
  )
}

export default ThingRow
