import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import Button from '../buttons/Button'
import EllipsisText from '../ux/EllipsisText'
import Icon from '../Icon'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import { ERoutes } from '../../components/routes/CheckRoutes'
import { IUser } from '../../types/account/user'
import SubscribeUsersDialog from './dialogs/SubscribeUsersDialog'
import UserChip from './UserChip'
import { ITreeLayout } from '../../components/ux/TreeSelector'

const styles = createStyles({
  container: {
    padding: '10px 20px',
    display: 'flex',
    position: 'relative',
  },
  contentsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 10,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  usersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 8,
  },
  flagIcon: {
    marginRight: 6,
    cursor: 'pointer',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
})

export interface ISubscriptionUser extends IUser {
  notifyFlagged: string
  forAssets: any[]
}

interface IProps extends WithStyles<typeof styles> {
  isFlag?: boolean
  name: string
  id?: string
  allUsers: IUser[]
  subscribedUsers: ISubscriptionUser[]
  treeAssetsCategory: ITreeLayout[]
}

interface IState {
  subscribeUsersOpen: boolean
}

class SubscriptionRow extends React.Component<IProps> {
  state: IState = {
    subscribeUsersOpen: false,
  }

  render() {
    const classes = this.props.classes
    return (
      <List disablePadding>
        <ListItem style={{ padding: '10px 20px' }}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              marginRight: 10,
            }}
          >
            <div style={{ display: 'flex' }}>
              {this.props.isFlag ? (
                <>
                  <div
                    style={{
                      backgroundColor: '#ba68c8',
                      top: 0,
                      left: 0,
                      width: 6,
                      bottom: 0,
                      position: 'absolute',
                    }}
                  />
                  <Link href={this.props.id ? '/' : ERoutes.subscriptions}>
                    <Icon icon="flag" className={classes.flagIcon} />
                  </Link>
                </>
              ) : null}
              <ListItemText
                primary={
                  <EllipsisText text={this.props.name}>
                    {this.props.name}
                  </EllipsisText>
                }
                style={{ paddingBottom: 8 }}
              />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', padding: 8 }}>
              {this.props.subscribedUsers.map((user) => (
                <UserChip
                  key={user.id}
                  name={`${user.firstName} ${user.lastName}`}
                  id={user.id}
                  onClickSettings={() => console.log()}
                  onClickDelete={() => console.log()}
                  flagId={this.props.id}
                  flagName={this.props.name}
                  treeAssetsCategory={this.props.treeAssetsCategory}
                />
              ))}
            </div>
          </div>

          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => this.setState({ subscribeUsersOpen: true })}
          >
            Subscribe Users
          </Button>
        </ListItem>
        <Divider />
        {this.state.subscribeUsersOpen ? (
          <SubscribeUsersDialog
            dialogOpen={this.state.subscribeUsersOpen}
            onClose={() => this.setState({ subscribeUsersOpen: false })}
            allUsers={this.props.allUsers}
            flagId={this.props.id}
            flagName={this.props.name}
          />
        ) : null}
      </List>
    )
  }
}

export default withStyles(styles)(SubscriptionRow)
