import React from 'react'
import { AssetMarkerEquipOverlay } from '../AssetMarker'
import Geofence from '../Geofence'
import SiteMarker from '../SiteMarker'
import { ISite } from '../../../types/maptrac/Sites'
import { ERadiusType, TRadiusType } from '../GeofenceDrawingManager'
import FullTags from './maptracLiveTags/FullTags'
import { ILatLng } from '../../../types/maptrac/LatLng'
import { IAsset, IAssetClicked } from '../../../types/maptrac/Assets'
import { AssetTabAsset } from '../../inventory/AssetsTabTypes'
import { ITag } from '../../../types/maptrac/Tags'

import { getAssetGps } from '../../../api/asset'
import { Geofence as IGeofence } from '../../../types/geofence'


const LiveMaptracMarkers = (props: {
  isMobile: boolean
  assetClicked: (val: IAssetClicked) => void
  geofenceClicked: (
    geofenceId: string,
    latlng: google.maps.LatLng[] | ILatLng[],
    geofenceType: TRadiusType,
    radius: number
  ) => void
  BLETagsShown: boolean
  assetMarkers: AssetTabAsset[]
  geofenceMarkers: IGeofence[]
  siteMarkers: ISite[]
  siteClicked: (siteId: string, gps: ILatLng) => void
  tagMarkers: Array<ITag[]>
}) => {
  return (
    <>
      {props.assetMarkers &&
        props.assetMarkers.map((asset: AssetTabAsset) => {
          const assetInfo = asset.asset_info
          const gps = getAssetGps(asset)
          return (
            <AssetMarkerEquipOverlay
              key={asset.id}
              useMetric={false}
              onClick={() =>
                props.assetClicked({ assetId: asset.id, gps })
              }
              category={
                assetInfo?.category
                ? {
                  color: assetInfo?.category.color,
                  icon: assetInfo?.category.icon,
                }
                : undefined
              }
              name={assetInfo?.asset_name || 'No Name'}
              speed={asset.speed}
              ign={asset.ignition}
              heading={asset.heading}
              lat={gps.lat}
              lng={gps.lng}
              isTransparent={asset.markerIsTransparent}
              isHighlighted={asset.markerIsHighlighted}
              tasks={{
                equipId: '123',
                  color: '#0078FF',
                  onMouseEnter: (
                    ev: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => console.log(ev),
                  onMouseLeave: (
                    ev: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => console.log(ev),
              }}
            />
          )
        }
      )}

      {props.geofenceMarkers &&
        props.geofenceMarkers.map((geofence: IGeofence) => (
          <Geofence
            key={geofence.id}
            points={geofence.coordinates.coordinates}
            radius={geofence.coordinates.radius}
            onEditPoints={(p) => console.log(p)}
            type={geofence.coordinates.type}
            onClick={(paths) => {
              props.geofenceClicked(
                geofence.id,
                paths,
                geofence.coordinates.type || ERadiusType.Polygon,
                geofence.coordinates.radius || 0
              )
            }}
          />
        ))}

      {props.siteMarkers &&
        props.siteMarkers.map((site: ISite, key) => (
          <SiteMarker
            key={key}
            latLng={site.siteGps}
            name={site.siteName}
            onClick={() => props.siteClicked(site.siteId, site.siteGps)}
          />
        ))}

      {props.BLETagsShown ? (
        props.tagMarkers.length > 0 ? (
          <FullTags
            totalTags={props.tagMarkers}
            onClick={props.assetClicked}
          />
        ) : null
      ) : null}
    </>
  )
}

export default LiveMaptracMarkers
