import React, {useState} from 'react'
import EntityDetailsPanel from '../../EntityDetailsPanel'
import { ICategory } from '../../../../types/category'
import { colors } from '../../../ux/roviTheme'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { backend_api, headersAPI } from '../../../../constants/api'
import { LoadingIcon } from '../../../LoadingOverlay'
import AssetListItem from '../maptracLiveAsset/AssetListItem'
import { IAsset } from '../maptracLiveSites/SiteDetailsMaptrac'
import ListSubheader from '@mui/material/ListSubheader'
import SiteListItem from '../maptracLiveSites/ListItemSite'
import { ILatLng } from 'src/types/generated'
import postDeleteGeofence from '../../../../apiCalls/postCalls/postDeleteGeofence'
import SnackBar from '../../../ux/SnackBar'
import { TErrorTypes } from '../../../../types/apiCalls'
import { Geofence } from '../../../../types/geofence'
import { getHash } from '../../../../constants/hashGrabber'
import { IMaptracHash } from '../../../../constants/maptracHashController'

const styles = createStyles({
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    fontSize: 12,
    height: 15,
  },
  centerRow: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: 'white',
    width: 16,
    height: 16,
  },
  noItemsMessage: {
    fontSize: 16,
    padding: 20,
    textAlign: 'center',
    color: colors.textGrey,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  flagsIcon: {
    color: 'white',
  },
  spacing: {
    marginRight: 8,
  },
})

interface IDetailsProps extends WithStyles<typeof styles> {
  category: ICategory
  make: string
  model: string
}

interface IProps extends WithStyles<typeof styles> {
  isMobile: boolean
  onBack: () => void
  assetClicked: (id: any) => void
  siteClicked: (id: any, gps: ILatLng) => void
  unFocus: () => void
  setEditedGeofence: (val: Geofence) => void
  deleteGeofence: (geofence: Geofence) => void
  geofence: Geofence
}

interface IState {
  geofence?: Geofence
  snackBarMessage: string
  snackBarError: TErrorTypes
}

const GeofenceDetailsMaptrac = (props: IProps) => {
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [snackBarError, setSnackBarError] = useState('error')

  return props.geofence ? (
    <>
      <EntityDetailsPanel
        onBack={props.onBack}
        isMobile={props.isMobile}
        width={350}
        title={props.geofence.name}
        navItems={[
          {
            label: 'Edit',
            icon: 'edit',
            onClick: () => {
              if (props.geofence) {
                props.setEditedGeofence(props.geofence)
              }
            },
          },
          {
            label: 'Delete',
            icon: 'delete',
            onClick: () => props.deleteGeofence(props.geofence),
          },
        ]}
      >
        {props.geofence.geofenceAssets ? (
          <>
            <ListSubheader inset={false}>
              Assets ({props.geofence.geofenceAssets.length})
            </ListSubheader>
            {props.geofence.geofenceAssets.map((equip: IAsset, key) => (
              <AssetListItem
                key={key}
                id={equip.id}
                name={equip.name}
                make={equip.make}
                model={equip.model}
                imageUrl={equip.imageUrl}
                icon={equip.category.icon}
                onClick={() => props.assetClicked(equip.id)}
                tasks={equip.tasks}
                user={equip.user}
                hasCamera={equip.hasCamera}
                battery={equip.battery}
                ignition={equip.ignition}
                flags={equip.flags}
              />
            ))}
          </>
        ) : (
          <ListSubheader inset={false}>Assets (0)</ListSubheader>
        )}
      </EntityDetailsPanel>
      <SnackBar
        message={snackBarMessage}
        onClose={() => setSnackBarMessage('')}
        variant={snackBarError}
      />
    </>
  ) : (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoadingIcon loading />
    </div>
  )
}

export default withStyles(styles)(GeofenceDetailsMaptrac)
