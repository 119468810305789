import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import { backend_api, headersAPI } from '../../../constants/api'
import Select, { ISelectValues } from '../../ux/Select'
import TextField from '../../textBoxs/TextField'
import InfoHover from '../../buttons/InfoHover'
import Button from '../../buttons/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { AssetTabAsset } from '../AssetsTabTypes'
import { Branch } from '../../../types/tenant'

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    dialogContent: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    toBranchDropdown: {
      marginTop: 14,
      marginBottom: 0,
    },
    commentField: {
      marginTop: 14,
      marginBottom: 0,
      width: '97.25%',
    },
    transferingAsset: {
      maxHeight: 200,
      overflowY: 'auto',
    },
  })

interface ISelectedBranches {
  name: string
  branch: string
  id: string
}

interface IProps extends WithStyles<typeof styles> {
  open: boolean
  onClose: () => void
  onSubmit: (
    selectedAssetIds: string[],
    newBranchId: string,
    oldBranchId: string,
    comment: string
  ) => void
  selectedAssets: string[]
  allAssets: AssetTabAsset[]
  branches: Branch[]
  currentBranch?: Branch
  isMobile: boolean
}

const TransferEquipmentDialog = (props: IProps) => {
  const [comment, setComment] = useState('')
  const [selectedBranchId, setSelectedBranchId] = useState('')

  function handleClose() {
    props.onClose()
    setComment('')
    setSelectedBranchId('')
  }

  function handleSubmit() {
    if (!props.currentBranch?.id) {
      throw Error('User is not currently in a branch.')
    }

    props.onSubmit(
      props.selectedAssets,
      selectedBranchId,
      props.currentBranch.id,
      comment
    )
    props.onClose()
  }

  function sortbranches(branches: Branch[]) {
    let sortedBranches: ISelectValues[] = []
    branches.forEach((branch) => {
      if (branch.id === props.currentBranch?.id) {
        return
      }

      sortedBranches.push({
        value: branch.id,
        label: branch.branch_name,
      })
    })
    return sortedBranches
  }

  function loadTableOfAssets(assets: AssetTabAsset[], selected: string[]) {
    let newAssets: ISelectedBranches[] = []
    assets.forEach((asset) => {
      if (selected.includes(asset.id)) {
        newAssets.push({
          name: asset.asset_info?.asset_name || 'No Name',
          branch: props.currentBranch?.branch_name || 'Current branch not selected',
          id: asset.id,
        })
      }
    })
    return newAssets
  }

  const isSaveButtonDisabled = !(
    !!selectedBranchId
    && !!comment
  )

  return (
    <Dialog open={props.open} onClose={handleClose} fullWidth>
      <DialogTitle>
        <div className={props.classes.dialogTitle}>
          <div>Transfer</div>
          <InfoHover>
            You are transfering assets from one branch to another.
          </InfoHover>
        </div>
      </DialogTitle>
      <DialogContent className={props.classes.dialogContent}>
        <div className={props.classes.transferingAsset}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  Asset (
                  {
                    loadTableOfAssets(
                      props.allAssets,
                      props.selectedAssets
                    ).length
                  }
                  )
                </TableCell>
                <TableCell>Current Branch</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadTableOfAssets(
                props.allAssets,
                props.selectedAssets
              ).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.branch}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className={props.classes.fieldContainer}>
          <Select
            label="To Branch"
            selectedValue={selectedBranchId}
            values={sortbranches(props.branches)}
            onChange={(value) => setSelectedBranchId(value)}
            required
            className={props.classes.toBranchDropdown}
          />
          <TextField
            label="Comment"
            required
            formClassName={props.classes.commentField}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            multiline
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color="primary"
          onClick={() => {
            handleSubmit()
            handleClose()
          }}
          disabled={isSaveButtonDisabled}
        >
          Transfer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(TransferEquipmentDialog)
