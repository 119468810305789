import React from 'react'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { CardItem } from '../ux/Attribute'
import { IEquipmentProfile } from '../../types/equipment/equipmentProfile'
import { AssetTabAsset } from '../../components/inventory/AssetsTabTypes'
import moment from 'moment'

const styles = createStyles({
  CardBorder: {
    width: '100%',
    marginTop: 5,
    marginBottom: 5,
  },
  cardContentMobile: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    width: 175.67,
  },
})

/**
 * @description
 * This Component is a Material-UI Card that holds the basic information of the asset, such as the Make/Model, and VIN, etc.
 * This component is already split between both mode Mobile and Desktop so its easy to use outside of this component.
 * @warning This Component is not Dynamic and only displays what's written inside the component already.
 */
const AssetInformationCard = (props: {
  asset: AssetTabAsset
  isMobile: boolean
  useMetric: boolean
  classes: any
}) => {
  const { asset } = props
  const assetInfo = asset?.asset_info
  return (
    <>
      {props.isMobile ? (
        // Mobile Version \/
        <Card
          className={props.classes.CardBorder}
          style={{ marginBottom: 0 }}
        >
          <CardContent>
            <Typography variant="h6">Asset Information</Typography>
          </CardContent>
          <CardContent>
            <div>
              <Typography>Available</Typography>
            </div>
          </CardContent>
          <CardContent style={{ display: 'flex' }}>
            <CardItem
              title="Make / Model"
              value={`${assetInfo?.asset_make} / ${assetInfo?.asset_model}`}
            />
          </CardContent>
          <CardContent style={{ display: 'flex' }}>
            <CardItem
              title="Category"
              value={assetInfo?.category?.name}
            />
            <CardItem
              title="Year Built"
              value={
                assetInfo?.manufacture_year || '-'
              }
            />
          </CardContent>
          <CardContent style={{ display: 'flex' }}>
            <CardItem
              title="Vin Number"
              value={assetInfo?.asset_vin || '-'}
            />
            <CardItem
              title="Serial Number"
              value={assetInfo?.serial_number || '-'}
            />
          </CardContent>
          <CardContent style={{ display: 'flex' }}>
            <CardItem
              title="Purchase Price"
              value={
                assetInfo?.purchase_price
                  ? '$' + assetInfo?.purchase_price
                  : '-'
              }
            />
            <CardItem
              title="Purchase Date"
              value={
                assetInfo?.purchase_date
                  ? moment(assetInfo?.purchase_date).format(
                      'MMM DD, YYYY'
                    )
                  : '-'
              }
            />
          </CardContent>
          <CardContent style={{ display: 'flex', width: '100%' }}>
            <div className={props.classes.cardContentMobile}>
              <Link href={`/app/equipment/${props.asset.id}/reports`}>
                {asset.metrics.find(
                  (metric) => metric.field === 'engineHour'
                )
                  ? asset.metrics.find(
                      (metric) => metric.field === 'engineHour'
                    )?.value
                  : '-'}
              </Link>
              <Link
                href={`/app/equipment/${props.asset.id}/adjustEngineHours`}
                style={{ fontSize: 13, marginLeft: 4 }}
              >
                [Adjust]
              </Link>
              <Typography
                variant="caption"
                component="p"
                color="textSecondary"
              >
                Engine Hour Meter
              </Typography>
            </div>
            <p>
              {asset.metrics.find(
                (metric) => metric.field === 'odometer'
              )
                ? asset.metrics.find(
                    (metric) => metric.field === 'odometer'
                  )?.value
                : '-'}
            </p>
            <Typography variant="caption" component="p" color="textSecondary">
              Odometer
            </Typography>
          </CardContent>
          <CardContent style={{ display: 'flex' }}>
            <CardItem
              title="Notes"
              value={
                <span style={{ whiteSpace: 'pre-wrap' }}>
                  {
                    asset?.asset_notes.length
                    ? asset.asset_notes.map((note) => <p key={note.id}>{note}</p>)
                    : '-'
                  }
                </span>
              }
            />
          </CardContent>
        </Card>
      ) : (
        // Desktop Version \/

        <Card className={props.classes.CardBorder}>
          <CardContent>
            <Typography variant="h6">Asset Information</Typography>
          </CardContent>
          <CardContent style={{ display: 'flex' }}>
            <CardItem
              title="Category"
              value={assetInfo?.category?.name}
            />
            <CardItem
              title="Year Built"
              value={
                assetInfo?.manufacture_year || '-'
              }
            />
            <CardItem
              title="Vin Number"
              value={assetInfo?.asset_vin || '-'}
            />
          </CardContent>
          <CardContent style={{ display: 'flex' }}>
            <CardItem
              title="Purchase Price"
              value={
                assetInfo?.purchase_price
                  ? '$' + assetInfo?.purchase_price
                  : '-'
              }
            />
            <CardItem
              title="Purchase Date"
              value={
                assetInfo?.purchase_date
                  ? moment(assetInfo?.purchase_date).format(
                      'MMM DD, YYYY'
                    )
                  : '-'
              }
            />
            <CardItem
              title="Serial Number"
              value={assetInfo?.serial_number || '-'}
            />
          </CardContent>

          <CardContent style={{ display: 'flex', width: '100%' }}>
            <div className={props.classes.cardContent}>
              <Link href={`/app/equipment/${props.asset.id}/reports`}>
                {asset?.metrics.find(
                  (metric) => metric.field === 'engineHour'
                )
                  ? asset.metrics.find(
                      (metric) => metric.field === 'engineHour'
                    )?.value
                  : '-'}
              </Link>
              <Link
                href={`/app/equipment/${props.asset.id}/adjustEngineHours`}
                style={{ fontSize: 13, marginLeft: 4 }}
              >
                [Adjust]
              </Link>
              <Typography
                variant="caption"
                component="p"
                color="textSecondary"
              >
                Engine Hour Meter
              </Typography>
            </div>

            <div className={props.classes.cardContent}>
              <p>
                {asset.metrics.find(
                  (metric) => metric.field === 'odometer'
                )
                  ? asset.metrics.find(
                      (metric) => metric.field === 'odometer'
                    )?.value
                  : '-'}
              </p>
              <Typography
                variant="caption"
                component="p"
                color="textSecondary"
              >
                Odometer
              </Typography>
            </div>
          </CardContent>
          <CardContent style={{ display: 'flex' }}>
            <CardItem
              title="Notes"
              value={
                <span style={{ whiteSpace: 'pre-wrap' }}>
                  {
                    asset?.asset_notes.length
                    ? asset.asset_notes.map((note) => <p key={note.id}>{note}</p>)
                    : '-'
                  }
                </span>
              }
            />
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default withStyles(styles)(AssetInformationCard)
