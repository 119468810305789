import { gql, useQuery, useMutation } from '@apollo/client'
import {useState} from 'react'
import {
  AmplifyUser,
} from '../types/account/user'
import {
  ICategory,
  CategoryWithAssets,
} from '../types/category'
import {AssetTabAssetCategory} from '../components/inventory/AssetsTabTypes'

export const GET_CATEGORIES_BY_TENANT_ID =  gql`
  query GetCategoriesByTenantId($tenant_id: uuid!) {
    assets_asset_categories(where: {tenant_id: {_eq: $tenant_id}}) {
      id
      name
      icon
      color
    }
  }
`

export const GET_CATEGORIES_BY_TENANT_ID_WITH_ASSET_COUNT =  gql`
  query GetCategoriesByTenantIdWithAssetCount($tenant_id: uuid!) {
    assets_asset_categories(where: {tenant_id: {_eq: $tenant_id}}) {
      id
      name
      icon
      color
      asset_infos_aggregate {
        aggregate {
        count
        }
      }
    }
  }
`

export const GET_CATEGORIES_BY_TENANT_ID_WITH_ASSETS =  gql`
  query GetCategoriesByTenantIdWithAssets($tenant_id: uuid!) {
    assets_asset_categories(where: {tenant_id: {_eq: $tenant_id}}) {
      id
      name
      icon
      color
      asset_infos {
        asset_id
        asset_name
      }
    }
  }
`

export function getCategories(
  amplifyUser: AmplifyUser|null
): {
  categories: AssetTabAssetCategory[]
  loadingCategories: boolean
} {
  const { loading, error, data, refetch } = useQuery(GET_CATEGORIES_BY_TENANT_ID, {
    variables: {
      tenant_id: amplifyUser?.['custom:tenantID'],
    },
    skip: !amplifyUser,
  })
  return {
    categories: data?.assets_asset_categories || [],
    loadingCategories: loading,
  }
}

export function getCategoriesWithAssetCount(
  amplifyUser: AmplifyUser|null
): {
  categories: ICategory[]
  loadingCategories: boolean
} {
  const { loading, error, data, refetch } = useQuery(
    GET_CATEGORIES_BY_TENANT_ID_WITH_ASSET_COUNT,
    {
      variables: {
        tenant_id: amplifyUser?.['custom:tenantID'],
      },
      skip: !amplifyUser,
    }
  )
  return {
    categories: data?.assets_asset_categories || [],
    loadingCategories: loading,
  }
}

export function getCategoriesWithAssets(
  amplifyUser: AmplifyUser|null
): {
  categories: CategoryWithAssets[]
  loadingCategories: boolean
} {
  const { loading, error, data, refetch } = useQuery(
    GET_CATEGORIES_BY_TENANT_ID_WITH_ASSETS,
    {
      variables: {
        tenant_id: amplifyUser?.['custom:tenantID'],
      },
      skip: !amplifyUser,
    }
  )
  return {
    categories: data?.assets_asset_categories || [],
    loadingCategories: loading,
  }
}

const INSERT_CATEGORY = gql`
mutation($category: assets_asset_categories_insert_input!) {
  insert_assets_asset_categories_one(object: $category) {
    id
  }
}
`

export function queryInsertCategory() {
  const [insertCategory, { loading, error, data }] = useMutation(
    INSERT_CATEGORY,
    {
      refetchQueries: [
        'GetCategoriesByTenantId',
        'GetCategoriesByTenantIdWithAssetCount',
      ],
    }
  )
  return insertCategory
}

const UPDATE_CATEGORY = gql`
mutation(
  $id: Int!
  $name: String!
  $color: String!
  $icon: String!
) {
  update_assets_asset_categories_by_pk(
    pk_columns: {id: $id}
    _set: {
      name: $name
      color: $color
      icon: $icon
    }
  ) {
    id
  }
}
`

export function queryUpdateCategory() {
  const [updateCategory, { loading, error, data }] = useMutation(
    UPDATE_CATEGORY,
    {
      refetchQueries: [
        'GetCategoriesByTenantId',
        'GetCategoriesByTenantIdWithAssetCount',
      ],
    }
  )
  return updateCategory
}

const DELETE_CATEGORY = gql`
mutation($id: Int!) {
  delete_assets_asset_categories_by_pk(id: $id) {
    id
  }
}
`

export function queryDeleteCategory() {
  const [deleteCategory, { loading, error, data }] = useMutation(
    DELETE_CATEGORY,
    {
      refetchQueries: [
        'GetCategoriesByTenantId',
        'GetCategoriesByTenantIdWithAssetCount',
      ],
    }
  )
  return deleteCategory
}
