// const importantMetrics = [
//   Field.LastUpdate,
//   Field.SignalStrength,
//   Field.Ignition,
//   Field.GPS,
//   Field.Fuel,
// ]

import React, { useState, useEffect } from "react"
import "../../styles/components/inventory/FieldRow.scss"
import { backend_api, headersAPI } from "../../constants/api"
import { View } from "./FieldView"
import { Snapshot } from "./AssetsTabTypes"

const metricPath = "/assets/metrics"

const FieldRow = (props: {
  deviceId: string, snapshots: Snapshot[]
}) => {
  if (props.snapshots.length === 0) {
    return (<></>)
  }

  const metrics = {
    ...props.snapshots[0],
    __typename: undefined,
    device_id: undefined,
  }

  return (
    <div className="fieldsContainer">
      {Object.entries(metrics).map(([key, value]) => (
        <div key={key}>
          <View
            field={key}
            value={value}
            equipId={props.deviceId}
          />
        </div>
      ))}
    </div>
  )
}

export default FieldRow
