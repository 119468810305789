import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Button from '../buttons/Button'
import EllipsisText from '../ux/EllipsisText'
import { backend_api, headersAPI } from '../../constants/api'
import { LoadingIcon } from '../LoadingOverlay'
import ReinstateDialog from './dialogs/ReinstateDialog'

interface IAssetArchivedSold {
  name: string
  id: string
}

interface IProps {}

interface IState {
  loadingArchive: boolean
  archiveData: IAssetArchivedSold[]
  reinstateDialogOpen: boolean
  assetSelected?: IAssetArchivedSold
}

class ArchiveEquipmentTable extends React.Component<IProps, IState> {
  state: IState = {
    loadingArchive: true,
    archiveData: [],
    reinstateDialogOpen: false,
  }

  async grabArchivedData() {
    await fetch(backend_api + '/devices/archived', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          archiveData: response,
          loadingArchive: false,
        })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.grabArchivedData()
  }

  render() {
    return (
      <>
        <Typography
          variant="h6"
          style={{ paddingTop: 10, paddingLeft: 20, display: 'flex' }}
        >
          Archived Equipment
          {this.state.loadingArchive ? (
            <div style={{ marginLeft: 10 }}>
              <LoadingIcon loading />
            </div>
          ) : null}
        </Typography>

        <Paper style={{ margin: 10, overflow: 'hidden' }}>
          {this.state.archiveData.map((val, key) => (
            <List disablePadding key={key}>
              <ListItem>
                <ListItemText
                  primary={
                    <EllipsisText text={val.name}>
                      <Link href="/">{val.name}</Link>
                    </EllipsisText>
                  }
                />

                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() =>
                    this.setState({
                      assetSelected: val,
                      reinstateDialogOpen: true,
                    })
                  }
                >
                  Reinstate
                </Button>
              </ListItem>
              <Divider />
            </List>
          ))}
        </Paper>
        {this.state.assetSelected ? (
          <ReinstateDialog
            dialogOpen={this.state.reinstateDialogOpen}
            onClose={() =>
              this.setState({
                reinstateDialogOpen: false,
                assetSelected: undefined,
              })
            }
            assetId={this.state.assetSelected.id}
            assetName={this.state.assetSelected.name}
          />
        ) : null}
      </>
    )
  }
}

export default ArchiveEquipmentTable
