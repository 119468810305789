import React from 'react'
import {Link as RouterLink, LinkProps as RouterLinkProps} from 'react-router-dom'
import Link, { LinkProps } from '@mui/material/Link'

import { ThemeOptions } from '@mui/material/styles/createTheme'
import {
  teal,
  grey,
  red,
  yellow,
  green,
  blue,
  orange,
  lightGreen,
  lightBlue,
  purple,
  cyan,
  brown,
  indigo,
  lime,
  blueGrey,
  deepOrange,
  deepPurple,
} from '@mui/material/colors'

interface IRoviColors {
  primaryBlue: string
  primaryBlue2: string
  primaryBlue3: string
  primaryOrange: string
  primaryOrange2: string
  accentOrange: string
  accentLightGrey: string
  accentDarkGrey: string
  accentLightBlue: string
  assetBorderColor: string

  defaultText: string
  flippedText: string
  black: string
  white: string

  bg: string
  mbg: string
  cbg: string
  bbg: string
  dbg: string

  abc: string

  disabled: string

  borderColor: string
  onGreen: string
  offGrey: string

  highlightedBackground: string
  wBodyBackgroundColor: string

  paper: string
  red: string
  textGrey: string
  textDarkGrey: string
  hoverGrey: string
  iconColor: string
  seafoamGreen: string
  imageBackground: string
  green: string
  validSensorColor: string
  pendingColor: string
  calendarSelection: string
  yarnPageTitles: string
}

/**
 * Here we check the user device, such as android, windows, macos, ios
 */
// export const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

export const darkMode =
  localStorage.getItem('darkMode') === 'dark'
    ? true
    : localStorage.getItem('darkMode') === 'light' && false

/**
 * The Default Rovitracker Colors
 *
 * All ternary Operators here are suppose to be temporary reading into Material.io they say the dark mode for android
 * is done, but web, ios, and flutter are planned for the future until than we will create our own designs for everything
 * @see https://material.io/design/color/dark-theme.html#implementation
 */
export const colors: IRoviColors = {
  /**
   * Rovitracker's Main Default Colors
   */
  primaryBlue: '#0078FF', // royal blue - Rovitracker's Main Blue Color
  primaryOrange: '#ff6f00', // - Rovitracker's Main Orange Color - lighter orange
  primaryBlue2: blue[900],
  primaryOrange2: orange[800], // #f47921 #ff6f00 - darker orange
  primaryBlue3: '#0054B2', // this renders most of the site
  accentOrange: orange[300],
  /**
   * Text Colors
   */
  defaultText: darkMode ? '#fff' : '#000',
  flippedText: darkMode ? '#000' : '#fff',
  black: '#000',
  white: '#fff',
  /**
   * Background Colors
   */
  bg: darkMode ? grey[800] : '#fff', // bg standing for back ground
  mbg: darkMode ? grey[800] : grey[100], // mbg standing for main back ground - used for the default white to dark mode background
  cbg: darkMode ? grey[700] : grey[100], // cbg standing for card back ground
  bbg: darkMode ? grey[800] : grey[300], // bbg standing for box back ground
  dbg: darkMode ? grey[900] : '#f4f4f4', // dbg standing for the default back part - this is a very light grey - default SCSS/CSS Files on rovitracker contain this color, this just following suit
  /**
   * Border Colors
   */
  abc: darkMode ? grey[700] : grey[300], // assetBorderColor
  /**
   * Disabled Colors
   */
  disabled: grey[500],
  /**
   * Rovitracker Background Colors
   */
  highlightedBackground: darkMode ? blue[800] : blue[50], // when selecting assets on inventory page
  paper: darkMode ? grey[800] : '#fff',
  assetBorderColor: darkMode ? grey[700] : grey[300], // used on asset page for the borders of all the assets
  imageBackground: darkMode ? grey[600] : grey[200], // used for picture image backgrounds
  /**
   * Experimental Background Colors, the goal is to darken the very back part of rovitracker, without affecting the rest of rovitracker
   */
  wBodyBackgroundColor: darkMode ? grey[900] : '#fff',
  /**
   * going through and implementing Typography to all text components than will remove this,
   * the other thing is we may need to have a variant of different text components, to change up whats displayed
   * and make light mode look better, in some respects due to dark mode some parts of light look pretty flat and should be changed.
   */
  // used for Text components not connected to the Material-UI there are a lot.
  textGrey: grey[500],
  textDarkGrey: darkMode ? grey[300] : grey[700], // Used for some text that default is way close to the darkness of Dark Mode so its hard to read.

  accentLightGrey: grey[100],
  accentDarkGrey: grey[500],
  accentLightBlue: darkMode ? blue['A200'] : blue[50],

  borderColor: grey[400],

  hoverGrey: grey[200],
  offGrey: darkMode ? grey[600] : grey[200], // IGN background color
  iconColor: darkMode ? grey[300] : grey[800], // icon default for darkMode

  red: red[500],
  onGreen: lightGreen['A400'],
  green: green[500],
  seafoamGreen: darkMode ? teal[800] : '#e4f7f1',

  validSensorColor: green[400],
  pendingColor: yellow[100],
  calendarSelection: darkMode ? lightBlue[600] : lightBlue[50],

  yarnPageTitles: darkMode ? grey[500] : grey[50],
}

/** All the report colors with chart.js and cube.js */
export const COLORS_SERIES = [
  colors.primaryBlue3,
  colors.primaryOrange2,
  purple[500],
  cyan[500],
  green[500],
  yellow[500],
  brown[500],
  red[500],
  indigo[500],
  teal[500],
  lime[500],
  blueGrey[500],
  deepOrange[900],
  deepPurple[900],
  blueGrey[900],
]

const LinkBehavior = React.forwardRef<
  any,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
  >((props, ref) => {
    const { href, ...other } = props
    if (!href) {
      return <div
        {...other}
      />
    }

    // Map href (MUI) -> to (react-router)
    return <RouterLink
      data-testid="custom-link"
      ref={ref}
      to={href}
      {...other}
    />
  })
LinkBehavior.displayName = 'LinkBehavior'

/**
 * Theme for the new Material UI Provider
 * @see https://material-ui.com/customization/themes/
 * This will be the Main @mui Theme for everything
 */
export const muiTheme: ThemeOptions = {
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: colors.primaryBlue3,
      dark: colors.primaryBlue3,
      light: '#0078FF',
      contrastText: '#fff',
    },
    secondary: {
      main: colors.primaryOrange,
      contrastText: grey[50], // (almost white) contrast for text and icons
    },
    background: {
      default: colors.dbg,
      paper: colors.paper,
    },
    action: {
      active: darkMode ? '#fff' : grey[800], // default is too light - darker icons
      disabled: darkMode ? '#eee' : grey[500],
    },
  },
  typography: {
    fontSize: 13,
    // makes smaller for card headers
    h5: {
      fontSize: '1.2rem',
    },
    // makes smaller for ux/AppBar
    h6: {
      fontWeight: 400,
      fontSize: '1rem',
      color: colors.defaultText,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        // @ts-ignore
        component: LinkBehavior,
        underline: "none",
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiListItem: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        a: {
          color: '#0078FF',
          textDecoration: 'none',
        },
      `,
    },
  },
}
