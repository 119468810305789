import React, {useState} from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import { IDeviceType } from '../../../types/account/devices'
import NumberField from '../../textBoxs/NumberField'
import TextField from '../../textBoxs/TextField'
import DialogRow from '../../dialogComponents/DialogRow'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'

const styles = () =>
  createStyles({
    TextField: {
      minWidth: 250,
      marginTop: 14,
      marginBottom: 0,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  deviceType: IDeviceType
}

interface IState {
  quantity: number | null
  quantityError: string
  notes: string
}

const PurchaseDeviceDialog = (props: IProps) => {
  const [quantity, setQuantity] = useState(1)
  const [quantityError, setQuantityError] = useState('')
  const [notes, setNotes] = useState('')

  function handleClose() {
    props.onClose()
  }

  function onSubmit() {
    props.onClose()
  }

  return (
    <Dialog open={props.dialogOpen} onClose={handleClose}>
      <DialogTitle>
        Purchase {props.deviceType.device_type.label} Devices
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        Use this form to request more {props.deviceType.device_type.label} devices.
        <DialogRow
          FieldLeft={
            <NumberField
              label="Quantity"
              required
              value={quantity}
              errorMessage={quantityError}
              className={props.classes.TextField}
              onChange={(event: number | null) => {
                setQuantity(event || 0)
                setQuantityError(
                  event
                    ? event < 1
                      ? 'Invalid amount'
                      : ''
                    : 'Invalid amount',
                )
              }}
            />
          }
        />
        <TextField
          multiline
          rows={3}
          fullWidth
          label="Notes"
          value={notes}
          className={props.classes.TextField}
          onChange={(event) => setNotes(event.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={!!quantityError}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(PurchaseDeviceDialog)
