import React, {useState} from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogRow from '../../dialogComponents/DialogRow'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import Select, { ISelectValues } from '../../ux/Select'
import TextField from '../../textBoxs/TextField'
import Button from '../../buttons/Button'
import { countriesArray } from '../../../constants/countries'
import {
  Tenant,
  TenantInfo,
} from '../../../types/tenant'

const styles = (theme: Theme) =>
  createStyles({
    paper: {},
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  onSave: (tenant: Tenant) => void
  tenant: Tenant
}

const EditAccountDialog = (props: IProps) => {
  const [editableTenant, setEditableTenant] = useState({
    ...props.tenant,
  } as Tenant)

  function updateTenantValue(
    key: keyof Tenant,
    value: any
  ) {
    setEditableTenant({
      ...editableTenant,
      [key]: value,
    })
  }

  function updateTenantInfoValue(
    key: keyof TenantInfo,
    value: any
  ) {
    setEditableTenant({
      ...editableTenant,
      tenants_info: {
        ...editableTenant.tenants_info,
        [key]: value,
      }
    })
  }

  function handleClose() {
    props.onClose()
  }

  function onSubmit() {
    props.onSave(editableTenant)
    props.onClose()
  }

  const tenantInfo = editableTenant?.tenants_info

  const isSaveButtonDisabled = !(
    !!editableTenant.tenant_name
    && !!tenantInfo?.address
    && !!tenantInfo?.state
    && !!tenantInfo?.postal_code
    && !!tenantInfo?.country
    && !!tenantInfo?.phone
    && !!tenantInfo?.fax
  )

  return (
    <Dialog open={props.dialogOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Edit Account</DialogTitle>
      <DialogContent>
        <DialogRow
          FieldLeft={
            <TextField
              label="Company Name"
              required
              className={props.classes.TextField}
              value={editableTenant.tenant_name}
              onChange={(event) => updateTenantValue('tenant_name', event.target.value)}
            />
          }
        />
        <DialogRow
          FieldLeft={
            <TextField
              label="Address"
              required
              className={props.classes.TextField}
              value={tenantInfo?.address}
              onChange={(event) => updateTenantInfoValue('address', event.target.value)}
            />
          }
          FieldRight={
            <TextField
              label="City"
              required
              className={props.classes.TextField}
              value={tenantInfo?.city}
              onChange={(event) => updateTenantInfoValue('city', event.target.value)}
            />
          }
        />
        <DialogRow
          FieldLeft={
            <TextField
              label="State / Province"
              required
              className={props.classes.TextField}
              value={tenantInfo?.state}
              onChange={(event) => updateTenantInfoValue('state', event.target.value)}
            />
          }
          FieldRight={
            <TextField
              label="Postal Code"
              required
              className={props.classes.TextField}
              value={tenantInfo?.postal_code}
              onChange={(event) => updateTenantInfoValue('postal_code', event.target.value)}
            />
          }
        />
        <DialogRow
          FieldLeft={
            <Select
              label="Country"
              required
              values={countriesArray}
              className={props.classes.TextField}
              selectedValue={tenantInfo?.country}
              onChange={(name) => updateTenantInfoValue('country', name)}
            />
          }
        />
        <DialogRow
          FieldLeft={
            <TextField
              label="Phone"
              required
              className={props.classes.TextField}
              value={tenantInfo?.phone}
              onChange={(event) => updateTenantInfoValue('phone', event.target.value)}
            />
          }
          FieldRight={
            <TextField
              label="Fax"
              required
              className={props.classes.TextField}
              value={tenantInfo?.fax}
              onChange={(event) => updateTenantInfoValue('fax', event.target.value)}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={isSaveButtonDisabled}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(EditAccountDialog)
