import React, { useState } from 'react'
import { colors } from '../ux/roviTheme'
import Paper from '@mui/material/Paper'
import Button from '../buttons/Button'
import Icon from '../Icon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'
import Box from '@mui/material/Box'
import moment from 'moment'

const playIcon = {
  0: 'pause',
  1: 'playArrow',
  2: 'fastForward',
  3: 'doubleFastForward',
}

const styles: Record<string, React.CSSProperties> = {
  mobileContainer: {
    position: 'absolute',
    top: 7,
    left: 7,
    zIndex: 9,
    transition: '0.33s',
    backgroundColor: colors.wBodyBackgroundColor,
    width: '100%',
  },
  desktopContainer: {
    position: 'absolute',
    bottom: 24,
    right: 48,
    zIndex: 9,
    transition: '0.33s',
    backgroundColor: colors.wBodyBackgroundColor,
    width: '70%',
  },
  Button: {
    color: colors.defaultText,
  },
}

const PlayBackControl = (props: {
  isMobile: boolean
  playSpeed: number
  switchLiveHistory: () => void
  selectedDate: Date
  onChangeDate: (newDate: Date) => void
}) => {
  const [liveAnchorEl, setLiveAnchorEl] = useState(null)
  const [speedAnchorEl, setSpeedAnchorEl] = useState(null)
  const [position, setPosition] = useState(0)

  function handleSpeedClick(speed: number) {
    setSpeedAnchorEl(null)
    // console.log(speed)
  }

  function updatePlaybackTime(seconds: number) {
    // Sets the current time to 0 seconds
    const newDate = new Date(props.selectedDate.getTime())
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)

    // Update the current date with the new seconds
    const MILLISECONDS_IN_SECOND = 1000
    props.onChangeDate(new Date((seconds * MILLISECONDS_IN_SECOND) + newDate.getTime()))
  }

  return (
    <div
      style={
        props.isMobile ? styles.mobileContainer : styles.desktopContainer
      }
    >
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.wBodyBackgroundColor,
        }}
      >
        <div
          style={{
            display: props.isMobile ? 'none' : 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px 16px 0px 16px',
            width: '100%',
          }}
        >
          <Button
            onClick={(ev) => setSpeedAnchorEl(ev.currentTarget)}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Icon
                //@ts-ignore
                icon={playIcon[props.playSpeed]}
                style={{ color: colors.iconColor }}
              />
              <Icon
                icon="arrowDropDown"
                style={{ color: colors.iconColor }}
              />
            </div>
          </Button>

          <PlaybackSlider
            startTimeInSeconds={undefined}
            onTimeChange={updatePlaybackTime}
          />
        </div>

        {!props.isMobile && (
          <Button
            onClick={(ev) => setLiveAnchorEl(ev.currentTarget)}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Icon icon="history" style={{ color: colors.iconColor }} />
              <Icon
                icon="arrowDropDown"
                style={{ color: colors.iconColor }}
              />
            </div>
          </Button>
        )}
        <Menu
          anchorEl={liveAnchorEl}
          open={!!liveAnchorEl}
          onClose={() => setLiveAnchorEl(null)}
        >
          <MenuItem
            style={styles.Button}
            onClick={() => props.switchLiveHistory()}
          >
            <ListItemIcon>
              <Icon icon="liveUpdates" style={{ color: colors.iconColor }} />
            </ListItemIcon>
            <Typography>Live</Typography>
          </MenuItem>
          <MenuItem style={styles.Button} disabled>
            <ListItemIcon>
              <Icon icon="history" style={{ color: colors.iconColor }} />
            </ListItemIcon>
            <Typography>History</Typography>
          </MenuItem>
        </Menu>
        <Menu
          anchorEl={speedAnchorEl}
          open={!!speedAnchorEl}
          onClose={() => setSpeedAnchorEl(null)}
        >
          <MenuItem
            style={styles.Button}
            onClick={() => handleSpeedClick(0)}
            disabled={props.playSpeed === 0}
          >
            <ListItemIcon>
              <Icon icon="pause" style={{ color: colors.iconColor }} />
            </ListItemIcon>
            <Typography>Pause</Typography>
          </MenuItem>
          <MenuItem
            style={styles.Button}
            onClick={() => handleSpeedClick(1)}
            disabled={props.playSpeed === 1}
          >
            <ListItemIcon>
              <Icon icon="playArrow" style={{ color: colors.iconColor }} />
            </ListItemIcon>
            <Typography>1x Speed</Typography>
          </MenuItem>
          <MenuItem
            style={styles.Button}
            onClick={() => handleSpeedClick(2)}
            disabled={props.playSpeed === 2}
          >
            <ListItemIcon>
              <Icon icon="fastForward" style={{ color: colors.iconColor }} />
            </ListItemIcon>
            <Typography>10x Speed</Typography>
          </MenuItem>
          <MenuItem
            style={styles.Button}
            onClick={() => handleSpeedClick(3)}
            disabled={props.playSpeed === 3}
          >
            <ListItemIcon>
              <Icon
                icon="doubleFastForward"
                style={{ color: colors.iconColor }}
              />
            </ListItemIcon>
            <Typography>100x Speed</Typography>
          </MenuItem>
        </Menu>
        {/* <DatePickerFlat
          date={atZone(timestamp, timezone).date}
          onChange={handleDateChange}
          shouldDisableDate={(localDate) => {
            const todayDate = LocalDate.today(timezone)
            return localDate.isAfter(todayDate)
          }}
        /> */}
      </Paper>
    </div>
  )
}

export default PlayBackControl

const PlaybackSlider = (props: {
  startTimeInSeconds?: number
  onTimeChange: (seconds: number) => void
}) => {
  const SECONDS_IN_MINUTE = 60
  const MINUTES_IN_HOUR = 60
  const HOURS_IN_DAY = 24
  const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * MINUTES_IN_HOUR
  const SECONDS_IN_DAY = SECONDS_IN_HOUR * HOURS_IN_DAY

  const _12AM_IN_SECONDS = 0
  const _4AM_IN_SECONDS = 4 * SECONDS_IN_HOUR
  const _8AM_IN_SECONDS = 8 * SECONDS_IN_HOUR
  const _12PM_IN_SECONDS = 12 * SECONDS_IN_HOUR
  const _4PM_IN_SECONDS = 16 * SECONDS_IN_HOUR
  const _8PM_IN_SECONDS = 20 * SECONDS_IN_HOUR

  const MARKS = [
    {
      value: _12AM_IN_SECONDS,
      label: '12 AM',
    },
    {
      value: _4AM_IN_SECONDS,
      label: '4 AM',
    },
    {
      value: _8AM_IN_SECONDS,
      label: '8 AM',
    },
    {
      value: _12PM_IN_SECONDS,
      label: '12 PM',
    },
    {
      value: _4PM_IN_SECONDS,
      label: '4 PM',
    },
    {
      value: _8PM_IN_SECONDS,
      label: '8 PM',
    },
    {
      value: SECONDS_IN_DAY,
      label: '12 AM',
    },
  ]

  const [seconds, setSeconds] = useState(props.startTimeInSeconds || _12AM_IN_SECONDS)

  function secondsToTime(seconds: number): string {
    return moment().startOf('day')
      .seconds(seconds)
      .format('h:mm:ss A')
  }

  function updateTime(seconds: number) {
    setSeconds(seconds)
    props.onTimeChange(seconds)
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    }}>
      <Button
        onClick={(ev) => updateTime(
          (seconds - 10) < 0
            ? SECONDS_IN_DAY
            : (seconds - 10)
        )}
      >
        <Icon
          icon="replay10"
        />
      </Button>

      <Slider
        aria-label="Time Select"
        defaultValue={props.startTimeInSeconds || _12AM_IN_SECONDS}
        valueLabelFormat={secondsToTime}
        getAriaValueText={secondsToTime}
        step={SECONDS_IN_MINUTE}
        min={_12AM_IN_SECONDS}
        max={SECONDS_IN_DAY}
        value={seconds}
        valueLabelDisplay="auto"
        marks={MARKS}
        onChangeCommitted={(event, newValue: number) => updateTime(newValue)}
        onChange={(event, newValue: number) => setSeconds(newValue)}
      />

      <Button
        onClick={(ev) => updateTime((seconds + 10) % SECONDS_IN_DAY)}
      >
        <Icon
          icon="forward10"
        />
      </Button>
    </Box>
  )
}
