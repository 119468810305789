import React, {useState} from 'react'
import { ApolloError } from '@apollo/client'
import { WithStyles, createStyles } from '@mui/styles'
import {
  TextField,
  Paper,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import Button from '../buttons/Button'
import { withStyles } from '@mui/styles'
import { colors } from '../ux/roviTheme'
import { TRadiusType } from './GeofenceDrawingManager'
import InfoHover from '../buttons/InfoHover'
import postUpdateGeofence from '../../apiCalls/postCalls/postUpdateGeofence'
import { LoadingIcon } from '../LoadingOverlay'
import SnackBar from '../ux/SnackBar'
import { TErrorTypes } from '../../types/apiCalls'
import { Geofence } from '../../types/geofence'
import EditablePolygon from './Geofence'
import { ILatLng } from '../../types/maptrac/LatLng'

/**
 * Confluence Documentation
 * https://rovitracker.atlassian.net/l/c/EhVMPibQ
 */

export const GEOFENCE_RULES = {
  NO_NAME: 'Geofence Requires a Name',
}

const styles = () =>
  createStyles({
    positionContainer: {
      overflow: 'visible',
      position: 'absolute',
      top: 40,
      right: 5,
      margin: 10,
      zIndex: 10,
    },
    iconStyle: {
      fill: colors.defaultText,
    },
    positionDialogContainer: {
      position: 'absolute',
      bottom: 24,
      right: '50%',
      zIndex: 20,
      transform: 'translate(50%)',
      display: 'flex',
      flexDirection: 'column',
    },
    textInput: {
      width: 200,
      marginTop: 10,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  isMobile: boolean
  isOpen: boolean
  onClose: () => void
  onSubmit: (geofence: Geofence) => void
  geofence: Geofence
  isSaving: boolean
  errors?: ApolloError
}

const EditGeofence = (props: IProps) => {
  const geofenceCopy: Geofence = JSON.parse(
    JSON.stringify(
      props.geofence || ''
    )
  )

  const [editableGeofence, setEditableGeofence] = useState(geofenceCopy)

  function updateGeofenceValue(
    key: keyof Geofence,
    value: any
  ) {
    setEditableGeofence({
      ...editableGeofence,
      [key]: value,
    })
  }

  function onSave() {
    props.onSubmit(editableGeofence)
    props.onClose()
  }

  /**
   * On cancel this should reset the state to the original state, prevent bugs such as users cant create multiple geofences in a row
   */
  function onCancel() {
    props.onClose()
  }

  return props.isMobile ? null : (
    <>
      {props.isOpen && (
        <Paper className={props.classes.positionDialogContainer}>
          <DialogContent>
            <DialogContentText>
              Click on the map to edit your geofence
              <InfoHover text="A geofence sets a virtual border" />
            </DialogContentText>

            <TextField
              label="Geofence Name"
              value={editableGeofence.name}
              onChange={(ev) => {
                updateGeofenceValue('name', ev.target.value)
              }}
              error={!editableGeofence.name}
              helperText={!editableGeofence.name ? GEOFENCE_RULES.NO_NAME : null}
              className={props.classes.textInput}
              required
              autoFocus
              variant="standard"
              style={{ width: 256 }}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={() => onCancel()}>Cancel</Button>
            <Button
              color="primary"
              onClick={() => onSave()}
              disabled={!editableGeofence.name}
            >
              {props.isSaving ? <LoadingIcon loading /> : 'Save'}
            </Button>
          </DialogActions>

          {props.isOpen && (
            <EditablePolygon
              type={editableGeofence.shape}
              points={editableGeofence.coordinates.coordinates}
              onEditPoints={(points: pointsRef) => {
                console.log(points)
                setEditableGeofence({
                  ...editableGeofence,
                  coordinates: points,
                })
              }}
              editable
            />
          )}
        </Paper>
      )}
      <SnackBar
        message={props.errors?.message || ''}
        onClose={() => {}}
        variant="error"
      />
    </>
  )
}

export default withStyles(styles)(EditGeofence)
