import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import DialogContentText from '@mui/material/DialogContentText'

const DeleteBranchDialog = (props: {
  dialogOpen: boolean
  onClose: () => void
  onSubmit: (branchId: string) => void
  branchName: string
  branchId: string
}) => {

  function handleClose() {
    props.onClose()
  }

  function onSubmit() {
    props.onSubmit(props.branchId)
    props.onClose()
  }

  return (
    <Dialog open={props.dialogOpen} onClose={handleClose}>
      <DialogTitle>Delete {props.branchName}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this branch?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color="primary"
          onClick={onSubmit}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteBranchDialog
