import React from 'react'
import EntityDetailsPanel from '../../EntityDetailsPanel'
// import LocationDetailsPopover from '../mapComponents/LocationDetailsPopover'
import CategoryIcon from '../../../ux/CategoryIcon'
import { ICategory } from '../../../../types/category'
import TabsContainer from '../../../ux/TabsContainer'
import Divider from '@mui/material/Divider'
import { colors } from '../../../ux/roviTheme'
import HoverContainer from '../../../ux/HoverContainer'
import FlagButton from '../../../ux/FlagButton'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import classNames from 'classnames'
import UserAvatar from '../../../ux/UserAvatar'
import EllipsisText from '../../../ux/EllipsisText'
import { backend_api, headersAPI } from '../../../../constants/api'
import { LoadingIcon } from '../../../LoadingOverlay'
import LocationDetailsPopover from '../../LocationDetailsPopover'
import {
  AssetTabAsset,
} from '../../../inventory/AssetsTabTypes'

import {
  getAssetImage,
  getAssetFlags,
} from '../../../../api/asset'

const styles = createStyles({
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    fontSize: 12,
    height: 15,
  },
  centerRow: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: 'white',
    width: 16,
    height: 16,
  },
  noItemsMessage: {
    fontSize: 16,
    padding: 20,
    textAlign: 'center',
    color: colors.textGrey,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  flagsIcon: {
    color: 'white',
  },
  spacing: {
    marginRight: 8,
  },
})

interface IDetailsProps extends WithStyles<typeof styles> {
  category?: ICategory
  make: string
  model: string
}

const Details = withStyles(styles)((props: IDetailsProps) => (
  <div className={props.classes.detailsContainer}>
    {props.category ? (
      <div className={props.classes.detailsSection}>
        <div className={props.classes.centerRow}>
          <CategoryIcon
            className={classNames(props.classes.icon, props.classes.spacing)}
            icon={props.category ? props.category.icon : 'atv'}
          />
          {props.category.name}
        </div>
        {`${props.make} / ${props.model}`}
      </div>
    ) : null}

    {/* {props.agreement
      .map((a) => (
        <div className={props.classes.detailsSection}>
          <div
            className={props.classes.centerRow}
            style={{ cursor: 'pointer' }}
            onClick={a.onClickSite}
          >
            <Icon
              className={classNames(props.classes.icon, props.classes.spacing)}
              icon="site"
            />
            {a.clientName} / {a.siteName}
          </div>
        </div>
      ))
      .orNull()} */}
  </div>
))

interface IProps extends WithStyles<typeof styles> {
  asset: AssetTabAsset
  isMobile: boolean
  onBack: () => void
  onClickEntity: (id: any) => void
  timezone: string
  onClickCamera: () => void
  onClickTrip: (date: any, endDate: any) => void
  width: number
  gatewayHistoryObs: any
  tagsHistoryObs: any
  setDate: (date: any) => void
  setFocus: (entity: any) => void
}

const AssetDetailsMaptrac = (props: IProps) => {
  const asset = props.asset
  if (!asset) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingIcon loading />
      </div>
    )
  }

  const assetInfo = asset.asset_info
  const assetFlags = getAssetFlags(asset, []) // TODO: Needs to get global flags
  const assetImage = getAssetImage(asset)
  const user = assetInfo?.user
  const snapshot = asset.snapshots?.[0] || {}
  const gps = snapshot?.estimated_lat_lng || {lat: 0, lng: 0}
  const metrics = Object.entries(snapshot)

  return (
    <EntityDetailsPanel
      onBack={props.onBack}
      isMobile={props.isMobile}
      width={props.width}
      titleDense={assetInfo?.asset_name}
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LocationDetailsPopover
            gps={gps}
            timezone="utc"
          />
          <div className={props.classes.spacing}>
            <EllipsisText text={assetInfo?.asset_name} width={100} />
          </div>
          <FlagButton
            assetId={asset.id}
            flags={assetFlags}
            white
          />

          <div style={{ marginLeft: 8 }}>
            {user ? (
              <UserAvatar
                userId={user.id}
                firstName={user.first_name}
                lastName={user.last_name}
                variant="small"
              />
            ) : null}
          </div>
        </div>
      }
      imageUrl={assetImage?.image_url || ''}
      category={assetInfo?.category}
      icon={assetInfo?.category?.icon}
      details={
        <Details
          category={assetInfo?.category}
          make={assetInfo?.asset_make || ''}
          model={assetInfo?.asset_model || ''}
        />
      }
      navItems={[
        {
          label: 'Profile',
          icon: 'profile',
          href: `/asset/${asset.id}#tab=profile`,
        },
        {
          label: 'Timeline',
          icon: 'timeline',
          href: `/asset/${asset.id}#tab=timeline`,
        },
        {
          label: 'Events',
          icon: 'events',
          href: `/asset/${asset.id}#tab=events`,
        },
      ]}
      isEquip
    >
      <>
        <TabsContainer
          labels={
            asset.isGateway
              ? ['Metrics', 'Trips', 'Tag History']
              : asset.isTag
              ? ['Metrics', 'Trips', 'History']
              : ['Metrics', 'Trips']
          }
        >
          {metrics.length ? (
            <div>
              {metrics.map(([key, value]) => (
                <div key={key}>
                  {key} {value?.toString() || 'null'}
                </div>
              ))}
            </div>
          ) : (
            <div className={props.classes.noItemsMessage}>
              No Metrics
            </div>
          )}

          {!asset.assetTrips?.length ? (
            <div className={props.classes.noItemsMessage}>No Trips</div>
          ) : (
            asset.assetTrips.map((trips: any[], tripKey: any) => (
              <HoverContainer key={tripKey}>
                {({ hovered }) => (
                  <div
                    style={{
                      cursor: 'pointer',
                      background: hovered ? colors.hoverGrey : undefined,
                    }}
                    onClick={() => console.log()}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '20px 10px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div>From </div>
                        <div></div>
                      </div>
                      <div>
                        <div>Duration: minutes</div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div>To</div>
                        <div></div>
                      </div>
                    </div>
                    <Divider />
                  </div>
                )}
              </HoverContainer>
            ))
          )}

          {/* History Box - this area splits up depending on the device it self, so if its a gateway 
              it can contain the history of any tag its picked up and display it here. Now if its a
              Tag it will only show its own history if there is any. In the past alain has wanted
              tag history to only show up if it has been recent along side some time stamps. */}
          {asset.isGateway ? (
            asset.assetGatewayHistory &&
            asset.assetGatewayHistory.length !== 0 ? (
              asset.assetGatewayHistory.map(
                (gatewayHist, gHKey) => <div key={gHKey}>history</div>
              )
            ) : (
              <div className={props.classes.noItemsMessage}>
                No Tag History
              </div>
            )
          ) : asset.isTag ? (
            asset.assetTagsHistory &&
            asset.assetTagsHistory.length !== 0 ? (
              asset.assetTagsHistory.map((tagHist, tHKey) => (
                <div key={tHKey}>history</div>
              ))
            ) : (
              <div className={props.classes.noItemsMessage}>
                No History
              </div>
            )
          ) : null}
        </TabsContainer>
      </>
    </EntityDetailsPanel>
  )
}

export default withStyles(styles)(AssetDetailsMaptrac)
