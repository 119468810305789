import React from 'react'

// const dimensions = {
//   'list': { height: 40, width: 64 },
//   'profile': { height: 180, width: 256 },
// }

interface IProps {
  imageUrl: string
  style?: React.CSSProperties
}

const Image: React.FC<IProps> = (props) => (
  <div
    data-testid={props.imageUrl}
    style={{
      backgroundImage: `url(${props.imageUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      ...props.style,
    }}
  />
)

export default Image
