import React, {useState} from 'react'
import ReactHelmet from '../components/ReactHelmet'
import LiveMaptracContainer from '../components/maptrac/maptracLive/LiveMaptracContainer'
import HistoryMaptracContainer from '../components/maptrac/maptracHistory/HistoryMaptracContainer'
import PlayBackControl from '../components/maptrac/PlayBackControl'
import ModeButton from '../components/maptrac/ModeButton'
import WidthViewport from '../components/ux/WidthViewport'

const MaptracPage = (props: {}) => {
  const [isMaptracLive, setIsMaptracLive] = useState(true)
  const [selectedDate, setSelectedDate] = useState(new Date())

  return (
    <WidthViewport>
      {({ height, width, isMobile }) => (
        <div>
          <ReactHelmet title="Maptrac" />
          <div>
            {isMaptracLive ? (
              /**
               * Everything in this section is Maptrac Live Components
               */
              <>
                <ModeButton
                  timezone=""
                  onChangeDate={(val) => console.log(val)}
                  isMobile={isMobile}
                  switchLiveHistory={() => setIsMaptracLive(false)}
                />
              </>
            ) : (
              /**
               * Everything in this section is Maptrac History Components
               */
              <>
                <PlayBackControl
                  isMobile={isMobile}
                  playSpeed={0}
                  switchLiveHistory={() => setIsMaptracLive(true)}
                  selectedDate={selectedDate}
                  onChangeDate={setSelectedDate}
                />
              </>
            )}

            <LiveMaptracContainer
              isMobile={isMobile}
              height={height}
              width={width}
              selectedDate={selectedDate}
              isLive={isMaptracLive}
            />
          </div>
        </div>
      )}
    </WidthViewport>
  )
}

export default MaptracPage
