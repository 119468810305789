import React from 'react'
import { Section } from './EquipmentSection'
import { IEquipTask } from '../../types/equipment/equipmentProfile'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'

interface IProps {
  tasks: IEquipTask[]
  equipId: string
}

class UpcomingMaintenance extends React.Component<IProps> {
  render() {
    return (
      <div style={{ width: '100%', margin: '5px 0' }}>
        <Section
          header="Upcoming Maintenance Tasks"
          equipId={this.props.equipId}
          emptyMessage={
            this.props.tasks.length > 0 ? undefined : 'no Maintenance'
          }
        >
          {this.props.tasks.map((task, i) => (
            <div key={i}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '5px 0',
                }}
              >
                <Link href={`/equipment/${56}/maintenance`}>{task.task}</Link>
              </div>
              {i < this.props.tasks.length - 1 && <Divider />}
            </div>
          ))}
        </Section>
      </div>
    )
  }
}

export default UpcomingMaintenance
