import React, {useState} from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import { IDeviceType } from '../../../types/account/devices'

const LearnMoreDialog = (props: {
  dialogOpen: boolean
  onClose: () => void
  onOpenPurchaseDialog: () => void
  deviceType: IDeviceType
}) => {

  function handleClose() {
    props.onClose()
  }

  function onSubmit() {
    props.onOpenPurchaseDialog()
    props.onClose()
  }

  return (
    <Dialog open={props.dialogOpen} onClose={handleClose}>
      <DialogTitle>{props.deviceType.device_type.label}</DialogTitle>
      <DialogContent>
        Price: {props.deviceType.device_type.price} <br /> <br />
        {props.deviceType.device_type.description}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          color="primary"
          onClick={onSubmit}
        >
          Purchase
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LearnMoreDialog
