import React, {useState} from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogRow from '../../dialogComponents/DialogRow'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import TextField from '../../textBoxs/TextField'
import Button from '../../buttons/Button'

import { IUser } from '../../../types/account/user'

const styles = (theme: Theme) =>
  createStyles({
    paper: {},
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    formControl: {
      margin: theme.spacing(1),
    },
  })

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  onSave: (user: IUser) => void
  user: IUser
}

const EditUserDialog = (props: IProps) => {
  const [editableUser, setEditableUser] = useState({
    ...props.user,
  } as IUser)

  function handleClose() {
    props.onClose()
  }

  function onSubmit() {
    props.onSave(editableUser)
    props.onClose()
  }

  function isSaveButtonDisabled() {
    return !(
      !!editableUser.first_name
      && !!editableUser.last_name
      && !!editableUser.email
    )
  }

  return (
    <Dialog open={props.dialogOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <DialogRow
          FieldLeft={
            <TextField
              label="First Name"
              required
              className={props.classes.TextField}
              value={editableUser.first_name}
              onChange={(event) => setEditableUser({
                ...editableUser,
                first_name: event.target.value,
              })}
            />
          }
          FieldRight={
            <TextField
              label="Last Name"
              required
              className={props.classes.TextField}
              value={editableUser.last_name}
              onChange={(event) => setEditableUser({
                ...editableUser,
                last_name: event.target.value,
              })}
            />
          }
        />
        <DialogRow
          FieldLeft={
            <TextField
              label="Email"
              required
              className={props.classes.TextField}
              value={editableUser.email}
              onChange={(event) => setEditableUser({
                ...editableUser,
                email: event.target.value,
              })}
            />
          }
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={isSaveButtonDisabled()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(EditUserDialog)
