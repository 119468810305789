import React, {useState, useEffect} from 'react'
import '../../styles/components/Appbar.scss'
import WidthViewport from '../ux/WidthViewport'
import DesktopRoviAppbar from './DesktopRoviAppbar'
import MobileRoviAppbar from './MobileRoviAppbar'
import { ILinks } from '../../types/appbar'
import {
  ERoutes,
  PageTitle,
  disabledMobileBarPages,
} from '../routes/CheckRoutes'
import HashStateManager from '../../rx-js/HashStateManager'
import { HashFromString, HashToString } from '../../constants/hashControllers'

import {
  CognitoUserSession,
} from 'amazon-cognito-identity-js'

import {
  getAmplifySession,
  getAmplifyUser,
  getCurrentUser,
} from '../../api/user'

import {
  getBranches,
  getCurrentBranch,
} from '../../api/tenant'

import {
  Branch
} from '../../types/tenant'

const RoviAppbar = (props: {
  title?: string
  appType?: boolean // this is to switch the app location link
}) => {
  const [hash, setHash] = useState('')

  const {amplifySession} = getAmplifySession()
  const {user} = getCurrentUser(amplifySession)

  const [amplifyUser] = getAmplifyUser()
  const {branches} = getBranches(amplifyUser)
  const {currentBranch, setNewCurrentBranch} = getCurrentBranch(user, branches, amplifyUser)

  const links: Array<ILinks> = [
    {
      name: 'DASHBOARD',
      link: ERoutes.dashboard,
      tab: 0,
    },
    // { name: 'YARD', link: ERoutes.yard, tab: 1 },
    // { name: 'CLIENT', link: ERoutes.client, tab: 2 },
    {
      name: 'ASSETS',
      link: ERoutes.inventory,
      tab: 1,
    },
    {
      name: 'MAPTRAC',
      link: ERoutes.maptrac,
      tab: 2,
    },
    {
      name: 'SERVICE',
      link: ERoutes.service,
      tab: 3,
    },
    {
      name: 'REPORTS',
      link: ERoutes.fleetReport,
      tab: 4,
    },
  ]

  useEffect(() => {
    window.addEventListener('popstate', () => {
      let hash = new HashStateManager(HashFromString, HashToString)
      setHash(hash.value.tab)
    })
    return function cleanup() {
      window.removeEventListener('popstate', () => setHash(''))
    }
  }, [])

  return (
    <WidthViewport>
      {({ isMobile }) =>
        isMobile ? (
          disabledMobileBarPages.includes(
            PageTitle(undefined, hash)
          ) ? null : (
            <MobileRoviAppbar
              title={PageTitle(undefined, hash)}
              branches={branches}
            />
          )
        ) : (
          <DesktopRoviAppbar
            links={links}
            user={user}
            branches={branches}
            currentBranch={currentBranch}
            setCurrentBranchId={setNewCurrentBranch}
            title={props.title}
          />
        )
      }
    </WidthViewport>
  )
}

export default RoviAppbar
