import React, {useState} from 'react'
import { ApolloError } from '@apollo/client'
import FabButton from '../buttons/FabButton'
import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import { TextField, Paper } from '@mui/material'
import { WithStyles, createStyles } from '@mui/styles'
import Button from '../buttons/Button'
import { withStyles } from '@mui/styles'
import { colors } from '../ux/roviTheme'
import GeofenceDrawingManager, {
  TRadiusType,
  ERadiusType,
} from './GeofenceDrawingManager'
import InfoHover from '../buttons/InfoHover'
import postCreateGeofence from '../../apiCalls/postCalls/postCreateGeofence'
import { LoadingIcon } from '../../components/LoadingOverlay'
import SnackBar from '../../components/ux/SnackBar'
import { TErrorTypes } from '../../types/apiCalls'
import { GEOFENCE_RULES } from './EditGeofence'
import { NewGeofence } from '../../types/geofence'

/**
 * Confluence Documentation
 * https://rovitracker.atlassian.net/l/c/9U1KbXuW
 */

interface ILatLng {
  lat: number
  lng: number
}

const styles = () =>
  createStyles({
    positionContainer: {
      overflow: 'visible',
      position: 'absolute',
      top: 40,
      right: 5,
      margin: 10,
      zIndex: 10,
    },
    iconStyle: {
      fill: colors.defaultText,
    },
    positionDialogContainer: {
      position: 'absolute',
      bottom: 24,
      right: '50%',
      zIndex: 20,
      transform: 'translate(50%)',
      display: 'flex',
      flexDirection: 'column',
    },
    textInput: {
      width: 200,
      marginTop: 10,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  isMobile: boolean
  onSubmit: (geofence: NewGeofence) => void
  isSaving: boolean
  errors?: ApolloError
}

const GeofenceButton = (props: IProps) => {
  const [geofenceDialogIsOpen, setGeofenceDialogIsOpen] = useState(false)
  const [editableGeofence, setEditableGeofence] = useState(newGeofence())

  function newGeofence(): NewGeofence {
    return {
      name: '',
      shape: ERadiusType.Polygon,
      coordinates: {
        type: 'Polygon',
        radius: undefined,
        coordinates: [],
      }
    }
  }

  function updateGeofenceValue(
    key: keyof NewGeofence,
    value: any
  ) {
    setEditableGeofence({
      ...editableGeofence,
      [key]: value,
    })
  }

  function onSave() {
    props.onSubmit(editableGeofence)
  }

  /**
   * On cancel this should reset the state to the original state, prevent bugs such as users cant create multiple geofences in a row
   */
  function onCancel() {
    setEditableGeofence(newGeofence())
    setGeofenceDialogIsOpen(false)
  }

  const isFormValid = (
    !!editableGeofence.name
  )

  return props.isMobile ? null : (
    <>
      <div className={props.classes.positionContainer}>
        <FabButton
          icon="addGeofence"
          whiteBlackVersion
          noDefaultPostion
          size="small"
          onClick={() => setGeofenceDialogIsOpen(true)}
        />
      </div>

      {geofenceDialogIsOpen && (
        <Paper className={props.classes.positionDialogContainer}>
          <DialogContent>
            <DialogContentText>
              Click on the map to draw a geofence
              <InfoHover text="A geofence sets a virtual border" />
            </DialogContentText>

            <TextField
              label="Geofence Name"
              value={editableGeofence.name}
              onChange={(ev) => {
                updateGeofenceValue('name', ev.target.value)
              }}
              error={!editableGeofence.name}
              helperText={!editableGeofence.name ? GEOFENCE_RULES.NO_NAME : null}
              className={props.classes.textInput}
              required
              autoFocus
              variant="standard"
              style={{ width: 256 }}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={() => onCancel()}>Cancel</Button>
            <Button
              color="primary"
              onClick={() => onSave()}
              disabled={props.isSaving || !isFormValid}
            >
              {props.isSaving ? <LoadingIcon loading /> : 'Save'}
            </Button>
          </DialogActions>

          {geofenceDialogIsOpen && (
            <GeofenceDrawingManager
              onDrawEnd={(points, type, radius) => {
                setEditableGeofence({
                  ...editableGeofence,
                  shape: type || editableGeofence.shape,
                  coordinates: points,
                })
              }}
              editable={!props.isSaving}
            />
          )}
        </Paper>
      )}
      <SnackBar
        message={props.errors?.message || ''}
        onClose={() => {}}
        variant="error"
      />
    </>
  )
}

export default withStyles(styles)(GeofenceButton)
