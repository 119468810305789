import { apiGet } from './apiCall'
import {
  IAssetInfo,
  IAssetMap,
} from '../components/inventory/AssetsTabTypes'
import {
  ITask,
  ITaskGroup,
  ILegacyTask,
  IAssetTasks,
  IAssignAsset,
  TTaskMetric,
} from '../types/service/tasks'

export async function getGroupTasksByTenantId(tenantId: string): Promise<ITaskGroup[]> {
  const data = await apiGet(`/maintenance/groups/tenant/${tenantId}`)
  let groups: ITaskGroup[] = []
  for (const group of data.maintenances) {
    groups.push({
      id: group.group_id,
      name: group.group_name,
      tenantId: group.tenant_id,
      legacyTenantId: group.legacy_tenant_id,
      tasks: getTasksFromGroup(group.tasks),
    })
  }
  return groups
}

export function  getTasksFromGroup(one_time_tasks: any[]): ITask[] {
  let tasks: ITask[] = []
  for (const task of one_time_tasks) {
    tasks.push({
      type: task.interval_type,
      metric: task.interval_type,
      taskName: task.task_name,
      taskId: task.task_id,
      value: task.interval_value,
      notify_users: task.notify_user ?? [],
      assetIds: task.asset || [],
      assets: [],
    })
  }
  return tasks
}

export async function getAllTasksByTenantId(tenantId: string): Promise<ITask[]> {
  const response = await apiGet(`/maintenance/tasks/onetime/tenant/${tenantId}`)
  let tasks: ITask[] = []
  for (const task of response.tasks) {
    tasks.push({
      type: task.task.interval_type,
      metric: task.task.interval_type,
      taskName: task.task.task_name,
      taskId: task.task.task_id,
      value: task.task.interval_value,
      notify_users: task.task.notify_user ?? [],
      assetIds: task.assets
        ? task.assets.map((asset: {id: string}) => asset.id)
        : [],
      assets: [],
    })
  }
  return tasks
}

export function attachAssetsToTasks(tasks: ITask[], assetMap: IAssetMap): ITask[] {
  const updatedTasks: ITask[] = tasks.map(
    (task) => {
      task.assets = task.assetIds.map(
        (assetId) => assetMap[assetId]
      ).filter((asset) => asset !== undefined)
      return task
    }
  )
  return updatedTasks
}
