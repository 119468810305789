import React, {useState} from 'react'
import '../../styles/pages/account/accountPage.scss'
import ReactHelmet from '../../components/ReactHelmet'
import ContactCard from '../../components/accountComponents/ContactCard'
import SubscriptionPlanCard from '../../components/accountComponents/SubscriptionPlanCard'
import AccountBranches from '../../components/accountComponents/AccountBranches'
import LoadingOverlay from '../../components/LoadingOverlay'

import { IAccount } from '../../types/account/account'
import {
  Tenant,
  NewBranch,
} from '../../types/tenant'

import { removeTypename } from '../../utility/graphQl'

import {
  getCurrentTenant,
  queryUpdateTenant,
  getBranches,
  queryInsertBranch,
  queryUpdateBranch,
  queryDeleteBranch,
} from '../../api/tenant'
import {
  getAmplifyUser,
} from '../../api/user'

const AccountPage = (props: {
  isMobile: boolean
}) => {
  const [amplifyUser, setAmplifyUser] = getAmplifyUser()
  const {tenant, loadingTenant} = getCurrentTenant(amplifyUser)
  const {branches} = getBranches(amplifyUser)
  const insertBranch = queryInsertBranch(amplifyUser)
  const updateBranch = queryUpdateBranch(amplifyUser)
  const deleteBranch = queryDeleteBranch(amplifyUser)
  const updateTenant = queryUpdateTenant(amplifyUser)

  return (
    <div
      className="accountPage"
      style={{ padding: props.isMobile ? '48px 0' : undefined }}
    >
      <ReactHelmet title="Account" />
      <LoadingOverlay loading={loadingTenant} />
      { tenant
        && <div className="accountPageContainer">
          <div className="accountPageCards">
            <ContactCard
              tenant={tenant}
              loadingData={loadingTenant}
              onSave={(tenant: Tenant) => {
                tenant = removeTypename(tenant)
                updateTenant({
                  variables: {
                    id: amplifyUser?.['custom:tenantID'],
                    tenant: {
                      ...tenant,
                      tenants_image: undefined,
                      tenants_info: undefined,
                      tenant_setting: undefined,
                    },
                    tenants_info: {...tenant.tenants_info},
                  }
                })
              }}
            />

            <SubscriptionPlanCard />
          </div>
          <AccountBranches
            branches={branches}
            loadingData={loadingTenant}
            isMobile={props.isMobile}
            createBranch={(branch: NewBranch) => {
              insertBranch({
                variables: {
                  branch: {
                    ...branch,
                    tenant_id: amplifyUser?.['custom:tenantID'],
                  },
                }
              })
            }}

            updateBranch={(branch: NewBranch) => {
              branch = removeTypename(branch)
              updateBranch({
                variables: {
                  id: branch.id,
                  branch: {
                    ...branch,
                    branch_rate: undefined,
                    assets_aggregate: undefined,
                    user_branches: undefined,
                    tenant_id: amplifyUser?.['custom:tenantID'],
                  },
                  branch_rate: {
                    ...branch.branch_rate.data,
                  },
                }
              })
            }}

            deleteBranch={(branchId: string) => {
              deleteBranch({
                variables: {
                  id: branchId,
                }
              })
            }}
          />
        </div>
      }
    </div>
  )
}

export default AccountPage
