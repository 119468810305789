import { gql, useQuery, useMutation } from '@apollo/client'
import {useState} from 'react'
import {
  AmplifyUser,
} from '../types/account/user'
import {
  IDevice,
  IDeviceType,
} from '../types/account/devices'

export const GET_DEVICES_BY_TENANT_ID =  gql`
  query GetDevicesByTenantId($tenant_id: uuid!) {
    devices_devices(where: {tenant_id: {_eq: $tenant_id}}) {
      id
      device_esn
      active
      device_info {
        model_map {
          id
          rovitracker_model_name
        }
      }

      asset_device {
        asset {
          branch {
            id
            branch_name
          }

          asset_info {
            asset_id
            asset_name
            asset_make
            asset_model
          }
        }
      }
    }
  }
`

export function getDevices(
  amplifyUser: AmplifyUser|null
): {
  devices: IDevice[]
  loadingDevices: boolean
} {
  const { loading, error, data, refetch } = useQuery(GET_DEVICES_BY_TENANT_ID, {
    variables: {
      tenant_id: amplifyUser?.['custom:tenantID'],
    },
    skip: !amplifyUser,
  })
  return {
    devices: data?.devices_devices || [],
    loadingDevices: loading,
  }
}

export const GET_DEVICE_TYPES =  gql`
  query GetDeviceTypes {
    devices_model_map {
      id
      rovitracker_model_name
      device_type {
        label
        dimensions
        usage
        price
        show_on_market
        description
        short_desc
        is_gateway
        is_tag
      }
    }
  }
`

export function getDeviceTypes(): {
  deviceTypes: IDeviceType[]
  loadingDeviceTypes: boolean
} {
  const { loading, error, data, refetch } = useQuery(GET_DEVICE_TYPES)
  if (!data) {
    return {
      deviceTypes: [],
      loadingDeviceTypes: loading,
    }
  }

  let deviceTypes = data.devices_model_map.map((deviceType: IDeviceType) => {
    const id = deviceType.rovitracker_model_name
    return {
      ...deviceType,
      device_type: {
        ...deviceType.device_type,
        image_url: `https://public.rovitracker.com/app/images/devices/${id}.png`,
        small_image_url: `https://public.rovitracker.com/app/images/devices/${id}-small.png`,
      },
    }
  })
  return {
    deviceTypes,
    loadingDeviceTypes: loading,
  }
}
