import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogRow from '../../dialogComponents/DialogRow'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import Select, { ISelectValues } from '../../ux/Select'
import TextField from '../../textBoxs/TextField'
import Button from '../../buttons/Button'
import InfoHover from '../../buttons/InfoHover'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { label, allRoles, TRole } from '../../../types/account/user'

const styles = (theme: Theme) =>
  createStyles({
    paper: {},
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    formControl: {
      margin: theme.spacing(1),
    },
  })

function SortRoleSelectValues(allRolesTotal: TRole[]) {
  let selectRoles: ISelectValues[] = []
  allRolesTotal.forEach((role) => {
    selectRoles.push({
      value: role,
      label: label[role],
    })
  })
  return selectRoles
}

interface IProps extends WithStyles<typeof styles> {
  editedUserId: string
  dialogOpen: boolean
  onClose: () => void
  role?: string
  fname?: string
  lname?: string
  email?: string
  viewOnly?: boolean
  currentUser?: any
}

interface IState {
  role: string
  fname: string
  lname: string
  email: string
  viewOnly: boolean
}

class EditOtherUserDialog extends React.Component<IProps, IState> {
  state: IState = {
    role: this.props.role ? this.props.role : 'worker',
    fname: this.props.fname ? this.props.fname : '',
    lname: this.props.lname ? this.props.lname : '',
    email: this.props.email ? this.props.email : '',
    viewOnly: this.props.viewOnly ? this.props.viewOnly : false,
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  isSaveButtonDisabled() {
    return !(
      !!this.state.fname &&
      !!this.state.lname &&
      !!this.state.email &&
      !!this.state.role
    )
  }

  componentDidMount() {}

  render() {
    console.log({
      fname: !!this.state.fname,
      lname: !!this.state.lname,
      email: !!this.state.email,
      role: !!this.state.role,
    })
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose} fullWidth>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <DialogRow
            FieldLeft={
              <Select
                label="Role"
                required
                selectedValue={this.state.role}
                values={SortRoleSelectValues(allRoles)}
                onChange={(value) =>
                  this.setState({
                    role: value as string,
                  })
                }
                className={this.props.classes.TextField}
                formHelperText={
                  this.state.role === 'admin'
                    ? 'All rights'
                    : this.state.role === 'branch'
                    ? 'Access and manage their branch'
                    : 'Access their branch'
                }
              />
            }
            FieldRight={
              <InfoHover>
                <div>
                  <div>
                    <b>Administrator</b>
                    <small>
                      , can access and modify everything on the entire account.
                    </small>
                  </div>
                  <div>
                    <b>Branch Manager</b>
                    <small>
                      , can access and modify everything on their branch.
                    </small>
                  </div>
                  <div>
                    <b>Employee</b>
                    <small>
                      , can access their branch, cannot delete anything, are
                      limited to the Feature Access, and if View only is enabled
                      it will prevent the ability to create or edit.
                    </small>
                  </div>
                </div>
              </InfoHover>
            }
          />
          <DialogRow
            FieldLeft={
              <TextField
                label="First Name"
                required
                className={this.props.classes.TextField}
                value={this.state.fname}
                onChange={(event) =>
                  this.setState({ fname: event.target.value })
                }
              />
            }
            FieldRight={
              <TextField
                label="Last Name"
                required
                className={this.props.classes.TextField}
                value={this.state.lname}
                onChange={(event) =>
                  this.setState({ lname: event.target.value })
                }
              />
            }
          />
          <DialogRow
            FieldLeft={
              <TextField
                label="Email"
                required
                className={this.props.classes.TextField}
                value={this.state.email}
                onChange={(event) =>
                  this.setState({ email: event.target.value })
                }
              />
            }
          />
          {this.state.role === 'worker' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 16,
              }}
            >
              <FormControl className={this.props.classes.formControl}>
                <FormLabel>Prevent user from making changes</FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.viewOnly}
                      onChange={(event) =>
                        this.setState({ viewOnly: event.target.checked })
                      }
                      color="primary"
                    />
                  }
                  label="View Only"
                />
              </FormControl>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(EditOtherUserDialog)
