import React from 'react'
import Divider from '@mui/material/Divider'
import EllipsisText from '../../../ux/EllipsisText'
import MessageOverlay from '../../../ux/MessageOverlay'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Icon from '../../../Icon'
import { LoadingIcon } from '../../../LoadingOverlay'
import {
  Geofence,
} from '../../../../types/geofence'

const countGeofences = (geofenceAssetCount: number) => {
  switch (geofenceAssetCount) {
    case 0:
      return 'No assets'
    case 1:
      return `${geofenceAssetCount} asset`
    default:
      return `${geofenceAssetCount} assets`
  }
}

interface IProps {
  geofences: Geofence[]
  onClick: (val: IGeofenceClicked) => void
  onHoverIn: (id: any) => void
  onHoverOut: () => void
  loading: boolean
}
class LiveMaptracListGeofence extends React.Component<IProps> {
  render() {
    const props = this.props
    return (
      <>
        <MessageOverlay
          belowTheSurface
          isVisible={props.geofences.length === 0}
        >
          {props.loading ? <LoadingIcon loading /> : 'No Geofences'}
        </MessageOverlay>
        {props.geofences.map((geofence, key) => (
          <div key={key}>
            <ListItem
              button
              onClick={() =>
                props.onClick({
                  geofenceId: geofence.id,
                  latlng: geofence.coordinates.coordinates,
                  type: geofence.coordinates.type,
                  radius: geofence.coordinates.radius ? geofence.coordinates.radius : 0, // fixes that the radius may or may not exist
                })
              }
              onMouseEnter={() => props.onHoverIn(geofence.id)}
              onMouseLeave={props.onHoverOut}
            >
              <ListItemIcon>
                <Icon variant="button" icon="geofence" />
              </ListItemIcon>
              <ListItemText
                primary={<EllipsisText text={geofence.name} />}
                secondary={countGeofences(geofence.asset_count)}
              />
            </ListItem>
            <Divider variant="inset" style={{ marginRight: 10 }} />
          </div>
        ))}
      </>
    )
  }
}

export default LiveMaptracListGeofence
