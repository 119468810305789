import React, { useState } from 'react'
import '../../styles/ux/UserAvatar.scss'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'
import Link from '@mui/material/Link'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { colors } from '../ux/roviTheme'
import { Theme } from '@mui/material/styles/createTheme'
import { userProfileURL } from '../../constants/urlBuilder'
import { Auth } from 'aws-amplify'
import { ICurrentUser } from '../../types/amplifyTypes/currentUser'
import { getAmplifyUser } from '../../api/user'

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      color: 'white',
      background: theme.palette.primary.light,
      height: 24,
      width: 24,
      fontSize: 12,
    },
    disabled: {
      background: theme.palette.grey[400],
    },
    small: {
      height: 18,
      width: 18,
      fontSize: 10,
    },
  })

const UserAvatar = (props: {
  userId: string
  firstName: string
  lastName: string
  disabled?: boolean
  className?: string
  variant?: 'small' | 'medium'
  classes: WithStyles<typeof styles>
}) => {
  const [amplifyUser, setAmplifyUser] = getAmplifyUser()

  const comp = (
    <Tooltip
      title={`${props.firstName || '?'} ${props.lastName || '?'}`}
      placement={props.variant === 'small' ? 'right' : undefined}
    >
      <Avatar
        className={
          props.variant === 'small' ? 'smallAvatar' : 'mediumAvatar'
        }
        style={{
          backgroundColor: props.disabled
            ? colors.disabled
            : colors.primaryBlue,
          color: '#fff',
        }}
      >
        {`${props.firstName?.[0] || '?'}${props.lastName?.[0] || '?'}`}
      </Avatar>
    </Tooltip>
  )

  return props.disabled ? (
    comp
  ) : (
    <Link
      href={userProfileURL(
        props.userId,
        amplifyUser?.['sub'] || ''
      )}
      onClick={(ev) => ev.stopPropagation()}
    >
      {comp}
    </Link>
  )
}

export default withStyles(styles)(UserAvatar)
