export function removeTypename(data: any|any[]): any {
  if (data?.length !== undefined) {
    data = [...data]
  } else {
    data = {...data}
  }
  if (data.__typename) {
    delete data.__typename
  }
  for (const key of Object.keys(data)) {
    const value = data[key]
    if (typeof value === 'object') {
      data[key] = removeTypename(value)
    }
  }
  return data
}

