import React, {useState} from 'react'
import FloatingButtonDrawer from '../../buttons/FloatingButtonDrawer'
import TabsContainer from '../../ux/TabsContainer'
import { PaddedSearchBar } from '../../textBoxs/SearchBar'
import LiveMaptracListAsset from './maptracLiveAsset/LiveMaptracListAsset'
import LiveMaptracListSite from './maptracLiveSites/LiveMaptracListSite'
// import { backend_api } from '../../../constants/api'
import AssetDetailsMaptrac from './maptracLiveAsset/AssetDetailsMaptrac'
import SiteDetailsMaptrac from './maptracLiveSites/SiteDetailsMaptrac'
import GeofenceDetailsMaptrac from './maptracLiveGeofences/GeofenceDetailsMaptrac'
import { IClientSites } from '../../../types/maptrac/Sites'
import { ILatLng } from '../../../types/maptrac/LatLng'
import {
  IGeofenceClicked,
} from '../../../types/maptrac/Geofences'
import { Geofence } from '../../../types/Geofence'
import LiveMaptracListGeofence from '../maptracLive/maptracLiveGeofences/LiveMaptracListGeofence'
import { IAssetList, IAssetClicked } from '../../../types/maptrac/Assets'
import { AssetTabAsset } from '../../inventory/AssetsTabTypes'

export type TFocusType =
  | 'asset'
  | 'site'
  | 'geofence'
  | 'not_found'
  | 'not_focused'

const LiveMaptracDrawerComponents = (props: {
  searchString: string
  updateSearch: (val: string) => void
  isMobile: boolean
  focused: boolean
  assetClicked: (val: IAssetClicked) => void
  siteClicked: (val: string, gps: ILatLng) => void
  geofenceClicked: (val: IGeofenceClicked) => void
  focusedType: TFocusType
  selectedId: string
  unFocus: () => void

  // Geofences
  geofences: Geofence[]
  geofencesLoading: boolean
  setEditedGeofence: (val: Geofence) => void
  deleteGeofence: (geofence: Geofence) => void

  // Assets
  assets: AssetTabAsset[]
  assetLists: IAssetList[]
  assetsLoading: boolean
  width: number
  height: number
}) => {
  const assetsTotal = props.assets.length

  function assetCountVal(assetAmount: { equipment: string | any[] }[]) {
    let assetCountVal = 0
    assetAmount.forEach((val: { equipment: string | any[] }) => {
      assetCountVal = val.equipment.length + assetCountVal
    })
    return assetCountVal
  }

  function focusedComponents(focusedType: TFocusType) {
    if (focusedType === 'asset') {
      const asset = props.assets.find(asset => asset.id === props.selectedId)
      if (asset) {
        return (
          <AssetDetailsMaptrac
            asset={asset}
            isMobile={props.isMobile}
            onBack={props.unFocus}
            onClickEntity={(id: any) => console.log()}
            timezone=""
            onClickCamera={() => console.log()}
            onClickTrip={(date: any, endDate: any) => console.log()}
            width={0}
            gatewayHistoryObs=""
            tagsHistoryObs=""
            setDate={(data: any) => console.log()}
            setFocus={(entity: any) => console.log()}
          />
        )
      }
    } else if (focusedType === 'site') {
      return (
        <SiteDetailsMaptrac
          equip=""
          isMobile={props.isMobile}
          onBack={props.unFocus}
          assetClicked={(val) => props.assetClicked(val)}
        />
      )
    } else if (focusedType === 'geofence') {
      const geofence = props.geofences.find(geofence => geofence.id === props.selectedId)
      return (
        <GeofenceDetailsMaptrac
          geofence={geofence}
          isMobile={props.isMobile}
          onBack={props.unFocus}
          assetClicked={(val) => props.assetClicked(val)}
          siteClicked={(id: string, gps: ILatLng) =>
            props.siteClicked(id, gps)
          }
          unFocus={props.unFocus}
          setEditedGeofence={props.setEditedGeofence}
          deleteGeofence={props.deleteGeofence}
        />
      )
    }

    if (focusedType) {
      return <div>Not Found</div>
    }

    return <div>not focused</div>
  }

  return (
    <FloatingButtonDrawer isMobile={props.isMobile} isLeft defaultOpen>
      {/* Search Bar Component */}
      {props.focused ? (
        focusedComponents(props.focusedType)
      ) : (
        <>
          <PaddedSearchBar
            value={props.searchString}
            onChange={(newString: string) => {
              props.updateSearch(newString)
            }}
          />
          {/* Tabs that split up the Assets, Sites, and geofences */}

          <TabsContainer
            scroll
            labels={[
              `Assets (${assetsTotal})`,
              `geofences (${props.geofences.length})`,
            ]}
          >
            <LiveMaptracListAsset
              searchString={props.searchString}
              data={props.assetLists}
              onClick={(val: IAssetClicked) => props.assetClicked(val)}
              onHoverIn={() => console.log()}
              onHoverOut={() => console.log()}
              loading={props.assetsLoading}
              isMobile={props.isMobile}
              width={props.width}
              height={props.height}
            />

            <LiveMaptracListGeofence
              geofences={props.geofences}
              onClick={(geo) => props.geofenceClicked(geo)}
              onHoverIn={() => console.log()}
              onHoverOut={() => console.log()}
              loading={props.geofencesLoading}
            />
          </TabsContainer>
        </>
      )}
    </FloatingButtonDrawer>
  )
}

export default LiveMaptracDrawerComponents
