import React from 'react'
import '../../styles/pages/service/TasksPage.scss'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionActions from '@mui/material/AccordionActions'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Icon from '../Icon'
import IconButton from '../buttons/IconButton'
import Typography from '@mui/material/Typography'
import EllipsisText from '../ux/EllipsisText'
import grey from '@mui/material/colors/grey'
import {
  IAssignAsset,
  ETaskMetric,
  ITaskGroup,
} from '../../types/service/tasks'
import TasksAccordianRow from './taskComponents/TaskAccordianRow'
import TaskDialog from './taskComponents/TaskDialog'
import AssignGroupTaskToAssetDialog from './taskComponents/AssignGroupTaskToAssetDialog'
import DeleteTaskDialog from './taskComponents/DeleteTaskDialog'
import { IUser } from '../../types/account/user'

interface IProps {
  group: ITaskGroup
  isMobile?: boolean
  allUsers: IUser[]
  allEquipment: IAssignAsset[]
}

interface IState {
  totalOverdue: number
  totalUpcoming: number
  totalScheduled: number
  totalDiscrepency: number
  taskDialogOpen: boolean
  assignAssetOpen: boolean
  deleteTaskDialogOpen: boolean
}

class GroupTasksAccordian extends React.Component<IProps, IState> {
  state: IState = {
    totalOverdue: 0,
    totalUpcoming: 0,
    totalScheduled: 0,
    totalDiscrepency: 0,
    taskDialogOpen: false,
    assignAssetOpen: false,
    deleteTaskDialogOpen: false,
  }

  render() {
    const group = this.props.group
    return (
      <>
        <Accordion>
          <AccordionSummary expandIcon={<Icon icon="expandMore" />}>
            <Typography component="div">
              <EllipsisText text={group.name} />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                paddingLeft: 20,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {group.tasks.map((task) => (
                <div
                  key={task.taskId}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography component="div">
                    <EllipsisText text={task.taskName} />
                  </Typography>
                  <Typography>{task.value}</Typography>
                  <Typography className="progressCircleContainer" component="div">
                    <span
                      className="progressCircle"
                      style={{ backgroundColor: grey[50] }}
                    />
                    <span
                      className="progressCircle"
                      style={{ backgroundColor: grey[50] }}
                    />
                    <span
                      className="progressCircle"
                      style={{ backgroundColor: grey[50] }}
                    />
                  </Typography>
                </div>
              ))}
            </div>
            <Typography component="div" style={{ padding: '30px 0 0 0' }}>
              <EllipsisText text="Assets Assigned" />
            </Typography>
            <List dense className="list">
              <TasksAccordianRow
                name="Asset"
                interval={ETaskMetric.engineHours}
                equips={[
                  { id: '', name: 'Asset', scheduleRatio: 0.8, due: 100 },
                ]}
                globalThreshold={0.85}
                equip={{ id: '', name: 'Asset', scheduleRatio: 0.8, due: 100 }}
                isMobile={false}
              />
              <TasksAccordianRow
                name="Asset"
                interval={ETaskMetric.engineHours}
                equips={[
                  { id: '', name: 'Asset', scheduleRatio: 0.5, due: 100 },
                ]}
                globalThreshold={0.85}
                equip={{ id: '', name: 'Asset', scheduleRatio: 0.5, due: 100 }}
                isMobile={false}
              />
              <TasksAccordianRow
                name="Asset"
                interval={ETaskMetric.engineHours}
                equips={[
                  { id: '', name: 'Asset', scheduleRatio: 0.9, due: 100 },
                ]}
                globalThreshold={0.85}
                equip={{ id: '', name: 'Asset', scheduleRatio: 0.9, due: 100 }}
                isMobile={false}
              />
            </List>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <IconButton
              icon="add"
              variant="row"
              tooltip="Assign to Asset"
              onClick={() =>
                this.setState({
                  assignAssetOpen: true,
                })
              }
            />
            <IconButton
              icon="edit"
              variant="row"
              tooltip="Edit"
              onClick={() => this.setState({ taskDialogOpen: true })}
            />
            <IconButton
              icon="delete"
              variant="row"
              tooltip="Delete"
              onClick={() => this.setState({ deleteTaskDialogOpen: true })}
            />
          </AccordionActions>
        </Accordion>
        <AssignGroupTaskToAssetDialog
          onClose={() => this.setState({ assignAssetOpen: false })}
          open={this.state.assignAssetOpen}
          isMobile={this.props.isMobile}
          name={group.name}
          equipment={this.props.allEquipment}
          interval={[
            ETaskMetric.engineHours,
            ETaskMetric.odometer,
            ETaskMetric.date,
          ]}
        />
        <TaskDialog
          type="edit"
          open={this.state.taskDialogOpen}
          onClose={() => this.setState({ taskDialogOpen: false })}
          onSubmit={() => this.setState({ taskDialogOpen: false })}
          users={this.props.allUsers}
          useMetric
          isMobile={this.props.isMobile}
          maintenanceType="group"
        />
        <DeleteTaskDialog
          open={this.state.deleteTaskDialogOpen}
          onClose={() => this.setState({ deleteTaskDialogOpen: false })}
          onDelete={() => this.setState({ deleteTaskDialogOpen: false })}
          name={group.name}
        />
      </>
    )
  }
}

export default GroupTasksAccordian
