import { apiGet } from './apiCall'
import {
  IAssetMeta,
  IAssetInfo,
  IAssetMap,
} from '../components/inventory/AssetsTabTypes'
import {
  ICategory,
  ICategoryMap,
} from '../types/category'

export async function getAssetMetasByTenantId(tenantId: string): Promise<IAssetMeta[]> {
  const data = await apiGet(`/asset/byTenant/${tenantId}`)
  let assetMetas: IAssetMeta[] = []
  for (const assetMeta of data.assets) {
    assetMetas.push({
      asset_id: assetMeta.asset_id,
      legacy_id: assetMeta.legacy_id,
      tenant_id: assetMeta.tenant_id,
      active: assetMeta.active,
      created_date: assetMeta.created_date,
    })
  }
  return assetMetas
}

export async function getAssetsByMetas(assetMetas: IAssetMeta[]): Promise<IAssetInfo[]> {
  let requests = []
  for (const asset of assetMetas) {
    requests.push(getAssetById(asset.asset_id))
  }
  let assets: IAssetInfo[] = await Promise.all(requests)
  assets = assets.filter((asset) => asset !== null)
  return assets
}

export async function getAssetById(assetId: string): Promise<IAssetInfo | null> {
  let data
  try {
    data = await apiGet(`/asset/${assetId}`)
  } catch {
    return null
  }

  const assetProfile = data.info.asset_profile
  const asset = data.info.asset
  const deviceId = assetProfile?.associated_device_ids?.shift() || null
  return {
    id: asset.asset_id,
    name: assetProfile.asset_name,
    device: deviceId,
    hasDevice: deviceId ? true : false,
    category_id: assetProfile.category_id,
  }
}

export async function getCategoriesByTenantId(tenantId: string): Promise<ICategory[]> {
  const response = await apiGet(`/asset/category/byTenant/${tenantId}`)
  return response.category
}

export function getCategoryMap(categories: ICategory[]): ICategoryMap {
  let categoryMap: ICategoryMap = {}
  for (const category of categories) {
    if (category.id === undefined) {
      continue
    }
    categoryMap[category.id] = category
  }
  return categoryMap
}

export function getAssetMap(assets: IAssetInfo[]): IAssetMap {
  let assetMap: IAssetMap = {}
  for (const asset of assets) {
    if (asset.id === undefined) {
      continue
    }
    assetMap[asset.id] = asset
  }
  return assetMap
}

export function attachCategoryToAssets(assets: IAssetInfo[], categoryMap: ICategoryMap): IAssetInfo[] {
  const updatedAssets: IAssetInfo[] = assets.map(
    (asset) => {
      asset.category = categoryMap[asset.category_id] || {
        id: 0,
        name: 'None',
        color: 'grey',
        icon: 'barrel',
      }
      return asset
    }
  )
  return updatedAssets
}
