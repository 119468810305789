import React, { useState, ReactElement } from 'react'
import '../styles/components/LoadingOverlay.scss'
import { colors } from '../components/ux/roviTheme'
import { WhisperSpinner } from 'react-spinners-kit'

interface IProps {
  loading: boolean | ReactElement<any, any> | null
  delayedTime?: number // ms - default 0
  size?: number
}

interface IPropsCenter {
  loading: boolean | ReactElement<any, any> | null
  width: number | string
  height: number | string
  size?: number
}

// export const DelayedLoading: React.FunctionComponent<IProps> = (props) => {
//   const [delayed, setDelayed] = useState(true)
//   setTimeout(() => setDelayed(false), 5000)
//   return delayed ? false : props.loading
// }

export const LoadingIcon: React.FunctionComponent<IProps> = (props) =>
  props.loading ? (
    <WhisperSpinner
      frontColor={colors.primaryBlue}
      backColor={colors.primaryOrange}
      size={props.size ? props.size : 25}
    />
  ) : null

export const LoadingIconCenter: React.FunctionComponent<IPropsCenter> = (
  props
) =>
  props.loading ? (
    <div
      style={{
        width: props.width,
        height: props.height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <WhisperSpinner
        frontColor={colors.primaryBlue}
        backColor={colors.primaryOrange}
        size={props.size ? props.size : 25}
      />
    </div>
  ) : null

const LoadingOverlay: React.FunctionComponent<IProps> = (props) => {
  const [delayed, setDelayed] = useState(!!props.delayedTime ? true : false)

  /** Using this useEffect hook to unmount the timer when the component unmounts */
  React.useEffect(() => {
    const timer = setTimeout(
      () => setDelayed(false),
      !!props.delayedTime ? props.delayedTime : 5000
    )
    return () => clearTimeout(timer)
  })

  return delayed ? (
    <div></div>
  ) : props.loading ? (
    <div
      className="loadingOverlayContainer"
      data-testid="loadingSpinner"
    >
      <div className="spinner">
        <WhisperSpinner
          frontColor={colors.primaryBlue}
          backColor={colors.primaryOrange}
          size={props.size ? props.size : 25}
        />
      </div>
    </div>
  ) : null
}

export default LoadingOverlay
