import React from 'react'
import '../../styles/pages/service/TasksPage.scss'
import ReactHelmet from '../../components/ReactHelmet'
import Typography from '@mui/material/Typography'
import TasksOverviewBoxes from '../../components/serviceComponents/TasksOverviewBoxes'
import TasksAccordian from '../../components/serviceComponents/TasksAccordian'
import GroupTaskAccordian from '../../components/serviceComponents/GroupTaskAccordian'
import { LoadingIcon } from '../../components/LoadingOverlay'
import {
  ITask,
  ITaskGroup,
  ILegacyTask,
  IAssetTasks,
  IAssignAsset,
  TTaskMetric,
} from '../../types/service/tasks'
import {
  IAssetMeta,
  IAssetInfo,
  IAssetMap,
} from '../../components/inventory/AssetsTabTypes'
import WidthViewport from '../../components/ux/WidthViewport'
import TaskDialog from '../../components/serviceComponents/taskComponents/TaskDialog'
import {
  IUser,
  ERole,
} from '../../types/account/user'
import Divider from '@mui/material/Divider'

import { Auth } from 'aws-amplify'
import { apiGet } from '../../apiCalls/apiCall'
import {
  getAssetMetasByTenantId,
  getCategoriesByTenantId,
  getCategoryMap,
  getAssetsByMetas,
  attachCategoryToAssets,
  getAssetMap,
} from '../../apiCalls/assetsApi'
import {
  getGroupTasksByTenantId,
  getAllTasksByTenantId,
  attachAssetsToTasks,
} from '../../apiCalls/maintenanceApi'
import { getAllUsersByTenantId } from '../../apiCalls/usersApi'

export const globalThreshold = 0.85

const countOverdue = (equips: IAssetTasks[]) => {
  // filter overdue and return the length
  return equips.filter(
    (equip) => equip.scheduleRatio !== undefined && equip.scheduleRatio >= 1
  ).length
}

const countUpcoming = (equips: IAssetTasks[]) => {
  // filter upcoming and return the length
  return equips.filter(
    (equip) =>
      equip.scheduleRatio !== undefined &&
      equip.scheduleRatio >= globalThreshold &&
      equip.scheduleRatio < 1
  ).length
}

const countScheduled = (equips: IAssetTasks[]) => {
  // filter scheduled and return the length
  return equips.filter(
    (equip) =>
      equip.scheduleRatio !== undefined && equip.scheduleRatio < globalThreshold
  ).length
}

const countDiscrepency = (equips: IAssetTasks[]) => {
  // filter discrepency and return the length
  return equips.filter(
    (equip) => equip.scheduleRatio !== undefined && equip.scheduleRatio < 0
  ).length
}

interface IState {
  tasks: ITask[]
  taskGroups: ITaskGroup[]
  tasksLoading: boolean
  totalTaskTypes: {
    low: number
    med: number
    high: number
  }
  assignAssetEquipment: IAssetInfo[]
  newTaskDialogOpen: boolean
  allUsers: IUser[]
  assetMap: IAssetMap
}

interface IProps {
  addTaskDialogOpen: boolean
  onClose: () => void
}

class TasksPage extends React.Component<IProps, IState> {
  state: IState = {
    taskGroups: [],
    tasks: [],
    tasksLoading: true,
    totalTaskTypes: {
      low: 0,
      med: 0,
      high: 0,
    },
    assignAssetEquipment: [],
    newTaskDialogOpen: false,
    allUsers: [],
    assetMap: {},
  }

  filterThroughTasks(tasks: ITask[]) {
    let low = 0
    let med = 0
    let high = 0
    tasks.forEach((task) => {
      high = high + countOverdue(task.assets)
      med = med + countUpcoming(task.assets)
      low = low + countScheduled(task.assets)
    })
    return { low: low, med: med, high: high }
  }

  async grabTasks() {
    const { attributes } = await Auth.currentAuthenticatedUser()
    const tenantId: string = attributes['custom:tenantID']
    const taskGroups: ITaskGroup[] = await getGroupTasksByTenantId(tenantId)
    const tasks = await getAllTasksByTenantId(tenantId)
    this.setState({
      tasks,
      taskGroups,
      tasksLoading: false,
      totalTaskTypes: this.filterThroughTasks(tasks),
    })
  }

  async grabAllAssets() {
    const { attributes } = await Auth.currentAuthenticatedUser()
    const tenantId: string = attributes['custom:tenantID']
    const assetMetas: IAssetMeta[] = await getAssetMetasByTenantId(tenantId)
    const assets: IAssetInfo[] = await getAssetsByMetas(assetMetas)
    const assetMap: IAssetMap = getAssetMap(assets)
    const tasks = attachAssetsToTasks(this.state.tasks, assetMap)
    this.setState({
      tasks,
      assetMap,
      assignAssetEquipment: assets,
    })
  }

  async grabAllUsers() {
    const { attributes } = await Auth.currentAuthenticatedUser()
    const tenantId: string = attributes['custom:tenantID']
    this.setState({
      allUsers: await getAllUsersByTenantId(tenantId),
    })
  }

  componentDidMount() {
    this.grabTasks()
    this.grabAllAssets()
    this.grabAllUsers()
  }

  render() {
    return (
      <WidthViewport>
        {({ isMobile }: { isMobile: boolean }) => (
          <div className="tasksPageContainer">
            <ReactHelmet title="Tasks" />
            <Typography
              variant="h6"
              style={{ paddingTop: isMobile ? 48 : undefined }}
            >
              Service Tasks
            </Typography>
            <div className="tasksInnerContainer">
              <TasksOverviewBoxes totalTaskTypes={this.state.totalTaskTypes} />
              {this.state.tasksLoading ? <LoadingIcon loading /> : null}
              <div className="tasksAccordianContainer">
                {this.state.tasks.map((task) => (
                  <React.Fragment key={task.taskId}>
                    <TasksAccordian
                      schedule={task.type}
                      interval={task.metric}
                      value={task.value}
                      canBeDeleted={task.assets.length < 1}
                      timezone=""
                      useMetric={false}
                      isMobile={isMobile}
                      equips={task.assets}
                      globalThreshold={globalThreshold}
                      countOverdue={(equips) => countOverdue(equips)}
                      countUpcoming={(equips) => countUpcoming(equips)}
                      countScheduled={(equips) => countScheduled(equips)}
                      countDiscrepency={(equips) => countDiscrepency(equips)}
                      allEquipment={this.state.assignAssetEquipment}
                      allUsers={this.state.allUsers}
                      task={task}
                    />
                  </React.Fragment>
                ))}
              </div>

              <Divider
                variant="middle"
                style={{ width: 'calc(100vw - 80px)', padding: 25 }}
              >
                Grouped Tasks
              </Divider>
              <div
                className="groupedTasksContainer"
                style={{ width: 'calc(100vw - 80px)' }}
              >
                <div className="groupTaskAccordians">
                  {this.state.taskGroups.map((group) => (
                    <GroupTaskAccordian
                      key={group.id}
                      group={group}
                      isMobile={isMobile}
                      allUsers={this.state.allUsers}
                      allEquipment={this.state.assignAssetEquipment}
                    />
                  ))}
                </div>
              </div>
            </div>

            <TaskDialog
              type="add"
              open={this.props.addTaskDialogOpen}
              onClose={this.props.onClose}
              onSubmit={this.props.onClose}
              users={this.state.allUsers}
              useMetric
              isMobile={isMobile}
            />
          </div>
        )}
      </WidthViewport>
    )
  }
}

export default TasksPage
