import React from 'react'
import ListSubheader from '@mui/material/ListSubheader'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Icon from '../Icon'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import EllipsisText from '../ux/EllipsisText'
import { colors } from '../ux/roviTheme'
import { Branch } from '../../types/tenant'

const BranchList = (props: {
  branches: Branch[]
  currentBranch: Branch
  setCurrentBranchId: (branchId: string) => void
}) => {
  return (
    <>
      <ListSubheader>Branches</ListSubheader>
      <MenuList>
        {props.branches.map((branch) => (
          <MenuItem
            key={branch.id}
            id={`BranchName${branch.id}`}
            onClick={() => props.setCurrentBranchId(branch.id)}
          >
            {props.currentBranch.id === branch.id ? (
              <ListItemIcon>
                <Icon icon="check" style={{ color: colors.iconColor }} />
              </ListItemIcon>
            ) : (
              <div style={{ paddingLeft: 56 }} />
            )}
            <ListItemText
              primary={
                <EllipsisText
                  text={branch.branch_name}
                  width={136}
                />
              }
            />
          </MenuItem>
        ))}
      </MenuList>
    </>
  )
}

export default BranchList
