import { IAssetSnapshotRow } from '../AssetsTabTypes'
import { ICategory } from '../../../types/category'
import { IUser } from '../../../types/account/user'
import { IFlag } from '../../../types/flag'
import { IAgreement } from '../../../types/agreements'

export function sortInventoryCategories(thingval: ICategory[]) {
  let listCategories: ICategory[] = []
  thingval.forEach((thing) => {
    if (!listCategories.includes(thing.category)) {
      listCategories.push(thing.category)
    }
  })
  return listCategories
}

interface ISelectorVal {
  value: string
  label: string
}

export function convertCategoriesSelect(
  listCategories: ICategory[],
  defaultVal: ISelectorVal
) {
  let newListCategories: ISelectorVal[] = [defaultVal]
  listCategories.forEach((category) => {
    let newVal = { value: category.id, label: category.name }
    if (!newListCategories.includes(newVal)) {
      newListCategories.push({ value: category.id, label: category.name })
    }
  })
  return newListCategories
}

export function sortInventoryUsers(thingval: IAssetSnapshotRow[]) {
  let listUsers: IUser[] = []
  thingval.forEach((thing) => {
    if (thing.user && !listUsers.includes(thing.user)) {
      listUsers.push(thing.user)
    }
  })
  return listUsers
}

export function convertUsersSelect(
  users: IUser[],
  defaultVal: ISelectorVal
) {
  let newListUsers: ISelectorVal[] = [defaultVal]
  users.forEach((user) => {
    let newVal = {
      value: user.id,
      label: `${user.first_name} ${user.last_name}`,
    }
    if (!newListUsers.includes(newVal)) {
      newListUsers.push(newVal)
    }
  })
  return newListUsers
}

export function sortInventoryFlags(thingval: IAssetSnapshotRow[]) {
  let listFlags: IFlag[] = []
  thingval.forEach((thing) => {
    if (thing.flags) {
      thing.flags.forEach((flag) => {
        if (!listFlags.includes(flag)) {
          listFlags.push(flag)
        }
      })
    }
  })
  return listFlags
}

export function convertFlagsSelect(
  listFlags: IFlag[],
  defaultVal: ISelectorVal
) {
  let newListFlags: ISelectorVal[] = [defaultVal]
  listFlags.forEach((flag) => {
    let newVal = {
      value: flag.flagId,
      label: flag.flagName,
    }
    if (!newListFlags.includes(newVal)) {
      newListFlags.push(newVal)
    }
  })
  return newListFlags
}

export function sortInventoryAgreements(thingval: IAssetSnapshotRow[]) {
  let listClients: IAgreement[] = []
  thingval.forEach((thing) => {
    if (thing.agreement && !listClients.includes(thing.agreement)) {
      listClients.push(thing.agreement)
    }
  })
  return listClients
}

export function convertClientSelect(
  listAgreements: IAgreement[],
  defaultVal: ISelectorVal
) {
  let newListClients: ISelectorVal[] = [defaultVal]
  listAgreements.forEach((agreement) => {
    let newVal = {
      value: agreement.client.id,
      label: agreement.client.name,
    }
    if (!newListClients.includes(newVal)) {
      newListClients.push(newVal)
    }
  })
  return newListClients
}

export function convertSiteSelect(
  listAgreements: IAgreement[],
  defaultVal: ISelectorVal,
  currentClientId: string
) {
  let newListSites: ISelectorVal[] = [defaultVal]
  listAgreements.forEach((agreement) => {
    let newVal = {
      value: agreement.site.id,
      label: agreement.site.name,
    }
    if (
      !newListSites.includes(newVal) &&
      agreement.client.id === currentClientId
    ) {
      newListSites.push(newVal)
    }
  })
  return newListSites
}
