import React from 'react'
import Popover from '@mui/material/Popover'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { colors } from '../ux/roviTheme'
import moment from 'moment'

export type TTaskType = 'red' | 'orange' | 'blue' | 'none'
export const allTasks: TTaskType[] = ['blue', 'orange', 'red']
export const colorLookup: { [key in TTaskType]: string } = {
  red: 'red',
  orange: 'orange',
  blue: colors.primaryBlue,
  none: colors.offGrey,
}
export const getType = (scheduleRatio?: number): TTaskType =>
  scheduleRatio === undefined
    ? 'none'
    : scheduleRatio >= 0.95
    ? 'red'
    : scheduleRatio >= 0.85
    ? 'orange'
    : 'blue'

export const getColor = (scheduleRatio?: number): string =>
  colorLookup[getType(scheduleRatio)]

interface ITaskType {
  taskType: string
  value?: any
}

const getTaskDisplayFormat = (
  interval_type: string,
  interval_value: string,
) => {
  switch (interval_type) {
    case 'miles':
      return interval_value
    case 'months':
      return moment(moment(interval_value as string).format())
        .endOf('day')
        .fromNow()
    case 'hours':
      return moment(moment(interval_value as string).format())
        .endOf('hour')
        .fromNow()
    default:
      return interval_value
  }
}

interface ITaskWidgetProps {
  task: any
}

const TaskWidget = (props: ITaskWidgetProps) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <div
      style={{
        backgroundColor: getColor(props.task.scheduleRatio),
        height: '7px',
        width: '7px',
        borderRadius: '50%',
        marginRight: '3px',
      }}
    />
    <Typography
      variant="body2"
      color="textPrimary"
      component="div"
      style={{
        marginRight: 8,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 100,
      }}
    >
      {props.task.maintenance_one_time.task_name}
    </Typography>
    <Typography
      variant="body2"
      color="textSecondary"
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 100,
      }}
    >
      Due {getTaskDisplayFormat(
        props.task.maintenance_one_time.interval_type,
        props.task.maintenance_one_time.interval_value,
      )}
    </Typography>
  </div>
)

const styles = createStyles({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: 6,
    display: 'flex',
    flexDirection: 'column',
  },
})

interface IProps extends WithStyles<typeof styles> {
  anchorEl?: HTMLElement | null
  onClose: () => void
  tasks: any[]
}

const ServicePopover = (props: IProps) => {
  return (
    <Popover
      className={props.classes.popover}
      classes={{
        paper: props.classes.paper,
      }}
      open={!!props.anchorEl}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={props.onClose}
      disableRestoreFocus
    >
      {props.tasks.map((task) => (
        <TaskWidget
          key={task.maintenance_task_id}
          task={task}
        />
      ))}
    </Popover>
  )
}

export default withStyles(styles)(ServicePopover)
