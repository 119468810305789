import React from 'react'
import '../../styles/pages/ResetPassword.scss'
import TextField from '../../components/textBoxs/TextField'
import Button from '../../components/buttons/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'
import { Theme } from '@mui/material/styles/createTheme'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { darkMode } from '../../components/ux/roviTheme'
import { ERoutes } from '../../components/routes/CheckRoutes'
import { signOut } from '../../components/signOut'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '../../components/buttons/IconButton'
import { Auth } from 'aws-amplify'
import SnackBar from '../../components/ux/SnackBar'
import { RegexPasswordCheck } from '../../utility/regex'

const DarkTheme = (theme: Theme) =>
  createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#0078FF',
      },
      background: {
        paper: darkMode ? theme.palette.grey[700] : theme.palette.grey[100],
      },
    },
  })

interface IProps {
  email: string
  verifyCode: string
  isMobile: boolean
}

interface IState {
  password1Error: string
  password1: string
  password1Visible: boolean
  password2Error: string
  password2: string
  password2Visible: boolean
  loading: boolean
  codeEntered: boolean
  verifyCode: string
  SnackBarSuccess?: string
}

class ResetPasswordPage extends React.Component<IProps, IState> {
  state: IState = {
    password1Error: '',
    password1: '',
    password1Visible: false,
    password2Error: '',
    password2: '',
    password2Visible: false,
    loading: true,
    codeEntered: false,
    verifyCode: '',
    SnackBarSuccess: undefined,
  }

  canSubmit() {
    const { password1, password2, password1Error, password2Error } = this.state
    return !!(password1 && password2 && !password1Error && !password2Error)
  }

  submitVerificationCode() {
    this.setState({
      codeEntered: this.state.verifyCode.length > 5 ? true : false,
    })
  }

  changePassword1(val: string) {
    this.setState({
      password1: val,
      password1Error:
        val.length > 7
          ? RegexPasswordCheck.test(val)
            ? ''
            : 'Password requires at least 1 special character'
          : 'Password is too short',
    })
  }

  changePassword2(val: string) {
    this.setState({
      password2: val,
      password2Error:
        this.state.password1 === val ? '' : 'Passwords do not match',
    })
  }

  async submitPasswordChange() {
    if (!this.canSubmit()) return

    signOut()
    try {
      await Auth.forgotPasswordSubmit(
        this.props.email,
        this.props.verifyCode,
        this.state.password1
      )
      window.location.replace(ERoutes.login)
    } catch (err) {
      console.log(err)
    }
    this.setState({ SnackBarSuccess: 'Your password has been reset' })
  }

  componentDidMount() {
    this.setState({ loading: false })
  }

  render() {
    return (
      <div className="resetPassContainer">
        <SnackBar
          variant="success"
          message={this.state.SnackBarSuccess}
          onClose={() => this.setState({ SnackBarSuccess: undefined })}
        />
        <ThemeProvider theme={DarkTheme}>
          <Paper className="resetPassInner" square>
            <div className="resetPassTitle">
              <Typography variant="h5" align="center">
                Reset Password
              </Typography>
              <Typography
                variant="body2"
                align="center"
                className="resetPassInstructions"
              >
                Please enter a new password and confirm it.
              </Typography>
            </div>
            <div className="resetPassTextTop">
              <TextField
                id="password1"
                name="password"
                label="New password"
                error={!!this.state.password1Error}
                helperText={this.state.password1Error}
                formClassName="resetPassNoSideMargin"
                type={this.state.password1Visible ? 'text' : 'password'}
                onChange={(event) => this.changePassword1(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      variant="appbar"
                      icon={
                        this.state.password1Visible
                          ? 'visibility'
                          : 'visibilityOff'
                      }
                      onClick={() =>
                        this.setState({
                          password1Visible: !this.state.password1Visible,
                        })
                      }
                    />
                  </InputAdornment>
                }
              />
            </div>
            <TextField
              id="password2"
              name="password"
              label="Confirm password"
              error={!!this.state.password2Error}
              helperText={this.state.password2Error}
              formClassName="resetPassNoSideMargin"
              type={this.state.password2Visible ? 'text' : 'password'}
              onEnter={() => this.submitPasswordChange()}
              onChange={(event) => this.changePassword2(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    variant="appbar"
                    icon={
                      this.state.password2Visible
                        ? 'visibility'
                        : 'visibilityOff'
                    }
                    onClick={() =>
                      this.setState({
                        password2Visible: !this.state.password2Visible,
                      })
                    }
                  />
                </InputAdornment>
              }
            />
            <div className="submitEmailContainer">
              <Link
                href={ERoutes.login}
                className="returnToLogin"
                onClick={() => signOut()}
              >
                Login?
              </Link>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.submitPasswordChange()}
                disabled={!this.canSubmit()}
              >
                Submit
              </Button>
            </div>
          </Paper>
        </ThemeProvider>
      </div>
    )
  }
}

export default ResetPasswordPage
