import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import React, {useState, useEffect} from 'react'
import {
  Flag,
  NewFlag,
  getNewEmptyFlag,
  IRule,
  ICondition,
  ITimeRule,
} from 'src/constants/flagRules'
import MainView from './MainView'
import { IEquipEntity, IGeofenceEntity } from '../../../types/maptrac/playback'
import AssetView from './AssetView'
import ConditionView from './ConditionView'
import TimeView from './TimeView'
import {
  AmplifyUser,
} from '../../../types/account/user'

import {getCategoriesWithAssets} from '../../../api/category'

export type FlagDialogViewType = 'main' | 'assets' | 'condition' | 'time'

interface AssignedAsset {
  asset_id: string
  rule_id: string
  flag_id: string
  tenant_id: string
}

interface AssignedCategory {
  category_id: number
  rule_id: string
  flag_id: string
  tenant_id: string
}

const FlagDialog = (props: {
  amplifyUser: AmplifyUser,
  onSave: (flag: NewFlag) => void
  onClose: () => void
  open: boolean
  useMetricMeasurement?: boolean
  geofences: Record<string, IGeofenceEntity>
  equipment: Record<string, IEquipEntity>
  flag?: Flag
}) => {
  const [currentView, setCurrentView] = useState('main' as FlagDialogViewType)
  const [currentRule, setCurrentRule] = useState(null as IRule|null)
  const [editedFlag, setEditedFlag] = useState(getNewEmptyFlag() as NewFlag)
  const {categories} = getCategoriesWithAssets(props.amplifyUser)
  const tenantId = props.amplifyUser['custom:tenantID']

  let assetCount = 0
  const categoryTree = categories.map((category) => {
    assetCount++
    return {
      label: category.name,
      value: `category:${category.id}`,
      children: category.asset_infos.map((asset) => {
        assetCount++
        return {
          label: asset.asset_name,
          value: `asset:${asset.asset_id}`,
        }
      })
    }
  })
  const assetMapToCategories = categories
    .reduce<Record<string, number>>(
      (map, category) => {
        const assetMap = category.asset_infos
          .reduce<Record<string, number>>(
            (subMap, asset) => {
              return {
                ...subMap,
                [asset.asset_id]: category.id,
              }
            }, {}
          )
        return {
          ...map,
          ...assetMap,
        }
      }, {}
    )

  let currentRuleSelectedAssets: string[]
  if (currentRule?.apply_to_all_assets === false) {
    let categoryIds: number[] = []
    const selectedCategories = editedFlag.flag_category_assignments.data
      .reduce<string[]>(
        (categories, category) => {
          if (category.rule_id == currentRule.id) {
            categoryIds.push(category.category_id)
            return [
              ...categories,
              `category:${category.category_id}`,
            ]
          }
          return categories
        }, []
      )

    const selectedAssets = editedFlag.flag_asset_assignments.data
      .reduce<string[]>(
        (assets, asset) => {
          if (
            asset.rule_id == currentRule.id
            || categoryIds.includes(assetMapToCategories[asset.asset_id])
          ) {
            return [
              ...assets,
              `asset:${asset.asset_id}`
            ]
          }

          return []
        }, []
      )

    const assetsInSelectedCategories = Object.keys(assetMapToCategories)
      .reduce<string[]>(
        (assetIds, assetId) => {
          if (!categoryIds.includes(assetMapToCategories[assetId])) {
            return assetIds
          }
          return [
            ...assetIds,
            `asset:${assetId}`,
          ]
        }, []
      )

    currentRuleSelectedAssets = [
      ...selectedCategories,
      ...selectedAssets,
      ...assetsInSelectedCategories,
    ]
  } else {
    const allCategoryIds = categoryTree.map(
      (category) => category.value,
    )
    const allAssetIds = Object.keys(assetMapToCategories).map(
      (assetId) => `asset:${assetId}`
    )
    currentRuleSelectedAssets = [
      ...allCategoryIds,
      ...allAssetIds,
    ]
  }

  useEffect(() => {
    if (props?.flag) {
      const flag = props.flag
      setEditedFlag({
        id: flag.id,
        name: flag.name,
        type: flag.type,
        flag_rules: {
          data: [
            ...flag.flag_rules,
          ]
        },
        flag_category_assignments: {
          data: flag.flag_category_assignments,
        },
        flag_asset_assignments: {
          data: flag.flag_asset_assignments,
        },
      })
    }
  }, [])

  function onMainView() {
    return currentView === 'main'
  }

  function currentViewTitle() {
    switch (currentView) {
      case 'main':
        return 'Add Flag'
      case 'assets':
        return 'Applies To'
      case 'condition':
        return 'When'
      case 'time':
        return 'During'
      default:
        return ''
    }
  }

  function onSwitchView(newView: FlagDialogViewType, rule: IRule|null) {
    setCurrentView(newView)
    setCurrentRule(rule)
  }

  function onUpdateAssets(assets: string[], rule: IRule) {
    let assignedCategories: AssignedCategory[]
    let assignedAssets: AssignedAsset[]
    let applyToAllAssets = false
    if (assets.length === assetCount) {
      applyToAllAssets = true
      assignedCategories = []
      assignedAssets = []
    } else {
      let categoryIds: number[] = []
      assignedCategories = assets
        .reduce<AssignedCategory[]>(
          (filteredCategories, asset) => {
            const [type, id] = asset.split(':')
            if (type !== 'category') {
              return filteredCategories
            }

            categoryIds.push(parseInt(id))
            return [
              ...filteredCategories,
              {
                flag_id: editedFlag.id,
                rule_id: rule.id,
                category_id: parseInt(id),
                tenant_id: tenantId,
              },
            ]
          }, []
        )

      assignedAssets = assets.reduce<AssignedAsset[]>(
          (filteredAssets, asset) => {
            const [type, id] = asset.split(':')
            if (categoryIds.includes(assetMapToCategories[id])) {
              return filteredAssets
            }

            if (type !== 'asset') {
              return filteredAssets
            }

            return [
              ...filteredAssets,
              {
                flag_id: editedFlag.id,
                rule_id: rule.id,
                asset_id: id,
                tenant_id: tenantId,
              },
            ]
          }, []
        )
    }

    const allAssignedCategories = editedFlag.flag_category_assignments.data
      .filter((category) => category.rule_id !== rule.id)
    const allAssignedAssets = editedFlag.flag_asset_assignments.data
      .filter((asset) => asset.rule_id !== rule.id)
    const allFlagRules = editedFlag.flag_rules.data
      .filter((tempRule) => tempRule.id !== rule.id)

    const updatedRule = {
      ...rule,
      apply_to_all_assets: applyToAllAssets,
    }
    setCurrentRule({...updatedRule})
    setEditedFlag({
      ...editedFlag,
      flag_rules: {
        data: [
          ...allFlagRules,
          {...updatedRule},
        ],
      },
      flag_category_assignments: {
        data: [
          ...allAssignedCategories,
          ...assignedCategories,
        ],
      },
      flag_asset_assignments: {
        data: [
          ...allAssignedAssets,
          ...assignedAssets,
        ],
      },
    })
  }

  function onUpdateRuleCondition(
    rule: IRule,
    condition: ICondition,
  ) {
    updateFlagRules({
      ...rule,
      condition,
    })
  }

  function onUpdateRuleTime(
    rule: IRule,
    time: ITimeRule[],
  ) {
    updateFlagRules({
      ...rule,
      time,
    })
  }

  function updateFlagRules(updatedRule: IRule) {
    const allFlagRules = editedFlag.flag_rules.data
      .filter((tempRule) => tempRule.id !== updatedRule.id)
    setCurrentRule(updatedRule)
    setEditedFlag({
      ...editedFlag,
      flag_rules: {
        data: [
          ...allFlagRules,
          {...updatedRule},
        ],
      }
    })
  }

  function onUpdateFlag(flag: Partial<NewFlag>) {
    setEditedFlag({
      ...editedFlag,
      ...flag,
    })
  }

  function removeRule(rule: IRule) {
    const newRules = editedFlag.flag_rules.data
      .filter((tempRule) => tempRule.id !== rule.id)
    const newAssets = editedFlag.flag_asset_assignments.data
      .filter((asset) => asset.rule_id !== rule.id)
    const newCategories = editedFlag.flag_category_assignments.data
      .filter((category) => category.rule_id !== rule.id)
    setEditedFlag({
      ...editedFlag,
      flag_rules: {
        data: newRules,
      },
      flag_asset_assignments: {
        data: newAssets,
      },
      flag_category_assignments: {
        data: newCategories,
      },
    })
  }

  function onCancelClicked() {
    if (onMainView()) {
      props.onClose()
    } else {
      onSwitchView('main', null)
    }
  }

  function renderCurrentView() {
    switch (currentView) {
      case 'main':
        return (
          <MainView
            flag={editedFlag}
            onChangeView={onSwitchView}
            onChangeRule={onUpdateFlag}
            onRemoveRule={removeRule}
            categories={categories}
            geofences={props.geofences}
            equipment={props.equipment}
            useMetricMeasurement={props.useMetricMeasurement}
          />
        )
      case 'assets':
        return (
          <AssetView
            categoryTree={categoryTree}
            values={currentRuleSelectedAssets}
            onChangeAssets={(assets) => onUpdateAssets(assets, currentRule)}
          />
        )
      case 'condition':
        return (
          <ConditionView
            condition={currentRule.condition}
            geofences={props.geofences}
            useMetric={props.useMetricMeasurement}
            branchGeofenceIds={[]}
            onUpdateCondition={(condition) =>
              onUpdateRuleCondition(
                currentRule,
                {
                  ...currentRule.condition,
                  ...condition,
                },
              )
            }
          />
        )
      case 'time':
        return (
          <TimeView
            values={currentRule.time}
            onChange={(time) => onUpdateRuleTime(currentRule, time)}
          />
        )
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth>
      <DialogTitle>{currentViewTitle}</DialogTitle>
      <DialogContent>{renderCurrentView()}</DialogContent>
      <DialogActions>
        <Button onClick={onCancelClicked}>
          {onMainView() ? 'Cancel' : 'Back'}
        </Button>
        {currentView === 'main' && (
          <Button onClick={() => props.onSave(editedFlag)}>
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
export default FlagDialog
