import React, {useState, useEffect} from 'react'
import '../../styles/components/accountComponents/AccountBranch.scss'
import IconButton from '../buttons/IconButton'
import Icon from '../Icon'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import { getMapUrl } from '../maptrac/maptracHelpers'
import UserAvatar from '../ux/UserAvatar'
import AddEditBranchDialog from './dialogs/AddEditBranchDialog'
import DeleteBranchDialog from './dialogs/DeleteBranchDialog'
import AddUserDialog from './dialogs/AddUserDialog'
import { branchPageURL } from '../../constants/urlBuilder'
import {
  Branch,
  NewBranch,
} from '../../types/tenant'
import { ERole, IUser } from '../../types/account/user'
import { userProfileURL } from '../../constants/urlBuilder'

const SpacedDiv: React.FC<{ style?: React.CSSProperties }> = (props) => (
  <div style={{ margin: 5, ...props.style }}>{props.children}</div>
)

const mapAvailable = true
const addressPresent = true

const AccountBranchCard = (props: {
  branch: Branch
  onUpdate: (editableBranch: NewBranch) => void
  onDelete: (branchId: string) => void
  isMobile: boolean
}) => {
  const [isEditBranchDialogOpen, setIsEditBranchDialogOpen] = useState(false)
  const [isDeleteBranchDialogOpen, setIsDeleteBranchDialogOpen] = useState(false)
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false)
  const [branchManagers, setBranchManagers] = useState([] as IUser[])
  const users = props.branch.user_branches.map((user) => user.user)

  function sortBranchManagers(users?: IUser[]) {
    let managers: IUser[] = []
    if (!!users) {
      users.forEach((user) => {
        if (user.role !== ERole.worker) {
          managers.push(user)
        }
      })
    }
    return managers
  }

  useEffect(() => {
    setBranchManagers(
      sortBranchManagers(users)
    )
  }, [])

  /** Combines all the situations to why this branch cannot be deleted */
  const canDeleteBranch =
    props.branch.assets_aggregate.aggregate.count > 0
    || (
      users
      && users.length === 0
    )

  return (
    <>
      <Divider />
      <div className="branchcontainer">
        {props.isMobile ? null : (
          <div className="branchMapOuterBox">
            {mapAvailable ? (
              <img
                alt="Branch GPS Location Google Maps"
                width={180}
                height={170}
                src={getMapUrl({
                  lat: props.branch.latitude,
                  lng: props.branch.longitude,
                })}
              />
            ) : (
              <div className="mapNotAvailable" />
            )}
          </div>
        )}
        <div className="branchInfoContainer">
          <CardHeader
            action={
              <>
                <IconButton
                  variant="toolbar"
                  icon="delete"
                  tooltip={
                    canDeleteBranch
                      ? "Can't delete branch with users or equipment"
                      : 'Delete'
                  }
                  onClick={() => setIsDeleteBranchDialogOpen(true)}
                  disabled={canDeleteBranch}
                />
                <IconButton
                  variant="toolbar"
                  icon="addUser"
                  tooltip="Add User"
                  onClick={() => setIsAddUserDialogOpen(true)}
                />
                <IconButton
                  variant="toolbar"
                  icon="edit"
                  tooltip="Edit"
                  onClick={() => setIsEditBranchDialogOpen(true)}
                />
              </>
            }
            title={
              <Link href={branchPageURL(props.branch.id)}>
                {props.branch.branch_name}
              </Link>
            }
          />
          <CardContent>
            <div className="branchInfoBody">
              <div className="singleInfoContainer">
                {addressPresent ? (
                  <div>
                    <SpacedDiv>{props.branch.address}</SpacedDiv>
                    <SpacedDiv>
                      {props.branch.city},{' '}
                      {props.branch.state}{' '}
                      {props.branch.postal_code}
                    </SpacedDiv>
                  </div>
                ) : (
                  <div style={{ height: 40 }} />
                )}
                <div
                  className="branchTimeZoneContainer"
                  style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                >
                  <Icon
                    icon="timezone"
                    fontSize="inherit"
                    style={{
                      height: 12,
                      width: 12,
                      marginRight: 3,
                      marginBottom: 2,
                    }}
                  />
                  <SpacedDiv style={{ fontSize: 11 }}>
                    {props.branch.timezone}
                  </SpacedDiv>
                </div>
              </div>
              <div className="singleInfoContainer">
                <div>
                  <SpacedDiv>Thing count:</SpacedDiv>
                  <SpacedDiv>
                    <Typography variant="h6" color="primary">
                      {props.branch.assets_aggregate.aggregate.count}
                    </Typography>
                  </SpacedDiv>
                </div>
              </div>
              <div className="singleInfoContainer">
                <div>
                  <SpacedDiv>Labor hourly rate:</SpacedDiv>
                  <SpacedDiv>
                    <Typography variant="h6" color="primary">
                      ${props.branch.branch_rate.hourly_labor_rate}
                    </Typography>
                  </SpacedDiv>
                </div>
              </div>
              <div style={{ flex: 1.5, minWidth: 120, marginRight: 5 }}>
                {branchManagers
                  ? branchManagers.length > 0 && (
                      <div style={{ marginBottom: 20 }}>
                        <SpacedDiv>
                          {branchManagers.length > 1
                            ? 'Managers'
                            : 'Manager'}
                          :
                        </SpacedDiv>

                        {branchManagers.map((manager) => (
                          <div key={manager.id}>
                            <Typography
                              variant="body2"
                              style={{ paddingLeft: 15 }}
                            >
                              <Link href={userProfileURL(manager.id)}>
                                {manager.first_name} {manager.last_name}
                              </Link>
                            </Typography>
                          </div>
                        ))}
                      </div>
                    )
                  : null}
                <div>
                  {
                    (users?.length || 0) > 0
                      ? (<SpacedDiv>Users:</SpacedDiv>)
                      : null
                  }
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {users?.map((user) =>
                      user.role === ERole.worker ? (
                        <div key={user.id} style={{ margin: 2 }}>
                          <UserAvatar
                            userId={user.id}
                            firstName={user.first_name}
                            lastName={user.last_name}
                          />
                        </div>
                      ) : undefined
                    )}
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </div>
      </div>

      {isEditBranchDialogOpen ? (
        <AddEditBranchDialog
          dialogOpen={isEditBranchDialogOpen}
          onClose={() => setIsEditBranchDialogOpen(false)}
          onSubmit={(editableBranch: NewBranch) => {
            setIsEditBranchDialogOpen(false)
            props.onUpdate(editableBranch)
          }}
          branch={props.branch}
        />
      ) : null}

      {isDeleteBranchDialogOpen ? (
        <DeleteBranchDialog
          dialogOpen={isDeleteBranchDialogOpen}
          onClose={() => setIsDeleteBranchDialogOpen(false)}
          onSubmit={props.onDelete}
          branchName={props.branch.branch_name}
          branchId={props.branch.id}
        />
      ) : null}

      <AddUserDialog
        dialogOpen={isAddUserDialogOpen}
        onClose={() => setIsAddUserDialogOpen(false)}
      />
    </>
  )
}

export default AccountBranchCard
