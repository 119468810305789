import React, {useState} from 'react'
import ReactHelmet from '../components/ReactHelmet'
import Toolbar from '../components/toolbar/Toolbar'
import { ERoutes, PageTitle } from '../components/routes/CheckRoutes'
import Typography from '@mui/material/Typography'
import FabButton from '../components/buttons/FabButton'
import FlagCard, {
  PhantomFlagCard
} from '../components/flagComponents/FlagCard'
import FlagDialog from 'src/components/flagComponents/flagDialog/FlagDialog'
import { Flag, NewFlag } from '../constants/flagRules'
import {
  getAmplifyUser,
} from '../api/user'
import {
  getFlags,
  queryInsertFlag,
  queryUpdateFlag,
  queryArchiveFlag,
} from '../api/flag'

import { removeTypename } from '../utility/graphQl'

const FlagsPage = () => {
  const insertFlag = queryInsertFlag()
  const updateFlag = queryUpdateFlag()
  const archiveFlag = queryArchiveFlag()
  const [amplifyUser, setAmplifyUser] = getAmplifyUser()
  const {flags} = getFlags(amplifyUser)
  const [editingFlagIndex, setEditingFlagIndex] = useState(undefined as number|undefined)
  const [isCreatingNewFlag, setIsCreatingNewFlag] = useState(false)

  function onClickOpenDialog(flagToEditIndex: number) {
    setIsCreatingNewFlag(flagToEditIndex === -1)
    setEditingFlagIndex(flagToEditIndex)
  }

  function onCloseDialog(wasSaved: boolean, updatedFlag?: NewFlag) {
    setEditingFlagIndex(undefined)
    if (!wasSaved || !updatedFlag) {
      return
    }

    if (updatedFlag?.id === undefined) {
      // Generate the flag ID in advance so we can insert the flag rules even
      // without waiting to get a flag ID. View insertFlag() for details.
      updatedFlag.id = crypto.randomUUID()
    }

    const flagRules = updatedFlag.flag_rules.data.map((rule) => {
      return {
        ...rule,
        condition: JSON.stringify(rule.condition),
        time: JSON.stringify(rule.time),
        flag_id: updatedFlag?.id,
        tenant_id: amplifyUser?.['custom:tenantID'],
        __typename: undefined,
      }
    })

    if (isCreatingNewFlag) {
      const flagCategoryAssignments = updatedFlag.flag_category_assignments.data
        .map((category) => {
          return {
            ...category,
            flag_id: updatedFlag?.id,
            tenant_id: amplifyUser?.['custom:tenantID'],
          }
        })
      const flagAssetAssignments = updatedFlag.flag_asset_assignments.data
        .map((asset) => {
          return {
            ...asset,
            flag_id: updatedFlag?.id,
            tenant_id: amplifyUser?.['custom:tenantID'],
          }
        })
      insertFlag({
        variables: {
          // Because the flag rules need to be inserted before the category and
          // asset assginments, we've broken out the relationships out
          // alerts_flag_rules_insert_one variable into all the separately
          // needed relationships. The reason for this is we can't force the
          // flag_rules relationship to be inserted before the other
          // relationships.
          flag: {
            ...updatedFlag,
            tenant_id: amplifyUser?.['custom:tenantID'],
            flag_rules: undefined,
            flag_category_assignments: undefined,
            flag_asset_assignments: undefined,
          },
          flagRules,
          flagCategoryAssignments,
          flagAssetAssignments,
        }
      })

    } else {
      updatedFlag = removeTypename(updatedFlag)
      console.log('updatedFlag', updatedFlag)
      const categoryIds = updatedFlag?.flag_category_assignments.data.map(
        (category) => category.category_id
      )
      const assetIds = updatedFlag?.flag_asset_assignments.data.map(
        (asset) => asset.asset_id
      )
      const ruleIds = updatedFlag?.flag_rules.data.map(
        (rule) => rule.id
      )
      updateFlag({
        variables: {
          ...updatedFlag,
          ruleIds,
          rules: flagRules,
          assetIds,
          assets: updatedFlag?.flag_asset_assignments.data || [],
          categoryIds,
          categories: updatedFlag?.flag_category_assignments.data || [],
        },
      })
    }
  }

  function onDeleteFlag(flag: Flag) {
    archiveFlag({variables: {id: flag.id}})
  }

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <ReactHelmet title={PageTitle(ERoutes.flag)} />
      <Toolbar title={PageTitle(ERoutes.flag)} noXtraBar />
      <div style={{ marginTop: 50 }}>
        <Typography variant='h6' style={{ paddingTop: 10, paddingLeft: 20 }}>
          Standard
        </Typography>
        <div
          style={{ display: 'flex', flexWrap: 'wrap', padding: '10px 20px' }}
        >
          <FlagCard
            flag={{
              id: 'outside-asset-boundary',
              type: 'default',
              name: 'Outside Asset Boundary',
              flag_rules: [],
              flag_category_assignments: [],
              flag_asset_assignments: [],
            }}
            useMetric
            assets={[{}]}
            canManage
            isFocused
          />
          <FlagCard
            flag={{
              id: 'places-alert',
              type: 'default',
              name: 'Places Alert',
              flag_rules: [],
              flag_category_assignments: [],
              flag_asset_assignments: [],
            }}
            useMetric
            assets={[{}]}
            canManage
            isFocused
          />
          <PhantomFlagCard />
          <PhantomFlagCard />
          <PhantomFlagCard />
          <PhantomFlagCard />
          <PhantomFlagCard />
        </div>

        <Typography variant='h6' style={{ paddingTop: 10, paddingLeft: 20 }}>
          Custom
        </Typography>
        <div
          style={{ display: 'flex', flexWrap: 'wrap', padding: '10px 20px' }}
        >
          {flags.map((flag, flagIndex) => (
            <FlagCard
              key={flag.id}
              flag={flag}
              onDeleteFlag={() => onDeleteFlag(flag)}
              onEditFlag={() => onClickOpenDialog(flagIndex)}
              useMetric
              assets={[{}]}
              canManage
              isFocused
            />
          ))}
          <PhantomFlagCard />
          <PhantomFlagCard />
          <PhantomFlagCard />
          <PhantomFlagCard />
          <PhantomFlagCard />
        </div>
      </div>
      <FabButton icon='add' onClick={() => onClickOpenDialog(-1)} />

      {
        editingFlagIndex !== undefined
        && <FlagDialog
          amplifyUser={amplifyUser}
          open={editingFlagIndex !== undefined}
          onClose={() => onCloseDialog(false)}
          onSave={(flag) => onCloseDialog(true, flag)}
          equipment={{}}
          geofences={{}}
          categories={{}}
          flag={!isCreatingNewFlag ? flags[editingFlagIndex!] : undefined}
          useMetricMeasurement={false}
        />
      }
    </div>
  )
}

export default FlagsPage
