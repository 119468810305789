import React, { Suspense, useState, useEffect } from 'react'
import Toolbar from '../components/toolbar/Toolbar'
import { getHash } from '../constants/hashGrabber'
import LoadingOverlay from '../components/LoadingOverlay'
import NoAccessPage from './NoAccessPage'
import SideAppBar from '../components/sideAppBar/SideAppBar'
import HashStateManager from '../rx-js/HashStateManager'
import {
  EquipmentHashFromString,
  EquipmentHashToString,
  IEquipmentHash,
  TEquipmentTabs,
  EEquipmentTabs,
} from '../constants/equipmentHashControllers'
import WidthViewport from '../components/ux/WidthViewport'
import EquipmentProfile from './equipment/EquipmentProfile'
import EquipmentMonitor from './equipment/EquipmentMonitor'
import EquipmentMaintenance from './equipment/EquipmentMaintenance'
import EquipmentReports from './equipment/EquipmentReports'
import { getAsset } from '../api/asset'

/**
 * Main Array of Tabs
 */
const equipmentTabs = [
  {
    value: EEquipmentTabs.profile,
    label: 'Profile',
    icon: 'profile',
  },
  {
    value: EEquipmentTabs.maintenance,
    label: 'Service', // Maintenance
    icon: 'maintenance',
  },
  {
    value: 'monitor',
    label: 'Timeline', // Monitor alain wanted this changed, so i left this note so we remember what it originally was used for
    icon: 'timeline', // monitor
  },
  {
    value: EEquipmentTabs.reports,
    label: 'Reports',
    icon: 'reports',
  },
]

const Equipment = (props: {
  id: string
}) => {
  const [selectedTab, setSelectedTab] = useState(EEquipmentTabs.profile)
  const {asset} = getAsset(props.id)

  let hash: HashStateManager<IEquipmentHash> = new HashStateManager(
    EquipmentHashFromString,
    EquipmentHashToString
  )

  function setTab(tab: TEquipmentTabs, clearReports?: boolean) {
    setSelectedTab(tab)
    hash.set({
      ...hash.value,
      tab,
    })
    if (clearReports) {
      hash.set({
        ...hash.value,
        report: undefined, // clears the current selected report
        dateStart: undefined, // clears the dates selected
        dateEnd: undefined, // clears the dates selected
      })
    }
  }

  /**
   * monitors all url tab selection and produces the correct on
   * @param tab pass the value that changing the tabs
   */
  function equipLocationTab(tab: EEquipmentTabs | string) {
    switch (tab) {
      case EEquipmentTabs.profile:
        return EEquipmentTabs.profile
      case EEquipmentTabs.maintenance:
        return EEquipmentTabs.maintenance
      case EEquipmentTabs.events:
      case EEquipmentTabs.timeline:
        return 'monitor' // this is set to monitor as it includes both timeline and events
      case EEquipmentTabs.reports:
        return EEquipmentTabs.reports
      default:
        return 'error'
    }
  }

  /**
   * Maintains the current tabs, and preventing out of sync tabs when one is clicked, best used for the Monitor and Reports tab
   * @param tab pass the value that changing the tabs
   */
  function equipLocationSet(tab: string | EEquipmentTabs) {
    switch (tab) {
      case 'monitor':
        return EEquipmentTabs.timeline
      case 'reports':
        return EEquipmentTabs.reports
      default:
        return tab as EEquipmentTabs
    }
  }

  /**
   * Loads the specific component based off the selected tab value
   * @param selectedTab pass the loaded tab
   */
  function lazyLoadEquipment(
    selectedTab: string,
    isMobile: boolean,
    height: number,
    width: number
  ) {
    switch (selectedTab) {
      case EEquipmentTabs.profile:
        return (
          <EquipmentProfile
            equipmentProfile={asset}
            isMobile={isMobile}
            /**
             * @todo Connect the useMetric to the branch use metric setting
             */
            useMetric
          />
        )
      case EEquipmentTabs.maintenance:
        return <EquipmentMaintenance />
      case EEquipmentTabs.timeline:
        return (
          <EquipmentMonitor
            selected={EEquipmentTabs.timeline}
            onChangeHash={(val: string) =>
              setTab(val as TEquipmentTabs, true)
            }
          />
        )
      case EEquipmentTabs.events:
        return (
          <EquipmentMonitor
            selected={EEquipmentTabs.events}
            onChangeHash={(val: string) =>
              setTab(val as TEquipmentTabs, true)
            }
          />
        )
      case EEquipmentTabs.reports:
        return (
          <EquipmentReports
            isMobile={isMobile}
            height={height}
            width={width}
            hash={hash}
          />
        )
      default:
        return <NoAccessPage Error="404 Not Found" />
    }
  }

  useEffect(() => {
    const hashgrabbed = getHash()
      ? (getHash() as { tab?: TEquipmentTabs })
      : { tab: EEquipmentTabs.profile as TEquipmentTabs }
    setTab(hashgrabbed.tab ? hashgrabbed.tab : EEquipmentTabs.profile)
  })

  const assetName = asset?.asset_info?.asset_name || ''
  return (
    <WidthViewport>
      {({ isMobile, height, width }) => (
        <div>
          {isMobile ? null : (
            <Toolbar
              title={`Asset: ${assetName}`}
              noXtraBar
              noBlueBar
            />
          )}
          <SideAppBar
            selectedTab={equipLocationTab(selectedTab)}
            tabs={equipmentTabs}
            onChangeTab={(val: string) =>
              setTab(equipLocationSet(val), true)
            }
            toolBar={{ single: true }}
          >
            <Suspense
              fallback={<LoadingOverlay loading delayedTime={5000} />}
            >
              {asset ? (
                lazyLoadEquipment(
                  selectedTab,
                  isMobile,
                  height,
                  width
                )
              ) : (
                <NoAccessPage Error="401 Unauthorized" />
              )}
            </Suspense>
          </SideAppBar>
        </div>
      )}
    </WidthViewport>
  )
}

export default Equipment
