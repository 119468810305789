import {
  ApolloClient,
  InMemoryCache,
  QueryOptions,
  HttpLink
} from '@apollo/client'
export const createApolloClient = (authToken: string): ApolloClient<any> => {
  return new ApolloClient({
    link: new HttpLink({
      uri: process.env.REACT_APP_GRAPH_API_URL,
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }),
    cache: new InMemoryCache(),
  })
}

