import { backend_api, headersAPI } from '../constants/api'
import { RoviLog } from '../utility/roviLog'

export async function apiGet(url: string) {
  const response = await fetch(
    `${backend_api}${url}`,
    {
      method: 'GET',
      headers: headersAPI
    }
  )
  const data = await response.json()
  if (
    !data.success
    && data.reason
  ) {
    RoviLog.error(data.reason)
    throw new Error(data.reason)
  }
  return data
}

export async function apiPost(url: string, body: object) {
  const response = await fetch(
    `${backend_api}${url}`,
    {
      method: 'POST',
      headers: headersAPI,
      body: JSON.stringify(body),
    }
  )

  const data = await response.json()
  if (
    !data.success
    && data.reason
  ) {
    RoviLog.error(data.reason)
    throw new Error(data.reason)
  }

  return data
}

export async function apiDelete(url: string) {
  const response = await fetch(
    `${backend_api}${url}`,
    {
      method: 'DELETE',
      headers: headersAPI,
    }
  )

  const data = await response.json()
  if (
    !data.success
    && data.reason
  ) {
    RoviLog.error(data.reason)
    throw new Error(data.reason)
  }

  return data
}
