import { IGeofenceEntity } from '../types/maptrac/playback'
import Duration from '../types/jquants/Duration'
import Length from '../types/jquants/Length'
import Percentage from '../types/jquants/Percentage'
import ElectricPotential from '../types/jquants/ElectricPotential'
import Temperature from '../types/jquants/Temperature'
import Velocity from '../types/jquants/Velocity'
import Pressure from '../types/jquants/Pressure'

export enum Metric {
  Altitude = 'Altitude',
  Battery = 'Battery',
  CabTemp = 'Cab Temperature',
  CompressorPressure = 'Compressor Pressure',
  Fuel = 'Fuel',
  GPS = 'GPS',
  Ignition = 'Ignition',
  LastDeviceUpdate = 'Last Device Update',
  Light1 = 'Light 1',
  Light2 = 'Light 2',
  Light3 = 'Light 3',
  Light4 = 'Light 4',
  PTO1 = 'PTO 1',
  PTO2 = 'PTO 2',
  SolarBattery1 = 'Solar Battery 1',
  SolarBattery2 = 'Solar Battery 2',
  Speed = 'Speed',
  InMotion = 'In Motion',
  DeviceBatteryLevel = 'Device Battery Level',
}

export const StringToMetric: Record<string, Metric> = {
  Altitude: Metric.Altitude,
  Battery: Metric.Battery,
  'Cab Temperature': Metric.CabTemp,
  'Compressor Pressure': Metric.CompressorPressure,
  Fuel: Metric.Fuel,
  GPS: Metric.GPS,
  Ignition: Metric.Ignition,
  'Last Device Update': Metric.LastDeviceUpdate,
  'Light 1': Metric.Light1,
  'Light 2': Metric.Light2,
  'Light 3': Metric.Light3,
  'Light 4': Metric.Light4,
  'PTO 1': Metric.PTO1,
  'PTO 2': Metric.PTO2,
  'Solar Battery 1': Metric.SolarBattery1,
  'Solar Battery 2': Metric.SolarBattery2,
  Speed: Metric.Speed,
  'In Motion': Metric.InMotion,
  'Device Battery Level': Metric.DeviceBatteryLevel,
}

export const MetricToString: Record<Metric, string> = {
  [Metric.Altitude]: 'Altitude',
  [Metric.Battery]: 'Battery',
  [Metric.CabTemp]: 'Cab Temperature',
  [Metric.CompressorPressure]: 'Compressor Pressure',
  [Metric.Fuel]: 'Fuel',
  [Metric.GPS]: 'GPS',
  [Metric.Ignition]: 'Ignition',
  [Metric.LastDeviceUpdate]: 'Last Device Update',
  [Metric.Light1]: 'Light 1',
  [Metric.Light2]: 'Light 2',
  [Metric.Light3]: 'Light 3',
  [Metric.Light4]: 'Light 4',
  [Metric.PTO1]: 'PTO 1',
  [Metric.PTO2]: 'PTO 2',
  [Metric.SolarBattery1]: 'Solar Battery 1',
  [Metric.SolarBattery2]: 'Solar Battery 2',
  [Metric.Speed]: 'Speed',
  [Metric.InMotion]: 'In Motion',
  [Metric.DeviceBatteryLevel]: 'Device Battery Level',
}

// Used in ConditionSelector to determine "typeof" the value
export const MetricValueTypes: Record<Metric, any> = {
  [Metric.Altitude]: 0,
  [Metric.Battery]: 0,
  [Metric.CabTemp]: 0,
  [Metric.CompressorPressure]: 0,
  [Metric.Fuel]: 0,
  [Metric.GPS]: '',
  [Metric.Ignition]: '',
  [Metric.LastDeviceUpdate]: 0,
  [Metric.Light1]: '',
  [Metric.Light2]: '',
  [Metric.Light3]: '',
  [Metric.Light4]: '',
  [Metric.PTO1]: '',
  [Metric.PTO2]: '',
  [Metric.SolarBattery1]: 0,
  [Metric.SolarBattery2]: 0,
  [Metric.Speed]: 0,
  [Metric.InMotion]: false,
  [Metric.DeviceBatteryLevel]: 0,
}

export type Day = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'

export type Operation = '<' | '>' | '=' | 'inside' | 'outside'

export const OperationStrings: Record<Operation, string> = {
  '<': 'less than',
  '>': 'greater than',
  '=': 'equal to',
  inside: 'inside',
  outside: 'outside',
}

export const MetricOperations: Record<Metric, Operation[]> = {
  [Metric.Altitude]: ['<', '>'],
  [Metric.Battery]: ['<', '>'],
  [Metric.CabTemp]: ['<', '>'],
  [Metric.CompressorPressure]: ['<', '>'],
  [Metric.Fuel]: ['<', '>'],
  [Metric.GPS]: ['outside', 'inside'],
  [Metric.Ignition]: ['='],
  [Metric.LastDeviceUpdate]: ['<', '>'],
  [Metric.Light1]: ['='],
  [Metric.Light2]: ['='],
  [Metric.Light3]: ['='],
  [Metric.Light4]: ['='],
  [Metric.PTO1]: ['='],
  [Metric.PTO2]: ['='],
  [Metric.SolarBattery1]: ['<', '>'],
  [Metric.SolarBattery2]: ['<', '>'],
  [Metric.Speed]: ['<', '>'],
  [Metric.InMotion]: ['='],
  [Metric.DeviceBatteryLevel]: ['<', '>'],
}

export interface IConditionRule_Fuel {
  metric: Metric.Fuel
  operation: '<' | '>'
  value: Percentage
}

export interface IConditionRule_Battery {
  metric: Metric.Battery
  operation: '<' | '>'
  value: ElectricPotential
}

export interface IConditionRule_SolarBattery1 {
  metric: Metric.SolarBattery1
  operation: '<' | '>'
  value: ElectricPotential
}

export interface IConditionRule_SolarBattery2 {
  metric: Metric.SolarBattery2
  operation: '<' | '>'
  value: ElectricPotential
}

export interface IConditionRule_Speed {
  metric: Metric.Speed
  operation: '<' | '>'
  value: Velocity
}

export interface IConditionRule_Temperature {
  metric: Metric.CabTemp
  operation: '<' | '>'
  value: Temperature
}

export interface IConditionRule_Altitude {
  metric: Metric.Altitude
  operation: '<' | '>'
  value: Length
}

export interface IConditionRule_CompressorPressure {
  metric: Metric.CompressorPressure
  operation: '<' | '>'
  value: Pressure
}

export interface IConditionRule_Ignition {
  metric: Metric.Ignition
  operation: '='
  value: 'on' | 'off'
}

export interface IConditionRule_Gps {
  metric: Metric.GPS
  operation: 'outside' | 'inside'
  value: string
}

export interface IConditionRule_LastDeviceUpdate {
  metric: Metric.LastDeviceUpdate
  operation: '<' | '>'
  value: Duration
}

export interface IConditionRule_Light1 {
  metric: Metric.Light1
  operation: '='
  value: 'on' | 'off'
}

export interface IConditionRule_Light2 {
  metric: Metric.Light2
  operation: '='
  value: 'on' | 'off'
}

export interface IConditionRule_Light3 {
  metric: Metric.Light3
  operation: '='
  value: 'on' | 'off'
}

export interface IConditionRule_Light4 {
  metric: Metric.Light4
  operation: '='
  value: 'on' | 'off'
}

export interface IConditionRule_PTO1 {
  metric: Metric.PTO1
  operation: '='
  value: 'on' | 'off'
}

export interface IConditionRule_PTO2 {
  metric: Metric.PTO2
  operation: '='
  value: 'on' | 'off'
}

export interface IConditionRule_DeviceBatteryLevel {
  metric: Metric.DeviceBatteryLevel
  operation: '<' | '>'
  value: Percentage
}

export interface IConditionRule_InMotion {
  metric: Metric.InMotion
  operation: '='
  value: true | false
}

export type IConditionRule =
  | IConditionRule_Fuel
  | IConditionRule_Battery
  | IConditionRule_SolarBattery1
  | IConditionRule_SolarBattery2
  | IConditionRule_Speed
  | IConditionRule_InMotion
  | IConditionRule_Temperature
  | IConditionRule_Altitude
  | IConditionRule_CompressorPressure
  | IConditionRule_Ignition
  | IConditionRule_Gps
  | IConditionRule_LastDeviceUpdate
  | IConditionRule_Light1
  | IConditionRule_Light2
  | IConditionRule_Light3
  | IConditionRule_Light4
  | IConditionRule_PTO1
  | IConditionRule_PTO2
  | IConditionRule_DeviceBatteryLevel

export interface ICondition {
  type: 'all' | 'any'
  conditions: IConditionRule[]
}

export interface ITimeRule {
  days: Day[]
  hours: [string, string]
}

export interface Flag {
  id: string
  name: string
  type: string
  flag_rules: IRule[]
  flag_category_assignments: {
    flag_id: string
    rule_id: string
    category_id: number
  }[]
  flag_asset_assignments: {
    flag_id: string
    rule_id: string
    asset_id: string
  }[]
}

export interface NewFlag {
  id?: string
  name: string
  type: string
  flag_rules: {
    data: IRule[]
  }
  flag_category_assignments: {
    data: {
      flag_id: string
      rule_id: string
      category_id: number
    }[]
  }
  flag_asset_assignments: {
    data: {
      flag_id: string
      rule_id: string
      asset_id: string
    }[]
  }
}

export interface IRule {
  id: string
  apply_to_all_assets: boolean
  condition: ICondition
  time: ITimeRule[]
}

export const flagConditionRuleFormatter = (
  condition: IConditionRule,
  geofences: Record<string, IGeofenceEntity>,
  useMetricMeasurement?: boolean
) => {
  switch (condition.metric) {
    case Metric.GPS:
      return `${MetricToString[condition.metric]} ${condition.operation} ${
        condition.value === 'asset.boundary'
          ? "Asset's Geofence"
          : geofences[condition.value.split(':')[1]].meta.name
      }`
    case Metric.LastDeviceUpdate:
      return `${MetricToString[condition.metric]} more than ${Duration.format(
        condition.value
      )} ago`
    case Metric.Fuel:
    case Metric.DeviceBatteryLevel:
      return `${MetricToString[condition.metric]} ${
        condition.operation
      } ${Percentage.format(condition.value)}`
    case Metric.Battery:
    case Metric.SolarBattery1:
    case Metric.SolarBattery2:
      return `${MetricToString[condition.metric]} ${
        condition.operation
      } ${ElectricPotential.format(condition.value)}`
    case Metric.Speed:
      return `${MetricToString[condition.metric]} ${
        condition.operation
      } ${Velocity.format(
        condition.value,
        useMetricMeasurement
          ? Velocity.Unit.KilometersPerHour
          : Velocity.Unit.MilesPerHour
      )}`
    case Metric.CabTemp:
      return `${MetricToString[condition.metric]} ${
        condition.operation
      } ${Temperature.format(
        condition.value,
        useMetricMeasurement
          ? Temperature.Unit.Celsius
          : Temperature.Unit.Fahrenheit
      )}`
    case Metric.Altitude:
      return `${MetricToString[condition.metric]} ${
        condition.operation
      } ${Length.format(
        condition.value,
        useMetricMeasurement ? Length.Unit.Meters : Length.Unit.Feet
      )}`
    case Metric.CompressorPressure:
      return `${MetricToString[condition.metric]} ${
        condition.operation
      } ${Pressure.format(condition.value, Pressure.Unit.PSI)}`
    case Metric.InMotion:
      return `${MetricToString[condition.metric]} ${condition.operation} ${
        condition.value
      }`
    default:
      return `${MetricToString[condition.metric]} ${condition.operation} ${
        condition.value
      }`
  }
}

export const getNewEmptyFlag = (): NewFlag => ({
  name: '',
  type: '',
  flag_rules: {
    data: [
      {
        id: crypto.randomUUID(),
        apply_to_all_assets: true,
        time: [
          {
            days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
            hours: ['12AM', '12AM'],
          },
        ],
        condition: {
          type: 'all',
          conditions: [getNewDefaultConditionRule()],
        },
      },
    ],
  },
  flag_category_assignments: {
    data: [],
  },
  flag_asset_assignments: {
    data: [],
  },
})

export const getNewDefaultConditionRule = (): IConditionRule => ({
  metric: Metric.Fuel,
  operation: '<',
  value: 20,
})

export const isAnyTime = (time: ITimeRule) =>
  time.days.length === 7 && time.hours[0] === '12AM' && time.hours[1] === '12AM'
