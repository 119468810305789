import React, {useState} from 'react'
import ReactHelmet from '../../components/ReactHelmet'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Button from '../../components/buttons/Button'
import Carousel from '../../components/ux/Carousel'
import DeviceTypeCard from '../../components/accountComponents/DeviceTypeCard'
import { backend_api, headersAPI } from '../../constants/api'
import { IDeviceType, IDevice } from '../../types/account/devices'
import { colors } from '../../components/ux/roviTheme'
import LoadingOverlay from '../../components/LoadingOverlay'
import PurchaseDeviceDialog from '../../components/accountComponents/dialogs/PurchaseDeviceDialog'

import {
  getAmplifyUser,
} from '../../api/user'
import {
  getDevices,
  getDeviceTypes,
} from '../../api/device'

// id = dt.id,
// label = dt.label,
// dimensions = dt.dimensions,
// description = dt.usage,
// shortDescription = dt.shortDescription,
// price = dt.price,
// showOnMarket = dt.showOnMarket,
// imageUrl = s"https://public.rovitracker.com/app/images/devices/${dt.id.text}.png",
// smallImageUrl = s"https://public.rovitracker.com/app/images/devices/${dt.id.text}-small.png",
// isGateway = dt.isGateway,
// isTag = dt.isTag

const DevicesPage = (props: {
  isMobile: boolean
}) => {
  const [amplifyUser, setAmplifyUser] = getAmplifyUser()
  // TODO: Needs to be all devices on the account
  const {devices, loadingDevices} = getDevices(amplifyUser)
  const [purchaseDeviceId, setPurchaseDeviceId] = useState('')
  const {deviceTypes, loadingDeviceTypes} = getDeviceTypes()
  const deviceTypeMap = deviceTypes.reduce<Record<string, IDeviceType>>(
    (map, deviceType) => {
      return {
        ...map,
        [deviceType.id]: deviceType,
      }
    },
    {}
  )

  function getImageUrl(deviceTypeId: string): string {
    return deviceTypeMap[deviceTypeId]?.device_type.image_url || ''
  }

  return (
    <>
      <ReactHelmet title="Devices" />
      <LoadingOverlay
        loading={loadingDeviceTypes && loadingDevices}
      />
      <div
        style={{
          display: 'flex',
            flexDirection: 'column',
            padding: props.isMobile ? '48px 0' : undefined,
        }}
      >
        <div>
          <Typography
            variant="h6"
            style={{ paddingLeft: 20, paddingTop: 10 }}
          >
            Available Devices
          </Typography>

    {loadingDeviceTypes ? null : (
      <Carousel scrollBy={290}>
        {deviceTypes.map((device, key) => (
          <div key={key}>
            {device.device_type.show_on_market ? (
              <DeviceTypeCard
                deviceType={device}
                onClickPurchase={() =>
                  setPurchaseDeviceId(device.id)
                }
              />
            ) : null}
            {purchaseDeviceId === device.id ? (
              <PurchaseDeviceDialog
                dialogOpen={purchaseDeviceId === device.id}
                onClose={() => setPurchaseDeviceId('')}
                deviceType={device}
              />
            ) : null}
          </div>
        ))}
        <div style={{ width: 1, flexShrink: 0 }} />
      </Carousel>
    )}
  </div>

  <div style={{ padding: props.isMobile ? undefined : 10 }}>
    <Typography
      variant="h6"
      color="textPrimary"
      style={{ padding: 10 }}
    >
      Purchased Devices
    </Typography>
    {loadingDevices ? null : (
      <Paper style={{ width: '100%', overflowX: 'auto' }}>
        <Table style={{ minWidth: 900 }}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                Device ID ({devices.length})
              </TableCell>
              <TableCell>Device Type</TableCell>
              <TableCell>Branch</TableCell>
              <TableCell>Asset</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {devices.map((device, key) => {
              const assetInfo = device?.asset_device?.asset?.asset_info
              return (
                <TableRow key={key}>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                          alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          width: 45,
                            height: 45,
                            overflow: 'hidden',
                            borderRadius: '50%',
                            margin: '5px 0',
                        }}
                      >
                        <Link href="/">
                          <img
                            alt={device.device_info?.model_map?.rovitracker_model_name}
                            src={getImageUrl(device.device_info?.model_map?.id || '')}
                            style={{ height: 45 }}
                          />
                          {/* <img
                               alt={'device Img' + key}
                               src="https://via.placeholder.com/150"
                               style={{ height: 45 }}
                             /> */}
                           </Link>
                         </div>
                       </div>
                     </TableCell>
                     <TableCell>{device.device_esn}</TableCell>
                     <TableCell>{device?.device_info?.model_map?.rovitracker_model_name}</TableCell>
                     <TableCell>{device?.asset_device?.asset?.branch?.branch_name}</TableCell>
                     <TableCell>
                       <div>
                         <Link href={`/asset/${assetInfo?.asset_id}`}>{assetInfo?.asset_name}</Link>
                         <br />
                         <span
                           style={{
                             color: colors.textGrey,
                           }}
                         >
                           {assetInfo?.asset_make} / {assetInfo?.asset_model}
                         </span>
                       </div>
                     </TableCell>
                     <TableCell>
                       <Button
                         color="primary"
                         onClick={() => setPurchaseDeviceId(device.device_info?.model_map?.id || '')}
                       >
                         Purchase More
                       </Button>
                     </TableCell>
                   </TableRow>
                 )
            })}
           </TableBody>
         </Table>
       </Paper>
   )}
 </div>
     </div>
   </>
 )
}

export default DevicesPage
