import React from 'react'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import TreeSelector, { ITreeLayout } from '../../ux/TreeSelector'

interface IAssetViewProps extends WithStyles<typeof styles> {
  values: string[]
  categoryTree: ITreeLayout[]
  onChangeAssets: (assets: string[]) => void
  isMobile?: boolean
}

const AssetView = (props: IAssetViewProps) => {
  return (
    <TreeSelector
      allLabel="All"
      values={props.values}
      treeNodes={props.categoryTree}
      onChanged={props.onChangeAssets}
      parentsAreLinkedToChildren
    />
  )
}

const styles = createStyles({
  flex: {
    flex: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export default withStyles(styles)(AssetView)
