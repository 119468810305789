import React, {useState} from 'react'
import ReactHelmet from '../components/ReactHelmet'
import Toolbar from '../components/toolbar/Toolbar'
import WidthViewport from '../components/ux/WidthViewport'
import Button from '../components/buttons/Button'
import Icon from '../components/Icon'
import { ERoutes, PageTitle } from '../components/routes/CheckRoutes'
import { LoadingIcon } from '../components/LoadingOverlay'
import { ICategory, CategoryWithAssetCount } from '../types/category'
import CategoryRow from '../components/categoriesComponent/CategoryRow'
import AddEditCategoryDialog from '../components/categoriesComponent/dialogs/AddEditCategoryDialog'
import FabButton from '../components/buttons/FabButton'
import { RoviLog } from '../utility/roviLog'
import {
  apiGet,
  apiPost,
  apiDelete,
} from '../apiCalls/apiCall'
import {
  getAmplifyUser,
} from '../api/user'
import {
  getCategoriesWithAssetCount,
  queryInsertCategory,
  queryUpdateCategory,
  queryDeleteCategory,
} from '../api/category'
import { Auth } from 'aws-amplify'

const CategoriesPage = (props: {
}) => {
  const [amplifyUser, setAmplifyUser] = getAmplifyUser()
  const {
    categories,
    loadingCategories,
  } = getCategoriesWithAssetCount(amplifyUser)
  const [addCategoryDialogOpen, setAddCategoryDialogOpen] = useState(false)
  const insertCategory = queryInsertCategory()
  const updateCategory = queryUpdateCategory()
  const deleteCategory = queryDeleteCategory()

  function addCategory(category: ICategory) {
    insertCategory({
      variables: {
        category: {
          ...category,
          tenant_id: amplifyUser?.['custom:tenantID'],
        },
      }
    })
  }

  function executeUpdateCategory(category: ICategory) {
    console.log(category)
    updateCategory({
      variables: {
        ...category,
      }
    })
  }

  function removeCategory(id: number) {
    deleteCategory({
      variables: {
        id,
      }
    })
  }

  return (
    <WidthViewport>
      {({ isMobile }) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ReactHelmet title={PageTitle(ERoutes.categories)} />
          <Toolbar
            title={PageTitle(ERoutes.categories)}
            scrollBackgroundColor
            noXtraBar={isMobile}
            defaultBackgroundColor
          >
            {isMobile ? null : (
              <Button
                onClick={() => setAddCategoryDialogOpen(true)}
              >
                <Icon icon="add" variant="button" />
                Category
              </Button>
            )}
            <LoadingIcon loading={loadingCategories} />
          </Toolbar>
          <div
            style={{
              marginTop: isMobile ? 50 : 100,
              marginBottom: isMobile ? 80 : undefined,
            }}
          >
            {categories.map((val: CategoryWithAssetCount, key) => (
              <CategoryRow
                category={val}
                isMobile={isMobile}
                key={key}
                onUpdate={executeUpdateCategory}
                onDelete={removeCategory}
              />
            ))}
          </div>
          {isMobile ? (
            <FabButton
              icon="add"
              onClick={() => setAddCategoryDialogOpen(true)}
            />
          ) : null}
          {addCategoryDialogOpen ? (
            <AddEditCategoryDialog
              type="add"
              isMobile={isMobile}
              dialogOpen={addCategoryDialogOpen}
              onSave={addCategory}
              onClose={() => setAddCategoryDialogOpen(false)}
            />
          ) : null}
        </div>
      )}
    </WidthViewport>
  )
}

export default CategoriesPage
