import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import Select, { ISelectValues } from '../../ux/Select'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { backend_api, headersAPI } from '../../../constants/api'
import { IClient } from '../../../types/clients'
import Skeleton from '@mui/material/Skeleton'
import DatePicker from '../../datePickers/DatePicker'

const styles = () =>
  createStyles({
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  assetName: string
  assetId: string
}

interface IState {
  clients: IClient[]
  clientSelectValue: ISelectValues[]
  selectedClient: string
  loadingClients: boolean
  selectedDate: Date | null
}

class MarkAsSold extends React.Component<IProps, IState> {
  state: IState = {
    clients: [],
    clientSelectValue: [],
    selectedClient: '',
    loadingClients: true,
    selectedDate: new Date(),
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  loadClients() {
    fetch(backend_api + '/clients/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response: IClient[]) => {
        this.setState({
          clients: response,
          clientSelectValue: response.map(
            (client) =>
              ({ value: client.id, label: client.name } as ISelectValues)
          ),
          loadingClients: false,
        })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.loadClients()
  }

  render() {
    const classes = this.props.classes
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose}>
        <DialogTitle>Mark {this.props.assetName} as Sold?</DialogTitle>
        {this.state.loadingClients ? (
          <DialogContent className={classes.dialogContent}>
            <Skeleton animation="wave" className={classes.TextField} />
            <Skeleton animation="wave" className={classes.TextField} />
          </DialogContent>
        ) : (
          <DialogContent className={classes.dialogContent}>
            <Select
              label="Sold To"
              selectedValue={this.state.selectedClient}
              values={this.state.clientSelectValue}
              onChange={(value) =>
                this.setState({
                  selectedClient: value as string,
                })
              }
              className={classes.TextField}
            />
            <DatePicker
              selectedDate={this.state.selectedDate}
              onChange={(date: Date | null) => {
                console.log(date)
                this.setState({ selectedDate: date })
              }}
              showToolbar
              className={classes.TextField}
              label="Sell Date"
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
            disabled={!this.state.selectedClient && !this.state.selectedDate}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(MarkAsSold)
