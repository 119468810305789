import React from 'react'
import '../../styles/components/Appbar.scss'
import AppBar from '@mui/material/AppBar'
import IconButton from '../buttons/IconButton'
import BranchMenu from './BranchMenu'
import UserMenu from '../UserMenu'
import AppBarLinks from './AppBarLinks'
import { ILinks } from '../../types/appbar'
import { IUser } from '../../types/account/user'
import { colors } from '../ux/roviTheme'
import {
  Branch,
} from '../../types/tenant'

interface IProps {
}

const DesktopRoviAppbar = (props: {
  links: ILinks[]
  user: IUser|null
  branches: Branch[]
  currentBranch?: Branch
  setCurrentBranchId: (branchId: string) => void
  title?: string
}) => {
  return (
    <div className="mainAppBar">
      <AppBar
        position="absolute"
        className="innerMainAppBar"
        style={{ backgroundColor: colors.bg }}
      >
        <div className="appBarFloatLeft">
          <a href="https://rovitracker.com/">
            <img
              className="rovitrackerLogo"
              src="https://s3.amazonaws.com/assets.rovitracker.cloud/images/rovitrackerLogos/rovi-logo-120x78.png"
              alt="Rovitracker Logo"
            />
          </a>

          {props.user && (
            <AppBarLinks links={props.links} />
          )}
        </div>

        {props.user && (
          <div className="appBarFloatRight">
            <BranchMenu
              title={props.currentBranch?.branch_name || 'Loading...'}
              branches={props.branches}
              currentBranch={props.currentBranch}
              setCurrentBranchId={props.setCurrentBranchId}
            />

            <IconButton
              variant="appbar"
              icon="notifications"
              href="/notifications"
              id="appbarNotificationsButton"
              tooltip="Notifications"
            />

            <UserMenu
              title="Session"
              user={props.user}
              accountName="Rovitracker Inc."
            />
          </div>
        )}
      </AppBar>
    </div>
  )
}

export default DesktopRoviAppbar
