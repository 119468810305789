import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import DialogContentText from '@mui/material/DialogContentText'
import {
  ICategory,
} from '../../../types/category'

const DeleteCategoryDialog = (props: {
  dialogOpen: boolean
  onClose: () => void
  onDelete: (categoryId: number) => void
  category: ICategory
}) => {
  function handleClose() {
    props.onClose()
  }

  function onSubmit() {
    props.onDelete(props.category.id)
    props.onClose()
  }

  return (
    <Dialog open={props.dialogOpen} onClose={handleClose}>
      <DialogTitle>Delete {props.category.name}?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this category?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={onSubmit}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteCategoryDialog
