import { apiGet } from './apiCall'
import {
  IUser,
  ERole,
  AmplifyUser,
} from '../types/account/user'

export async function getAllUsersByTenantId(tenantId: string): Promise<IUser[]> {
  const response = await apiGet(`/user/byTenant/${tenantId}`)
  let users: IUser[] = []
  for (const user of response.users) {
    users.push({
      id: user.userId,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      createdDate: user.createdDate,
      role: getUserRole(user.accountType),
    })
  }
  return users
}

function getUserRole(accountType: number): ERole {
  const roles = {
    1: ERole.admin,
    2: ERole.branch,
    3: ERole.worker,
  }
  const userRole: ERole = roles[accountType]
  return userRole
}
