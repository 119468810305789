import { AssetTabAsset } from '../AssetsTabTypes'
import moment from 'moment'

interface IPrintJsNewList {
  ID: string
  Vin: string
  Serial: string
  'Year Built': string
  'Purchase Date': string
  Category: string
  Make: string
  Model: string
  Site: string
  Device: string
}

export const printList = [
  'ID',
  'Vin',
  'Serial',
  'Year Built',
  'Purchase Date',
  'Category',
  'Make',
  'Model',
  'Site',
  'Device',
]

function checkString(strCheck?: any) {
  return strCheck ? strCheck : ''
}

export function printJsSortInventory(assets: AssetTabAsset[]) {
  let newList: IPrintJsNewList[] = []
  assets.forEach((asset: AssetTabAsset) => {
    let newVal = {
      ID: checkString(asset.asset_info?.asset_name),
      Vin: checkString(asset.asset_info?.asset_vin),
      Serial: checkString(asset.asset_info?.serial_number),
      'Year Built': checkString(asset.asset_info?.manufacture_year),
      'Purchase Date': moment(checkString(asset.asset_info?.purchase_date)).format('l'),
      Category: checkString(asset.asset_info?.category?.name),
      Make: checkString(asset.asset_info?.asset_make),
      Model: checkString(asset.asset_info?.asset_model),
      Site: checkString(asset.agreement?.site.name),
      Device: checkString(asset.device),
    }
    newList.push(newVal)
  })
  return newList
}

interface ICSVNewList {
  ID: string
  Vin: string
  Serial: string
  'Year Built': string
  'Purchase Date': string
  Category: string
  Make: string
  Model: string
  Site: string
  Device: string
  'Purchase Price': string
  'Engine Hours': string
}

export function csvSortInventory(assets: AssetTabAsset[]) {
  let newList: ICSVNewList[] = []
  assets.forEach((asset: AssetTabAsset) => {
    let newVal = {
      ID: checkString(asset.asset_info?.asset_name),
      Vin: checkString(asset.asset_info?.asset_vin),
      Serial: checkString(asset.asset_info?.serial_number),
      'Year Built': checkString(asset.asset_info?.manufacture_year),
      'Purchase Date': moment(checkString(asset.asset_info?.purchase_date)).format('l'),
      Category: checkString(asset.asset_info?.category?.name),
      Make: checkString(asset.asset_info?.asset_make),
      Model: checkString(asset.asset_info?.asset_model),
      Site: checkString(asset.agreement?.site.name),
      Device: checkString(asset.device),
      'Purchase Price': '$' + checkString(asset.asset_info?.purchase_price) + ' USD',
      'Engine Hours': checkString(asset.odometer),
    }
    newList.push(newVal)
  })
  return newList
}
