import React from 'react'
import Link from '@mui/material/Link'

interface IProps {
  name: string
  href: string
}

class FlagBox extends React.Component<IProps> {
  render() {
    return (
      <Link
        href={this.props.href}
        style={{
          color: '#ffffff',
          padding: '1px 4px',
          overflow: 'hidden',
          fontSize: 11,
          background: '#ba68c8',
          fontWeight: 480,
          whiteSpace: 'nowrap',
          borderRadius: 2,
          textOverflow: 'ellipsis',
          margin: 1,
        }}
      >
        {this.props.name}
      </Link>
    )
  }
}

export default FlagBox
