import React, {useState} from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogRow from '../../dialogComponents/DialogRow'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Select, { ISelectValues } from '../../ux/Select'
import SelectArr from '../../ux/SelectArr'
import TextField from '../../textBoxs/TextField'
import Button from '../../buttons/Button'
import { countriesArray } from '../../../constants/countries'
import InfoHover from '../../buttons/InfoHover'
import NumberField from '../../textBoxs/NumberField'
import {
  timezoneList,
  currentUserTimezone,
  coreTimeZones,
} from '../../../constants/timezones'
import IconButton from '../../buttons/IconButton'
import {
  Branch,
  NewBranch,
} from '../../../types/tenant'

const styles = () =>
  createStyles({
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    SelectField: {
      width: 250,
    },
  })

const yesNoArr = ['Yes', 'No']

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  onSubmit: (editableBranch: NewBranch) => void
  branch?: Branch
}

const AddEditBranchDialog = (props: IProps) => {
  let branchToEdit
  if (props.branch?.id) {
    branchToEdit = {
      ...props.branch,
      branch_rate: {
        data: {
          ...props.branch.branch_rate,
        }
      }
    }
  } else {
    branchToEdit = {
      branch_name: '',
      address: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'US',
      timezone: 'America/Denver',
      latitude: 0,
      longitude: 0,
      use_metric: false,
      branch_rate: {
        data: {
          rental_tax_rate: 0,
          branch_tax_rate: 0,
          hourly_labor_rate: 0,
        },
      },
    }
  }

  const [editableBranch, setEditableBranch] = useState(branchToEdit as NewBranch)

  function onSubmit() {
    props.onSubmit(editableBranch)
    props.onClose()
  }

  function updateBranchValue(
    key: keyof NewBranch,
    value: any
  ) {
    setEditableBranch({
      ...editableBranch,
      [key]: value,
    })
  }

  const isAddDialog = !(props.branch?.id)
  const isSaveButtonDisabled = !(
    !!editableBranch.branch_name
    && !!editableBranch.country
    && !!editableBranch.timezone
  )

  const [showAllTimezones, setShowAllTimezones] = useState(
    !coreTimeZones.includes(editableBranch.timezone)
  )

  return (
    <Dialog open={props.dialogOpen} onClose={props.onClose} fullWidth>
      <DialogTitle>
        {isAddDialog ? 'Add' : 'Edit'} Branch
        <InfoHover>A branch is like your rental yard or store</InfoHover>
      </DialogTitle>

      <DialogContent>
        <DialogRow
          FieldLeft={
            <TextField
              label="Name"
              required
              className={props.classes.TextField}
              value={editableBranch.branch_name}
              onChange={(event) => updateBranchValue('branch_name', event.target.value)}
            />
          }
        />

        <DialogRow
          FieldLeft={
            <TextField
              label="Address"
              className={props.classes.TextField}
              value={editableBranch.address}
              onChange={(event) => updateBranchValue('address', event.target.value)}
            />
          }
          FieldRight={
            <TextField
              label="City"
              className={props.classes.TextField}
              value={editableBranch.city}
              onChange={(event) => updateBranchValue('city', event.target.value)}
            />
          }
        />

        <DialogRow
          FieldLeft={
            <TextField
              label="State / Province"
              className={props.classes.TextField}
              value={editableBranch.state}
              onChange={(event) => updateBranchValue('state', event.target.value)}
            />
          }
          FieldRight={
            <TextField
              label="Postal Code"
              className={props.classes.TextField}
              value={editableBranch.postal_code}
              onChange={(event) => updateBranchValue('postal_code', event.target.value)}
            />
          }
        />

        <DialogRow
          FieldLeft={
            <Select
              label="Country"
              required
              selectedValue={editableBranch.country}
              values={countriesArray}
              onChange={(name: string) => updateBranchValue('country', name)}
              className={props.classes.SelectField}
            />
          }
          FieldRight={
            <SelectArr
              label="Time Zone"
              required
              selectedValue={editableBranch.timezone}
              values={
                showAllTimezones
                  ? timezoneList
                  : coreTimeZones
              }
              onChange={(event) => updateBranchValue('timezone', event.target.value as string)}
              className={props.classes.SelectField}
            />
          }
          FieldRightAddOn={
            <IconButton
              onClick={() => setShowAllTimezones(!showAllTimezones)}
              variant="appbar"
              icon={showAllTimezones ? 'moreTimezone' : 'timezone'}
              tooltip={`showing ${
                showAllTimezones ? 'more' : 'less'
              } timezones`}
            />
          }
        />

        <DialogRow
          FieldLeft={
            <NumberField
              startAdornment="$"
              label="Labor Hourly Rate"
              value={editableBranch.branch_rate.data.hourly_labor_rate}
              onChange={(event) =>
                setEditableBranch({
                  ...editableBranch,
                  branch_rate: {
                    data: {
                      ...editableBranch.branch_rate.data,
                      hourly_labor_rate: event,
                    }
                  },
                })
              }
              className={props.classes.TextField}
            />
          }
        />

        <DialogRow
          FieldLeft={
            <SelectArr
              label="Use Metric System"
              selectedValue={editableBranch.use_metric ? 'Yes' : 'No'}
              values={yesNoArr}
              onChange={(event) => updateBranchValue(
                'use_metric',
                event.target.value === 'Yes'
              )}
              className={props.classes.TextField}
            />
          }
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={isSaveButtonDisabled}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(AddEditBranchDialog)
