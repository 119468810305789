import {
  gql,
  useQuery,
  useMutation,
} from '@apollo/client'

import {
  useState,
  useEffect,
} from 'react'
import {
  AmplifyUser,
  IUser,
} from '../types/account/user'
import {
  Tenant,
  Branch,
} from '../types/tenant'

import {
  queryUpdateUserSettings
} from './user'

export const GET_TENANT =  gql`
  query GetTenant($id: uuid!) {
    tenants_tenants_by_pk(id: $id) {
      tenant_name
      tenants_image {
        logo_image_url
      }

      tenants_info {
        address
        city
        state
        country
        postal_code
        phone
        fax
      }

      tenant_setting {
        basic_features
        rental_features
        monitoring
        invoice_term
        estimate_term
      }
    }
  }
`

export function getCurrentTenant(
  amplifyUser: AmplifyUser|null
): {
  tenant: Tenant|null
  loadingTenant: boolean
} {
  const { loading, error, data } = useQuery(GET_TENANT, {
    variables: {
      id: amplifyUser?.['custom:tenantID'],
    },
    skip: !amplifyUser,
  })
  return {
    tenant: data?.tenants_tenants_by_pk || null,
    loadingTenant: loading,
  }
}

export const UPDATE_TENANT = gql`
  mutation(
    $id: uuid!,
    $tenant: tenants_tenants_set_input!
    $tenants_info: tenants_tenants_info_set_input!
  ) {
    update_tenants_tenants_by_pk(
      pk_columns: {id: $id}
      _set: $tenant
    ) {
        id
    }
    update_tenants_tenants_info_by_pk(
      pk_columns: {tenant_id: $id}
      _set: $tenants_info
    ) {
        tenant_id
    }
  }
`

export function queryUpdateTenant(amplifyUser: AmplifyUser|null) {
  const [updateTenant, { loading, error, data }] = useMutation(UPDATE_TENANT, {
    refetchQueries: [
      'GetTenant',
    ],
  })
  return updateTenant
}

export const GET_BRANCHES_BY_TENANT_ID =  gql`
  query GetBranchesByTenantId($tenant_id: uuid!) {
    branches_branch(
    where: {
      tenant_id: {_eq: $tenant_id}
      deleted_date: {_is_null: true}
    }
  ) {
      id
      branch_name
      address
      city
      state
      postal_code
      country
      timezone
      latitude
      longitude
      use_metric
      branch_rate {
        rental_tax_rate
        branch_tax_rate
        hourly_labor_rate
      }
      user_branches {
        user {
          id
          first_name
          last_name
          email
        }
      }
      assets_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export function getBranches(
  amplifyUser: AmplifyUser|null
): {
  branches: Branch[]
  loadingBranches: boolean
} {
  const { loading, error, data } = useQuery(GET_BRANCHES_BY_TENANT_ID, {
    variables: {
      tenant_id: amplifyUser?.['custom:tenantID'],
    },
    skip: !amplifyUser,
  })
  return {
    branches: data?.branches_branch || [],
    loadingBranches: loading,
  }
}

export const INSERT_BRANCH = gql`
  mutation($branch: branches_branch_insert_input!) {
    insert_branches_branch_one(
      object: $branch
    ) {
        id
    }
  }
`

export function queryInsertBranch(amplifyUser: AmplifyUser|null) {
  const [insertBranch, { loading, error, data }] = useMutation(INSERT_BRANCH, {
    refetchQueries: [
      'GetBranchesByTenantId',
    ],
  })
  return insertBranch
}

export const UPDATE_BRANCH = gql`
  mutation(
    $id: uuid!,
    $branch: branches_branch_set_input!
    $branch_rate: branches_branch_rates_set_input!
  ) {
    update_branches_branch_by_pk(
      pk_columns: {id: $id}
      _set: $branch
    ) {
        id
    }
    update_branches_branch_rates_by_pk(
      pk_columns: {branch_id: $id}
      _set: $branch_rate
    ) {
        branch_id
    }
  }
`

export function queryUpdateBranch(amplifyUser: AmplifyUser|null) {
  const [updateBranch, { loading, error, data }] = useMutation(UPDATE_BRANCH, {
    refetchQueries: [
      'GetBranchesByTenantId',
    ],
  })
  return updateBranch
}

export const DELETE_BRANCH = gql`
  mutation(
    $id: uuid!,
  ) {
    update_branches_branch_by_pk(
      pk_columns: {id: $id}
      _set: {
        deleted_date: "now()"
      }
    ) {
        id
    }
  }
`

export function queryDeleteBranch(amplifyUser: AmplifyUser|null) {
  const [deleteBranch, { loading, error, data }] = useMutation(DELETE_BRANCH, {
    refetchQueries: [
      'GetBranchesByTenantId',
    ],
  })
  return deleteBranch
}

export function getCurrentBranch(
  user: IUser|null,
  branches: Branch[],
  amplifyUser: AmplifyUser|null
): {
  currentBranch: Branch|undefined
  setNewCurrentBranch: (branchId: string|undefined) => void
} {
  const [currentBranch, setCurrentBranch] = useState(undefined as Branch|undefined)
  const {setNewUserSettings} = queryUpdateUserSettings(amplifyUser)
  const currentBranchId: string|undefined = user?.settings?.currentBranchId
  const [isGettingCurrentBranch, setIsGettingCurrentBranch] = useState(true)

  function setNewCurrentBranch(branchId: string|undefined) {
    const branch = branches.find(branch => branch.id == branchId)
    if (!branch) {
      setIsGettingCurrentBranch(false)
      setCurrentBranch(undefined)
      setNewUserSettings({
        ...(user?.settings || {}),
        currentBranchId: undefined,
      })
      return
    }

    setIsGettingCurrentBranch(false)
    setCurrentBranch(branch)
    setNewUserSettings({
      ...(user?.settings || {}),
      currentBranchId: branch.id,
    })
  }

  if (
    !currentBranch
    && currentBranchId
    && branches.length
    && isGettingCurrentBranch
  ) {
    const branch = branches.find(branch => branch.id == currentBranchId)
    setNewCurrentBranch(branch?.id)
    setIsGettingCurrentBranch(false)
  } else if (
    !currentBranch
    && !currentBranchId
    && branches.length
    && isGettingCurrentBranch
  ) {
    const branch = branches[0]
    setNewCurrentBranch(branches[0].id)
    setIsGettingCurrentBranch(false)
  }

  if (
    currentBranch
    && currentBranch.id !== currentBranchId
    && isGettingCurrentBranch
  ) {
    const branch = branches.find(branch => branch.id == currentBranchId)
    setCurrentBranch(branch)
  }

  return {
    currentBranch,
    setNewCurrentBranch,
  }
}
