import { Geofence } from '../../../types/geofence'

export function filterGeofenceDrawer(
  geofences: Geofence[],
  searchString: string
) {
  if (!searchString) {
    return geofences
  }
  searchString = searchString.toLowerCase()
  return geofences.filter((geofence: Geofence) =>
    geofence.name.toLowerCase().includes(searchString)
  )
}

export function filterGeofenceMarkers(
  geofences: Geofence[],
  searchString: string
) {
  if (!searchString) {
    return geofences
  }
  searchString = searchString.toLowerCase()
  return geofences.filter((geofence) =>
    geofence.name.toLowerCase().includes(searchString)
  )
}

export function filterGeofenceMarkersUUID(
  geofences: Geofence[],
  searchUUID: string
) {
  if (!searchUUID) {
    return geofences
  }
  searchUUID = searchUUID.toLowerCase()
  return geofences.filter(
    (geofence) => geofence.id.toLowerCase() !== searchUUID
  )
}
