import React, {useState} from 'react'
import {
  ListItemIcon,
  ListItemText,
  ListSubheader,
  List,
  ListItem,
  Divider,
} from '@mui/material'
import Icon from '../../Icon'
import Popover from '@mui/material/Popover'
import { backend_api, headersAPI } from '../../../constants/api'
import { PaddedSearchBar } from '../../textBoxs/SearchBar'
import { Link } from 'react-router-dom'
import routes from '../../routes/routes'
import { colors } from '../../ux/roviTheme'
import {
  Device,
  AssetTabDeviceModel,
  AssetTabDeviceModelMap,
} from '../../inventory/AssetsTabTypes'

interface DeviceMap {
  [id: string]: Device
}

const AddDeviceComponent = (props: {
  anchorEl: HTMLButtonElement | null
  onClose: () => void
  isInventory?: boolean
  addDevices: (devices: Device[]) => void
  addPhone?: () => void
  devicesByModel: AssetTabDeviceModelMap
}) => {
  const [deviceSearch, setDeviceSearch] = useState('')
  const [devicesToAdd, setDevicesToAdd] = useState({} as DeviceMap)
  const models: AssetTabDeviceModel[] = Object.values(props.devicesByModel)

  return (
    <Popover
      open={!!props.anchorEl}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <PaddedSearchBar
        value={deviceSearch}
        onChange={(search) => setDeviceSearch(search)}
      />
      <List
        style={{ height: 300, width: 250, overflow: 'auto', paddingTop: 0 }}
      >
        {models.map((model) => (
          <>
            <ListSubheader style={{ backgroundColor: '#fff' }}>
              {model.manufacturer_model_name}
            </ListSubheader>
            {model.devices
              .filter((device) =>
                device.device_esn
                  .toLowerCase()
                  .includes(deviceSearch.toLowerCase())
              )
              .map((device) => (
                <ListItem
                  button
                  onClick={() => {
                    if (devicesToAdd[device.id]) {
                      let newDevicesToAdd = {...devicesToAdd}
                      delete newDevicesToAdd[device.id]
                      setDevicesToAdd(newDevicesToAdd)
                      return
                    }

                    setDevicesToAdd({
                      ...devicesToAdd,
                      [device.id]: device,
                    })
                  }}
                  selected={devicesToAdd[device.id] && true}
                  key={device.id}
                >
                  <ListItemText>{device.device_esn}</ListItemText>
                </ListItem>
              ))}
          </>
        ))}
        <Divider />
        <Link
          to={routes.gotoDevicesPage}
          style={{ color: colors.defaultText }}
        >
          <ListItem button>
            <ListItemIcon>
              <Icon icon="add" />
            </ListItemIcon>
            <ListItemText>Purchase Device</ListItemText>
          </ListItem>
        </Link>
        {props.isInventory ? null : (
          <ListItem button onClick={props.addPhone}>
            <ListItemIcon>
              <Icon icon="add" />
            </ListItemIcon>
            <ListItemText>Mobile Phone App</ListItemText>
          </ListItem>
        )}
      </List>
      <ListItem
        button
        disabled={Object.keys(devicesToAdd).length === 0}
        onClick={() => {
          props.addDevices(Object.values(devicesToAdd) as Device[])
        }}
      >
        <ListItemIcon>
          <Icon icon="add" />
        </ListItemIcon>
        <ListItemText>Add Devices</ListItemText>
      </ListItem>
    </Popover>
  )
}

export default AddDeviceComponent
