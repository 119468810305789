import React, {useState} from 'react'
import '../../styles/components/accountComponents/ContactCard.scss'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '../buttons/IconButton'
import { ERoutes } from '../routes/CheckRoutes'
import MessageOverlay from '../ux/MessageOverlay'
import EllipsisText from '../ux/EllipsisText'
import EditAccountDialog from '../accountComponents/dialogs/EditAccountDialog'
import { Tenant } from '../../types/tenant'

interface IState {
  editAccountDialog: boolean
}

interface IProps {
}

const ContactCard = (props: {
  tenant: Tenant
  loadingData: boolean
  onSave: (tenant: Tenant) => void
}) => {
  const [showEditAccountDialog, setShowEditAccountDialog] = useState(false)

  const tenantsInfo = props.tenant?.tenants_info
  return (
    <>
      <Card className="contactCard">
        <CardHeader
          action={
            <>
              <IconButton
                icon="history"
                variant="row"
                tooltip="History"
                href={ERoutes.history}
              />
              <IconButton
                icon="edit"
                variant="row"
                tooltip="Edit"
                onClick={() => setShowEditAccountDialog(true)}
                disabled={props.loadingData}
              />
            </>
          }
          title={
            <EllipsisText
              width={200}
              text={props.tenant.tenant_name || ''}
            />
          }
        />

        <CardContent>
          <div className="contactCardContainer">
            <div className="contactCardImageContainer">
              <label className="contactCardImageLabel">
                <img
                  alt="companyLogo"
                  src="https://s3.amazonaws.com/assets.rovitracker.cloud/images/rovitrackerLogos/rovi-logo-120x78.png"
                />
              </label>
            </div>
            <div className="contactInfoTextContainer">
              {tenantsInfo ? (
                <div style={{ marginBottom: 20 }}>
                  <p className="contactText">
                    {props.tenant.tenant_name}
                  </p>
                  <p className="contactText">
                    {tenantsInfo.address}
                  </p>
                  <p className="contactText">
                    {tenantsInfo.city},{' '}
                    {tenantsInfo.state}{' '}
                    {tenantsInfo.postal_code}
                  </p>
                </div>
              ) : (
                <MessageOverlay
                  stayInBox
                  style={{ flex: 1, position: 'relative' }}
                >
                  No Address
                </MessageOverlay>
              )}

              {tenantsInfo ? (
                <div>
                  {tenantsInfo.phone && (
                    <p className="contactText">
                      Phone: {tenantsInfo.phone}
                    </p>
                  )}
                  {tenantsInfo.fax && (
                    <p className="contactText">
                      Fax: {tenantsInfo.fax}
                    </p>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </CardContent>
      </Card>
      {showEditAccountDialog ? (
        <EditAccountDialog
          dialogOpen={showEditAccountDialog}
          onClose={() => setShowEditAccountDialog(false)}
          onSave={props.onSave}
          tenant={props.tenant}
        />
      ) : null}
    </>
  )
}

export default ContactCard
