import { AssetTabAsset } from '../AssetsTabTypes'
import {
  EMonitoring,
  TMonitorTypes,
  ERentStatus,
  TRentStatusTypes,
  ERepairStatus,
  TRepairStatus,
} from '../../../types/inventory'

export default function allFilters(
  assets: AssetTabAsset[],
  searchValue: string,
  monitoring: TMonitorTypes,
  rentStatus: TRentStatusTypes,
  repairStatus: TRepairStatus,
  categoryId: string,
  userId: string,
  flagId: string,
  clientId: string,
  siteId: string
) {
  return filterSite(
    filterClient(
      filterFlag(
        filterUser(
          filterCategory(
            filterRentStatus(
              filterRepairStatus(
                filterMonitor(filterAssets(assets, searchValue), monitoring),
                repairStatus
              ),
              rentStatus
            ),
            categoryId
          ),
          userId
        ),
        flagId
      ),
      clientId
    ),
    siteId
  )
}

export function filterAssets(
  assets: AssetTabAsset[],
  searchValue: string
) {
  return assets.filter((t) => t.asset_info?.asset_name.includes(searchValue))
}

export function filterMonitor(
  assets: AssetTabAsset[],
  monitoring: TMonitorTypes
) {
  return assets.filter((t) => {
    switch (monitoring) {
      case EMonitoring.monitored:
        return !!t.hasDevice
      case EMonitoring.nonMonitored:
        return !t.hasDevice
      default:
        return true
    }
  })
}

export function filterRentStatus(
  assets: AssetTabAsset[],
  rentStatus: TRentStatusTypes
) {
  return assets.filter((t) => {
    switch (rentStatus) {
      case ERentStatus.onRent:
        return !!t.agreement
      case ERentStatus.offRent:
        return !!t.agreement
      default:
        return true
    }
  })
}

export function filterRepairStatus(
  assets: AssetTabAsset[],
  repairStatus: TRepairStatus
) {
  return assets.filter((t) => {
    switch (repairStatus) {
      case ERepairStatus.onRepair:
        return !!t.inForService
      case ERepairStatus.offRepair:
        return !!t.inForService
      default:
        return true
    }
  })
}

export function filterCategory(
  assets: AssetTabAsset[],
  category: string
) {
  if (category !== 'all') {
    return assets.filter((t) => category === t.asset_info?.category?.id.toString())
  } else {
    return assets
  }
}

export function filterUser(
  assets: AssetTabAsset[],
  userIdToSearchFor: string
) {
  if (userIdToSearchFor !== 'all') {
    return assets.filter(
      (asset) => userIdToSearchFor === asset.asset_info?.user?.id
    )
  } else {
    return assets
  }
}

export function filterFlag(assets: AssetTabAsset[], flag: string) {
  if (flag !== 'all') {
    return assets.filter((t) => {
      let foundFlag = false
      t.flags?.forEach((f) => {
        if (f.flagId === flag) {
          foundFlag = true
        }
      })
      return foundFlag
    })
  } else {
    return assets
  }
}

export function filterClient(assets: AssetTabAsset[], clientId: string) {
  if (clientId !== 'all') {
    return assets.filter((t) => {
      return t.agreement ? t.agreement.client.id === clientId : false
    })
  } else {
    return assets
  }
}

export function filterSite(assets: AssetTabAsset[], siteId: string) {
  if (siteId !== 'all') {
    return assets.filter((t) => {
      return t.agreement ? t.agreement.site.id === siteId : false
    })
  } else {
    return assets
  }
}
