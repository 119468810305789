import React from 'react'
import Link from '@mui/material/Link'
import '../../styles/components/inventory/FieldView.scss'
import { Field, title } from '../../components/metrics/field'
import LinearProgressBar from '../../components/metricUX/LinearProgressBar'
import moment from 'moment'
import { colors } from '../ux/roviTheme'
import Icon from '../Icon'
import { ERoutes } from '../routes/CheckRoutes'
import SignalStrengthIcon from '../ux/SignalStrengthIcon'

const largeSize = 104
const smallSize = 50

interface IViewProps {
  field: string
  value?: string | number | boolean
  equipId: string
}

export const ContainerViewSize = (props: IViewProps) => {
  switch (props.field) {
    case Field.SignalStrength:
    case Field.EngineHour:
    case Field.DeviceBattery:
    case Field.DeviceBatteryLevel:
    case Field.CabTemp:
    case Field.Current3:
    case Field.Current2:
    case Field.Current1:
    case Field.SolarBattery1:
    case Field.SolarBattery2:
    case Field.Battery:
    case Field.InMotion:
    case Field.Input4:
    case Field.Input3:
    case Field.Input2:
    case Field.Input1:
    case Field.Ignition:
      return smallSize
    case Field.Odometer:
    case Field.LastUpdate:
    case Field.GreaseLevel:
    case Field.Fuel:
      return largeSize
    default: {
      return smallSize
    }
  }
}

export const View = (props: IViewProps) => {
  if (props.value === null) {
    return <></>
  }
  switch (props.field) {
    case Field.SignalStrength: {
      return (
        <FieldBox
          title={title[props.field as Field]}
          info={<SignalStrengthIcon strength={props.value as number} />}
        />
      )
    }
    case Field.EngineHour: {
      return (
        <FieldBox
          title={title[props.field as Field]}
          info={
            <Link href={ERoutes.equipment + '/56#tab=reports' /**props.equipId*/}>
              {props.value}h
            </Link>
          }
          infoTitle={props.value + 'h'}
        />
      )
    }
    case Field.Odometer: {
      return (
        <FieldBox
          title={title[props.field as Field]}
          info={
            <Link href={ERoutes.equipment + '/56#tab=reports' /**props.equipId*/}>
              {props.value}mi
            </Link>
          }
          infoTitle={props.value + 'mi'}
          large
        />
      )
    }
    case Field.DeviceBattery: {
      return (
        <FieldBox
          title={title[Field.DeviceBattery]}
          info={
            <div title={props.value === 'true' ? 'Good' : 'Needs Replacement'}>
              {props.value === 'true' ? (
                <Icon icon="batteryFull" style={{ color: colors.green }} />
              ) : (
                <Icon icon="batteryAlert" style={{ color: colors.red }} />
              )}
            </div>
          }
        />
      )
    }
    case Field.DeviceBatteryLevel: {
      return (
        <FieldBox
          title={title[props.field as Field]}
          info={<div>{props.value}%</div>}
          infoTitle={props.value + '%'}
        />
      )
    }
    case Field.CabTemp: {
      return (
        <FieldBox
          title={title[props.field as Field]}
          info={<div>{props.value}°F</div>}
          infoTitle={props.value + '°F'}
        />
      )
    }
    case Field.Current3:
    case Field.Current2:
    case Field.Current1: {
      return (
        <FieldBox
          title={title[props.field as Field]}
          info={<div>{props.value}A</div>}
          infoTitle={props.value + 'A'}
        />
      )
    }
    case Field.SolarBattery1:
    case Field.SolarBattery2:
    case Field.Battery: {
      return (
        <FieldBox
          title={title[props.field as Field]}
          info={<div>{props.value}V</div>}
          infoTitle={props.value + 'V'}
        />
      )
    }
    case Field.InMotion: {
      return (
        <FieldBox
          title={title[Field.LastUpdate]}
          info={<div>{props.value === 'true' ? 'Yes' : 'No'}</div>}
          infoTitle={props.value === 'true' ? 'Yes' : 'No'}
        />
      )
    }
    case Field.Input4:
    case Field.Input3:
    case Field.Input2:
    case Field.Input1:
    case Field.Ignition: {
      return (
        <FieldBox
          title={title[props.field as Field]}
          info={
            <div
              className="InputCircle"
              style={{
                background: props.value === true
                  ? colors.onGreen
                  : colors.offGrey,
              }}
            />
          }
          infoTitle={
            props.value === true
              ? 'On'
              : 'Off'
          }
        />
      )
    }
    case Field.LastUpdate: {
      return (
        <FieldBox
          title={title[Field.LastUpdate]}
          info={
            <div>
              {moment(moment(props.value as string).format()).fromNow()}
            </div>
          }
          large
          infoTitle={props.value?.toString()}
        />
      )
    }
    case Field.Fuel: {
      return (
        <FieldBox
          title={title[Field.Fuel]}
          info={
            props.value ? (
              <LinearProgressBar
                title={title[Field.Fuel] + ' '}
                level={props.value as number}
                size="medium"
                type="fuel"
              />
            ) : (
              <div></div>
            )
          }
          large
        />
      )
    }
    case Field.GreaseLevel: {
      return (
        <FieldBox
          title={title[Field.GreaseLevel]}
          info={
            props.value ? (
              <LinearProgressBar
                title={title[Field.GreaseLevel] + ' '}
                level={props.value as number}
                size="medium"
                type="grease"
              />
            ) : (
              <div></div>
            )
          }
          large
        />
      )
    }
    default: {
      return (
        <></>
      )
    }
  }
}

interface IFieldProps {
  title: string
  info: any
  infoTitle?: string
  width?: number
  large?: boolean
}

export const FieldBox = (props: IFieldProps) => {
  return (
    <div
      className="fieldBox"
      style={{
        width: props.width ? props.width : props.large ? largeSize : smallSize,
      }}
    >
      <div className="fieldTitle" title={props.title}>
        {props.title}
      </div>
      <div className="fieldInfo" title={props.infoTitle}>
        {props.info}
      </div>
    </div>
  )
}
