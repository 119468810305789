import React from 'react'
import { ImageWithDefaultEquip } from '../ux/ImageWithDefault'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import { standardIcons } from '../ux/standardIcons'
import IconButton from '../buttons/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Icon from '../Icon'
import classNames from 'classnames'
import Typography from '@mui/material/Typography'
import EllipsisText from '../ux/EllipsisText'
import { colors } from '../ux/roviTheme'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ICategory, TCategoryIcons } from '../../types/category'

const DarkTheme = createTheme({
  palette: {
    action: {
      active: '#fff',
    },
  },
  typography: {
    h6: {
      fontSize: '1rem',
      fontWeight: 400,
    },
  },
})

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      height: '100%',
    },
    childContainer: {
      flexShrink: 0,
      marginTop: 5,
    },
    headerRow: {
      display: 'flex',
      alignItems: 'center',
      height: 22,
      fontSize: 18,
      color: '#ffffff',
    },
    titleHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 18,
      flex: 1,
    },
    fixedAppbar: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      paddingRight: 10,
      zIndex: 3,
      display: 'flex',
      alignItems: 'center',
    },
    detailedAppbar: {
      flexShrink: 0,
      zIndex: 1,
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    appbar: {
      background: theme.palette.secondary.main,
      boxShadow: theme.shadows[2],
      color: 'white',
      boxSizing: 'border-box',
      minHeight: 60,
    },
    backButton: {
      position: 'absolute',
      top: 10,
      left: 10,
      height: 40,
      width: 40,
      borderRadius: 20,
      background: `rgba(0,0,0,0.15)`,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        background: `rgba(0,0,0,0.2)`,
      },
    },
    backButtonIcon: {
      filter: 'drop-shadow(0 0 2px rgba(0,0,0,0.4))',
    },
  })

interface IProps extends WithStyles<typeof styles> {
  // TODO: width should be 100%
  isMobile: boolean
  width: number
  navItems?: Array<{
    icon: keyof typeof standardIcons
    onClick?: () => void
    href?: string
    label: string
  }>
  imageUrl?: string
  onBack: () => void
  titleDense?: string
  title: React.ReactNode
  subTitle?: string
  details?: React.ReactNode
  icon?: TCategoryIcons
  isEquip?: boolean
  children?: React.ReactNode // Everything was breaking without this so added it when upgrading to Material-ui@v5.0.0-alpha.25 Feb 15th
  category?: ICategory
}

interface IState {
  anchorEl: HTMLElement | null
  isTop: boolean
}

class EntityDetailPanel extends React.Component<IProps, IState> {
  state: IState = {
    anchorEl: null,
    isTop: this.props.imageUrl || this.props.category ? false : true,
  }

  appbarRef = React.createRef<HTMLDivElement>()
  containerRef = React.createRef<HTMLDivElement>()

  handleScrollChange = () => {
    const appbar = this.appbarRef.current
    const container = this.containerRef.current

    if (appbar && container) {
      const appbarBounds = appbar.getBoundingClientRect()
      const containerBounds = container.getBoundingClientRect()

      const isTop = appbarBounds.top < containerBounds.top

      if (isTop !== this.state.isTop) {
        this.setState({ isTop })
      }
    }
  }

  handleOpenMenu = (ev: { currentTarget: any }) => {
    this.setState({ anchorEl: ev.currentTarget })
  }

  handleCloseMenu = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { classes, subTitle, navItems } = this.props

    const isImage = !!(this.props.imageUrl || this.props.category)

    return (
      <div style={{ height: '100%' }}>
        <div
          ref={this.containerRef}
          className={classes.container}
          onScroll={isImage ? this.handleScrollChange : undefined}
        >
          {isImage && (
            <>
              <div onClick={this.props.onBack} className={classes.backButton}>
                <ThemeProvider theme={DarkTheme}>
                  <Icon icon="back" className={classes.backButtonIcon} />
                </ThemeProvider>
              </div>
              <ImageWithDefaultEquip
                height={200}
                width={this.props.isMobile ? this.props.width : 350}
                imageUrl={this.props.imageUrl}
                categoryIcon={this.props.category?.icon}
              />
            </>
          )}

          {this.state.isTop && (
            <div className={classNames(classes.appbar, classes.fixedAppbar)}>
              <div
                className={classes.headerRow}
                style={{
                  flex: 1,
                  maxWidth: '88%',
                }}
              >
                <ThemeProvider theme={DarkTheme}>
                  <IconButton
                    variant="appbar"
                    icon="back"
                    onClick={this.props.onBack}
                  />
                </ThemeProvider>
                <div style={{ maxWidth: '88%' }}>
                  <EllipsisText
                    text={
                      this.props.titleDense
                        ? this.props.titleDense
                        : String(this.props.title)
                    }
                  ></EllipsisText>
                  <Typography
                    variant="body2"
                    color="inherit"
                    style={{ textOverflow: 'ellipsis', maxWidth: '100%' }}
                  >
                    {subTitle != null ? subTitle : ''}
                  </Typography>
                </div>
              </div>
              <ThemeProvider theme={DarkTheme}>
                {navItems &&
                  (this.props.isEquip ? (
                    navItems.map((item) => (
                      <IconButton
                        key={item.label}
                        icon={item.icon}
                        variant="row"
                        onClick={item.onClick}
                        href={item.href}
                        tooltip={item.label}
                      />
                    ))
                  ) : (
                    <IconButton
                      icon="overflowMenu"
                      variant="appbar"
                      onClick={this.handleOpenMenu}
                    />
                  ))}
              </ThemeProvider>
            </div>
          )}

          <div
            ref={this.appbarRef}
            className={classNames(classes.appbar, classes.detailedAppbar)}
            style={{
              visibility: this.state.isTop ? 'hidden' : 'visible',
            }}
          >
            <div className={classes.headerRow}>
              <div style={{ flex: 1 }}>{this.props.title}</div>
              <ThemeProvider theme={DarkTheme}>
                {navItems &&
                  (this.props.isEquip ? (
                    navItems.map((item, iconKey) => (
                      <div key={iconKey}>
                        <IconButton
                          icon={item.icon}
                          variant="row"
                          onClick={item.onClick}
                          href={item.href}
                          tooltip={item.label}
                        />
                      </div>
                    ))
                  ) : (
                    <IconButton
                      icon="overflowMenu"
                      variant="appbar"
                      onClick={this.handleOpenMenu}
                    />
                  ))}
              </ThemeProvider>
            </div>
            {this.props.details}
            {"Test1"}
          </div>

          <div className={classes.childContainer}>{this.props.children}</div>
        </div>

        {navItems && (
          <Menu
            anchorEl={this.state.anchorEl}
            open={!!this.state.anchorEl}
            onClose={this.handleCloseMenu}
            disableAutoFocusItem
          >
            {navItems.map((item) => {
              if (item.href) {
                return (
                  <MenuItem
                    key={item.label}
                    href={item.href}
                    dense
                  >
                    {/* Need to find better way to convert this color */}
                    <ListItemIcon>
                      <Icon
                        style={{ color: colors.iconColor }}
                        icon={item.icon}
                        fontSize="small"
                      />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </MenuItem>
                )
              } else {
                return (
                  <MenuItem
                    key={item.label}
                    onClick={() => {
                      ;(item as any).onClick()
                      this.handleCloseMenu()
                    }}
                    dense
                  >
                    {/* Need to find better way to convert this color */}
                    <ListItemIcon>
                      <Icon
                        style={{ color: colors.iconColor }}
                        icon={item.icon}
                        fontSize="small"
                      />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </MenuItem>
                )
              }
            })}
          </Menu>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(EntityDetailPanel)
