import React from 'react'
import EllipsisText from './EllipsisText'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import Link from '@mui/material/Link'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    atag: {
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.text.secondary,
      },
    },
  })

interface IProps extends WithStyles<typeof styles> {
  name: string
  badge: React.ReactNode
  href?: string
  style?: React.CSSProperties
}

const EntityChip = (props: IProps) => {
  const comp = (
    <div className={props.classes.root} style={props.style}>
      {props.badge}
      <EllipsisText text={props.name} style={{ marginLeft: 8 }}>
        {props.name}
      </EllipsisText>
    </div>
  )

  return props.href ? (
    <Link href={props.href} className={props.classes.atag}>
      {comp}
    </Link>
  ) : (
    comp
  )
}

export default withStyles(styles)(EntityChip)
