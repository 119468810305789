import { gql, useQuery, useMutation } from '@apollo/client'
import {useState} from 'react'
import {
  AmplifyUser,
} from '../types/account/user'
import {Flag} from '../constants/flagRules'

export const GET_FLAGS_BY_TENANT_ID =  gql`
  query GetFlagsByTenantId($tenant_id: uuid!) {
    alerts_flags(where: {
      tenant_id: {_eq: $tenant_id}
      archived: {_eq: false}
    }) {
      id
      name
      type
      flag_rules {
        id
        condition
        time
        apply_to_all_assets
      }
      flag_category_assignments {
        rule_id
        flag_id
        category_id
        tenant_id
        is_flagged
      }
      flag_asset_assignments {
        rule_id
        flag_id
        asset_id
        tenant_id
        is_flagged
      }
    }
  }
`

export function getFlags(
  amplifyUser: AmplifyUser|null
): {
  flags: Flag[]
} {
  const { loading, error, data, refetch } = useQuery(
    GET_FLAGS_BY_TENANT_ID,
    {
      variables: {
        tenant_id: amplifyUser?.['custom:tenantID'],
      },
      skip: !amplifyUser,
    }
  )
  let flags = []
  if (data?.alerts_flags) {
    for (const flag of data.alerts_flags) {
      let rules = []
      for (const rule of flag.flag_rules) {
        let condition
        try {
          condition = JSON.parse(rule.condition)
        } catch {
          condition = {
            type: 'all',
            conditions: [],
          }
        }

        let time
        try {
          time = JSON.parse(rule.time)
        } catch {
          time = {}
        }
        rules.push({
          ...rule,
          condition,
          time,
        })
      }
      flags.push({
        ...flag,
        flag_rules: rules,
      })
    }
  }
  return {
    flags,
  }
}

const INSERT_FLAG = gql`
mutation(
  $flag: alerts_flags_insert_input!,
  $flagRules: [alerts_flag_rules_insert_input!]!,
  $flagCategoryAssignments: [alerts_flag_category_assignments_insert_input!]!,
  $flagAssetAssignments: [alerts_flag_asset_assignments_insert_input!]!,
) {
  insert_alerts_flags_one(object: $flag) {
    id
  }

  insert_alerts_flag_rules(objects: $flagRules) {
    returning {
      id
    }
  }

  insert_alerts_flag_category_assignments(objects: $flagCategoryAssignments) {
    returning {
      category_id
    }
  }

  insert_alerts_flag_asset_assignments(objects: $flagAssetAssignments) {
    returning {
      asset_id
    }
  }
}
`

export function queryInsertFlag() {
  const [insertFlag, { loading, error, data }] = useMutation(
    INSERT_FLAG,
    {
      refetchQueries: [
        'GetFlagsByTenantId',
      ],
    }
  )
  return insertFlag
}

const UPDATE_FLAG = gql`
mutation(
  $id: uuid!
  $name: String!
  $type: String!
  $assetIds: [uuid!]!
  $ruleIds: [uuid!]!
  $categoryIds: [Int!]!
  $assets: [alerts_flag_asset_assignments_insert_input!]!
  $categories: [alerts_flag_category_assignments_insert_input!]!
  $rules: [alerts_flag_rules_insert_input!]!
) {
  update_alerts_flags_by_pk(
    pk_columns: {id: $id}
    _set: {
      name: $name
      type: $type
    }
  ) {
    id
  }

  deleteMissingAssets: delete_alerts_flag_asset_assignments(
    where: {
      flag_id: {_eq: $id}
      asset_id: {_nin: $assetIds}
    }
  ) {
    affected_rows
  }

  deleteMissingCategories: delete_alerts_flag_category_assignments(
    where: {
      flag_id: {_eq: $id}
      category_id: {_nin: $categoryIds}
    }
  ) {
    affected_rows
  }

  deleteAssetsByMissingRules: delete_alerts_flag_asset_assignments(
    where: {
      flag_id: {_eq: $id}
      rule_id: {_nin: $ruleIds}
    }
  ) {
    affected_rows
  }

  deleteCategoriesByMissingRules: delete_alerts_flag_category_assignments(
    where: {
      flag_id: {_eq: $id}
      rule_id: {_nin: $ruleIds}
    }
  ) {
    affected_rows
  }

  delete_alerts_flag_rules(
    where: {
      flag_id: {_eq: $id}
      id: {_nin: $ruleIds}
    }
  ) {
    affected_rows
  }

  insert_alerts_flag_rules(
    objects: $rules,
    on_conflict: {
      constraint: flag_rules_pkey,
      update_columns: [
        condition,
        time,
        apply_to_all_assets,
      ],
    }
  ) {
    returning {
      flag_id
      id
    }
  }

  insert_alerts_flag_asset_assignments(
    objects: $assets,
    on_conflict: {
      constraint: flag_assignment_pkey,
      update_columns: [],
    }
  ) {
    returning {
      flag_id
      asset_id
    }
  }

  insert_alerts_flag_category_assignments(
    objects: $categories,
    on_conflict: {
      constraint: flag_category_assignments_pkey,
      update_columns: [],
    }
  ) {
    returning {
      flag_id
      category_id
    }
  }
}
`


export function queryUpdateFlag() {
  const [updateFlag, { loading, error, data }] = useMutation(
    UPDATE_FLAG,
    {
      refetchQueries: [
        'GetFlagsByTenantId',
      ],
    }
  )
  return updateFlag
}

const ARCHIVE_FLAG = gql`
mutation($id: uuid!) {
  update_alerts_flags_by_pk(
    pk_columns: {id: $id}
    _set: {
      archived: true
    }
  ) {
    id
  }
}
`

export function queryArchiveFlag() {
  const [archiveFlag, { loading, error, data }] = useMutation(
    ARCHIVE_FLAG,
    {
      refetchQueries: [
        'GetFlagsByTenantId',
      ],
    }
  )
  return archiveFlag
}
