import React, {useState} from 'react'
import GoogleRoviMap, { IBounds } from '../GoogleRoviMap'
import LiveMaptracDrawerComponents from './LiveMaptracDrawerComponents'
import { TFocusType } from './LiveMaptracDrawerComponents'
import LiveMaptracMarkers from './LiveMaptracMarkers'
import { getHash, clearHash } from '../../../constants/hashGrabber'
import {
  MaptracHashFromString,
  MaptracHashToString,
  IMaptracHash,
  IMaptracFocus,
  EMaptracFocus,
} from '../../../constants/maptracHashController'
import HashStateManager from '../../../rx-js/HashStateManager'
import CurrentLocationButton from '../CurrentLocationButton'
import { backend_api, headersAPI } from '../../../constants/api'
import GeofenceButton from '../GeofenceButton'
import { ERadiusType, TRadiusType } from '../GeofenceDrawingManager'
import { ISite, IClientSites } from '../../../types/maptrac/Sites'
import { ITag } from '../../../types/maptrac/Tags'
import FilterMaptrac, {
  IMaintenance,
  maintenanceHashEncrypter,
  maintenanceHashDecrypter,
  splitToArr,
} from '../filters/FilterMaptrac'
import { ILatLng } from '../../../types/maptrac/LatLng'
import { Geofence } from '../../../types/geofence'
import { ICategory } from '../../../types/category'
import {
  IAssetClicked,
  IAssetList,
  IAsset,
} from '../../../types/maptrac/Assets'
import { findZoomLvl } from '../findZoomLvl'
import {
  filterAssetsDrawer,
  filterTagMarkers,
  filterAssetsMarker,
  filterAssetsMaintenanceMarker,
} from './MaptracAssetFilter'
import {
  AssetsSorter,
  TagsSorter,
  MaintenanceSorter,
  AssetCategorySorter,
} from './MaptracAssetSorter'
import { SitesSorter } from './MaptracSitesSorter'
import {
  filterSiteMarkers,
  filterClientSitesDrawer,
} from './MaptracSitesFilter'
import {
  filterGeofenceDrawer,
  filterGeofenceMarkers,
  filterGeofenceMarkersUUID,
} from './MaptracGeofenceFilter'
import getAllGeofenceData from '../../../apiCalls/getCalls/getAllGeofenceData'
import EditGeofence from '../EditGeofence'

import {
  getAmplifyUser,
  getAmplifySession,
  getCurrentUser,
} from '../../../api/user'

import {
  queryInsertGeofence,
  queryUpdateGeofence,
  queryDeleteGeofence,
} from '../../../api/geofence'

import {
  getMaptracData,
} from '../../../api/maptrac'

const LiveMaptracContainer = (props: {
  isMobile: boolean
  height: number
  width: number
  selectedDate: Date
  isLive: boolean
}) => {
  const parsedHash = new URLSearchParams(
    window.location.hash.substring(1) // skip the first char (#)
  )

  const [searchString, setSearchString] = useState(parsedHash.get('q') || '')
  const [BLETagsShown, setBleTagsShown] = useState(false)
  const [maintenance, setMaintenance] = useState({
    red: {
      value: 0,
      filter: true,
    },
    orange: {
      value: 0,
      filter: true,
    },
    blue: {
      value: 0,
      filter: true,
    },
  } as IMaintenance)

  const [center, setCenter] = useState(undefined as ILatLng|undefined)
  const [zoom, setZoom] = useState(undefined as number|undefined)

  const [categoriesFiltered, setCategoriesFiltered] = useState([] as string[])
  // const [focused, setFocused] = useState(parsedHash.get('focus') && parsedHash.get('id'))
  const [focused, setFocused] = useState(false)
  const [focusedType, setFocusedType] = useState(parsedHash.get('focus') || 'not_focused' as TFocusType)
  const [selectedId, setSelectedId] = useState(parsedHash.get('id') || null as string|null)
  const [assetBounds, setAssetBounds] = useState(undefined as IBounds|undefined)
  const [focusedAssetLatLng, setFocusedAssetLatLng] = useState(undefined as ILatLng|undefined)

  const [assetTagsLoading, setAssetTagsLoading] = useState(true)
  // BLE Tags
  const [tagMarkers, setTagMarkers] = useState([] as ITag[][])

  const [amplifyUser, setAmplifyUser] = getAmplifyUser()
  const {amplifySession} = getAmplifySession()
  const {
    geofences,
    loadingGeofences,
    assets,
    assetsLoading,
    assetsList,
    assetLatLng,
    branches,
    currentBranch,
    categories,
  } = getMaptracData(
    amplifyUser,
    amplifySession,
    props.selectedDate,
    props.isLive,
  )

  const {
    insertGeofence,
    isSaving: insertGeofenceIsSaving,
    error: insertGeofenceErrors,
  } = queryInsertGeofence(amplifyUser)

  const {
    updateGeofence,
    isSaving: updateGeofenceIsSaving,
    error: updateGeofenceErrors,
  } = queryUpdateGeofence()

  const {
    deleteGeofence,
    isDeleting: deleteGeofenceIsSaving,
    error: deleteGeofenceErrors,
  } = queryDeleteGeofence()

  const [geofenceBeingEdited, setGeofenceBeingEdited] = useState(undefined as Geofence|undefined)

  const [hash, setHash] = useState(
    new HashStateManager(
      MaptracHashFromString,
      MaptracHashToString
    ) as HashStateManager<IMaptracHash>
  )

  function setTab(focus: IMaptracFocus, id: string) {
    setFocused(true)
    setFocusedType(focus)
    setSelectedId(id)
    parsedHash.set('focus', focus)
    parsedHash.set('id', id)
    window.location.hash = parsedHash.toString()
  }

  function clearSelection() {
    setFocused(false)
  }

  function clickAsset(assetId: string) {
    clearSelection()
    setTab(EMaptracFocus.asset, assetId)
  }

  function clickGeofence(geofenceId: string) {
    clearSelection()
    setTab(EMaptracFocus.geofence, geofenceId)
  }

  function clickSite(siteId: string) {
    clearSelection()
    setTab(EMaptracFocus.site, siteId)
  }

  function updateCenterPosition(center: ILatLng, zoom: number) {
    setCenter(center)
    setZoom(zoom)
  }

  function assetMFilters() {
    return filterAssetsMaintenanceMarker(
      filterAssetsMarker(assets, searchString),
      maintenance.red.filter,
      maintenance.orange.filter,
      maintenance.blue.filter
    )
  }

  function getFilteredCategories(assetsList: IAssetList[]) {
    let newArr: string[] = []
    AssetCategorySorter(assetsList).forEach((c) => newArr.push(c.id))
    return newArr
  }

  return (
    <GoogleRoviMap
      isMobile={props.isMobile}
      height={props.height}
      width={props.width}
      onChangeCenter={(val) => console.log(val)}
      gpsCoords={assetLatLng}
      onClick={() => console.log('maplive c')}
    >
      {(map: google.maps.Map<Element>) => (
        <>
          <LiveMaptracDrawerComponents
            searchString={searchString}
            updateSearch={(val: string) =>
              setSearchString(val)
            }
            isMobile={props.isMobile}
            focused={focused}
            unFocus={() => {
              clearHash()
              setFocused(false)
            }}
            focusedType={focusedType}
            selectedId={selectedId}

            // Geofences
            geofences={filterGeofenceDrawer(
              geofences,
              searchString
            )}
            geofencesLoading={loadingGeofences}
            setEditedGeofence={(editedGeofence) =>
              setGeofenceBeingEdited(editedGeofence)
            }
            geofenceClicked={(val) => {
              const points = val.latlng[0].map(
                (pos: [number, number]) => ({
                  lng: pos[0],
                  lat: pos[1],
                })
              )
              console.log(val)
              setTab(EMaptracFocus.geofence, val.geofenceId)
              setTimeout(() => {
                clickGeofence(val.geofenceId)
                let bounds = new google.maps.LatLngBounds()
                points.forEach((path: google.maps.LatLng | ILatLng) =>
                  // adding each path to the boundary once this is full it should allow for some spacing along with it zooming on to the target.
                  bounds.extend(path)
                )
                if (val.type === ERadiusType.Circle) {
                  map.setCenter(val.latlng[0])
                  map.setZoom(findZoomLvl(val.radius))
                } else {
                  map.fitBounds(bounds)
                }
              }, 1)
            }}
            deleteGeofence={(geofence: Geofence) => {
              deleteGeofence(geofence.id)
            }}

            // Assets
            assets={assets}
            assetLists={filterAssetsDrawer(
              assetsList,
              searchString,
              maintenance
            )}
            assetsLoading={assetsLoading}
            assetClicked={(val: IAssetClicked) => {
              /** We need to panTo the asset, the panning should happen here */
              setTab(EMaptracFocus.asset, val.assetId)
              setSelectedId(val.assetId)
              setTimeout(() => {
                // I left it this way because it behaves the exact same as original maptrac version
                let bounds = new google.maps.LatLngBounds()
                bounds.extend(val.gps)
                map.fitBounds(bounds)
                // map.panTo(val.gps)
                // map.setZoom(11)
              }, 1)
            }}
            width={props.width}
            height={props.height}
          />
          {/* 
              According to Docs your suppose to wrap the google map around everything that modifys it 
              https://react-google-maps-api-docs.netlify.app/#section-getting-started 
            */}
          <LiveMaptracMarkers
            isMobile={props.isMobile}
            assetClicked={(val: IAssetClicked) => {
              clickAsset(val.assetId)
              setTimeout(() => {
                // I left it this way because it behaves the exact same as original maptrac version
                // map.panTo(val.gps)
                // map.setZoom(11)
                let bounds = new google.maps.LatLngBounds()
                bounds.extend(val.gps)
                map.fitBounds(bounds)
              }, 1)
            }}
            BLETagsShown={BLETagsShown}
            assetMarkers={assetMFilters()}
            geofenceClicked={(
              geofenceId: string,
              paths: google.maps.LatLng[] | ILatLng[],
              geofenceType: TRadiusType,
              radius: number
            ) => {
              console.log(paths)
              setTimeout(() => {
                clickGeofence(geofenceId)
                let bounds = new google.maps.LatLngBounds()
                paths.forEach((path: google.maps.LatLng | ILatLng) =>
                  // adding each path to the boundary once this is full it should allow for some spacing along with it zooming on to the target.
                  bounds.extend(path)
                )
                if (geofenceType === ERadiusType.Circle) {
                  map.setCenter(paths[0])
                  map.setZoom(findZoomLvl(radius))
                } else {
                  map.fitBounds(bounds)
                }
              }, 1)
            }}
            geofenceMarkers={filterGeofenceMarkersUUID(
              filterGeofenceMarkers(
                geofences,
                searchString
              ),
              geofenceBeingEdited
                ? geofenceBeingEdited.id
                : ''
            )}

            tagMarkers={
              BLETagsShown
                ? tagMarkers
                : filterTagMarkers(
                    tagMarkers,
                    searchString
                  )
            }
          />

          <GeofenceButton
            isMobile={props.isMobile}
            onSubmit={(geofence) => insertGeofence(geofence, currentBranch?.id)}
            errors={insertGeofenceErrors}
            isSaving={insertGeofenceIsSaving}
          />

          {geofenceBeingEdited ? (
            <EditGeofence
              isMobile={props.isMobile}
              isOpen
              onClose={() =>
                setGeofenceBeingEdited(undefined)
              }
              onSubmit={(updatedGeofence) => {
                updateGeofence(updatedGeofence)
              }}
              geofence={geofenceBeingEdited}
              isSaving={updateGeofenceIsSaving}
              errors={updateGeofenceErrors}
            />
          ) : null}

          <CurrentLocationButton
            isMobile={props.isMobile}
            centerUser={(location: ILatLng, zoom: number) => {
              map.panTo(location)
              map.setZoom(zoom)
            }}
            isLiveMap
          />

          <FilterMaptrac
            isMobile={props.isMobile}
            BLETagsShown={BLETagsShown}
            BLETagsToggle={(bool: boolean) => {
              setBleTagsShown(bool)
              setHash({
                ...hash.value,
                displayTags: bool,
              })
            }}
            maintenance={maintenance}
            changeMaintenance={(newMaintenance: IMaintenance) => {
              setMaintenance(newMaintenance)
              setHash({
                ...hash.value,
                tasks: maintenanceHashEncrypter(newMaintenance),
              })
            }}
            clearHash={() => setHash({})}
            categoriesList={categories}
            assets={assetsList}
            updateCategoriesFiltered={(val) => {
              setHash({
                ...hash.value,
                categories: val.toString(),
              })
              setCategoriesFiltered(val)
            }}
            categoriesFiltered={categoriesFiltered}
          />
        </>
      )}
    </GoogleRoviMap>
  )
}

export default LiveMaptracContainer
