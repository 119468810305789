import React from 'react'
import '../../styles/components/MobileAppbar.scss'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import IconButton from '../buttons/IconButton'
import IconButtonDrawer from '../buttons/IconButtonDrawer'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { colors } from '../ux/roviTheme'
import { ERoutes } from '../routes/CheckRoutes'
import RoviMainMenu from './RoviMainMenu'
import { ILinks } from '../../types/appbar'

const link: ILinks[] = [
  {
    name: 'Dashboard',
    link: ERoutes.dashboard,
    icon: 'dash',
  },
  {
    name: 'Yard',
    link: ERoutes.yard,
    icon: 'yard',
  },
  {
    name: 'Clients',
    link: ERoutes.client,
    icon: 'clients',
  },
  {
    name: 'Assets',
    link: ERoutes.inventory,
    icon: 'equipment',
  },
  {
    name: 'MapTrac',
    link: ERoutes.maptrac,
    icon: 'maptrac',
  },
  {
    name: 'Service',
    link: ERoutes.service,
    icon: 'service',
  },
  {
    name: 'Reports',
    link: ERoutes.fleetReport,
    icon: 'reports',
  },
]

const button = createTheme({
  palette: {
    action: {
      active: colors.white,
      hover: colors.borderColor,
    },
  },
})

const MobileRoviAppbar = (props: {
  title?: string
  length?: string | number
}) => {
  return (
    <>
      <AppBar position="fixed" color="primary" className="MobileAppbar">
        <Toolbar
          variant="dense"
          disableGutters
          className="MobileAppbarToolbar"
        >
          <div className="MobileAppbarLeft">
            <ThemeProvider theme={button}>
              <IconButtonDrawer icon="menu">
                <RoviMainMenu sections={link} />
              </IconButtonDrawer>
            </ThemeProvider>

            <Typography
              variant="h6"
              color="inherit"
              style={{ width: '100%' }}
            >
              {props.title}
            </Typography>
          </div>

          <div className="MobileAppbarRight">
            <ThemeProvider theme={button}>
              {props.title === 'Assets' ? (
                <>
                  ({props.length})
                  <IconButton
                    icon="flag"
                    variant="appbar"
                    href={ERoutes.flag}
                  />
                  <IconButtonDrawer icon="filter" anchor="right" />
                  <IconButton icon="search" variant="appbar" />
                </>
              ) : undefined}
              {
                props.title === 'Assets'
                || props.title === 'Client' ? (
                <IconButton icon="overflowMenu" variant="appbar" />
              ) : undefined}
            </ThemeProvider>
          </div>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default MobileRoviAppbar
