import React from 'react'
import IconButton from './IconButton'
import Popover from '@mui/material/Popover'
import { IStandardIcons } from '../ux/standardIcons'

interface IState {
  anchorEl: any
}

interface IProps {
  title: string
  icon: IStandardIcons
}

class IconButtonPopover extends React.Component<IProps, IState> {
  state: IState = {
    anchorEl: null,
  }

  render() {
    const props = this.props
    const state = this.state
    return (
      <div>
        <IconButton
          variant="appbar"
          icon={this.props.icon}
          onClick={(event: { currentTarget: any }) =>
            this.setState({ anchorEl: event.currentTarget })
          }
          tooltip={props.title}
        />
        <Popover
          open={!!state.anchorEl}
          anchorEl={state.anchorEl}
          onClose={() => this.setState({ anchorEl: null })}
          onClick={() => this.setState({ anchorEl: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {props.children}
        </Popover>
      </div>
    )
  }
}

export default IconButtonPopover
