import React, {ReactNode, useState} from 'react'
import '../../styles/ux/ServiceButton.scss'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Badge from '@mui/material/Badge'
import Link from '@mui/material/Link'
import Icon from '../Icon'
import { ERoutes } from '../routes/CheckRoutes'
import { colors } from './roviTheme'
import { backend_api, headersAPI } from '../../constants/api'
import ServicePopover, { colorLookup, getType } from './ServicePopover'
import classNames from 'classnames'

export function sort<T>(
  arr: Array<T>,
  compareFn?: (a: T, b: T) => number
): Array<T> {
  return [...arr].sort(compareFn)
}

export function calculateScheduleRatio(task: any, snapshot: any): number {
  const maintenanceTask = task.maintenance_one_time
  switch (maintenanceTask.interval_type) {
    case 'time':
      return 0
    case 'engine-hours':
      return 0
    case 'odometer':
      const lastPerformedValue = parseInt(task.performed_odometer)
      const intervalValue = parseInt(maintenanceTask.interval_value)
      const nextValue = lastPerformedValue + intervalValue
      const currentValue = snapshot?.odometer || 0
      return currentValue / nextValue
    default:
      console.error(`Interval type ${maintenanceTask.interval_type} is not supported.`, task)
  }
  return 0
}

const styles = createStyles({
  icon: {
    height: 16,
    width: 16,
    margin: '2px 0',
    color: colors.iconColor,
  },
  badge: {
    top: 2,
    height: 14,
    width: 14,
    minWidth: 14,
    fontSize: 10,
    color: 'white',
    background: colorLookup['none'],
  },
  badge_red: {
    background: colorLookup['red'],
  },
  badge_orange: {
    background: colorLookup['orange'],
  },
  badge_blue: {
    background: colorLookup['blue'],
  },
  badge_none: {
    background: colorLookup['none'],
  },
  root: {},
})

const ServiceButton: WithStyles<typeof styles> = (props: {
  assetId: string
  tasks: any[]
  classes: any
}) => {
  const [anchorEl, setAnchorEl] = useState(null as ReactNode)

  function findHighestRatio(
    tasksCheck: Array<{
      type: any
      task: any
      time: any
      scheduleRatio: string
    }>
  ) {
    let highestRatio = 0
    return highestRatio

    tasksCheck.forEach((task) => {
      console.log(calculateScheduleRatio(task))
      const ratio = parseFloat(task.scheduleRatio)
      highestRatio = ratio > highestRatio ? ratio : highestRatio
    })
    return highestRatio
  }

  function handlePopoverOpen(ev: { currentTarget: any }) {
    setAnchorEl(ev.currentTarget)
  }

  function handlePopoverClose() {
    setAnchorEl(null)
  }

  const badgeType = getType(findHighestRatio(props.tasks))
  return (
    <>
      {props.tasks.length > 0 ? (
        <Link href={ERoutes.service} onClick={(ev) => ev.stopPropagation()}>
          <Badge
            badgeContent={props.tasks.length}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            classes={{
              root: props.classes.root,
              badge: classNames(props.classes.badge, {
                [props.classes.badge_blue]: badgeType === 'blue',
                [props.classes.badge_none]: badgeType === 'none',
                [props.classes.badge_orange]: badgeType === 'orange',
                [props.classes.badge_red]: badgeType === 'red',
              }),
            }}
          >
            <Icon
              icon="service"
              className={props.classes.icon}
              fontSize="small"
            />
          </Badge>
        </Link>
      ) : null}
      <ServicePopover
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        tasks={props.tasks}
      />
    </>
  )
}

export default withStyles(styles)(ServiceButton)
