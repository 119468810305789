import React, { useState, useEffect } from 'react'
import '../../styles/components/AppBarLinks.scss'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { ILinks } from '../../types/appbar'
import { colors } from '../ux/roviTheme'


interface IState {
  selected?: string
}

interface IProps {
}

const AppBarLinks = (props: {
  links: ILinks[]
}) => {
  const [selected, setSelected] = useState(findCurrentTab(props.links))

  function findCurrentTab(links: ILinks[]): ILinks|undefined {
    const path = window.location.pathname
    return links.find(tab => tab.link === path)
  }

  function onLocationChange() {
    setSelected(findCurrentTab(props.links))
  }

  useEffect(() => {
    window.addEventListener('locationchange', onLocationChange, true)
    return function cleanup() {
      window.removeEventListener('locationchange', onLocationChange)
    }
  }, [])

  return (
    <Tabs
      value={selected?.tab}
      indicatorColor="secondary"
      className="linkContainer"
    >
      {props.links.map((link) => (
        <Tab
          key={link.tab}
          id={`desktopRoviAppBar${link.link}`}
          label={link.name}
          className="appBarLink"
          style={
            selected?.tab === link.tab
              ? { color: colors.defaultText }
              : undefined
          }
          href={link.link}
        />
      ))}
    </Tabs>
  )
}

export default AppBarLinks
