import React from 'react'
import AssetInformationCard from '../../components/equipmentComponents/AssetInformation'
import MetricsSection from '../../components/equipmentComponents/MetricsSection'
import AttributesSection from '../../components/equipmentComponents/AttributesSection'
import DeviceSection from '../../components/equipmentComponents/DeviceSection'
import UserSection from '../../components/equipmentComponents/UserSection'
import RentalRatesSection from '../../components/equipmentComponents/RentalRatesSection'
import UpcomingMaintenance from '../../components/equipmentComponents/UpcomingMaintenance'
import FlagsSection from '../../components/equipmentComponents/FlagsSection'
import ProfileImageUploader from '../../components/equipmentComponents/ProfileImageUploader'
import { AssetTabAsset } from '../../components/inventory/AssetsTabTypes'

interface IProps {
  asset?: AssetTabAsset
  isMobile: boolean
  useMetric: boolean
}

interface IState {}

class EquipmentInfoDesktop extends React.Component<IProps, IState> {
  state: IState = {}
  render() {
    return (
      <div
        data-testid="hello"
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          marginBottom: 56,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 113,
            width: '100%',
            height: '100%',
          }}
        >
          {/* Left Hand Side of Asset Profile Page */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1 0%',
              marginRight: 30,
              marginLeft: 20,
              maxWidth: 565,
            }}
          >
            {this.props.asset ? (
              <>
                <UpcomingMaintenance
                  tasks={this.props.asset.maintenance_task_performeds}
                  equipId={this.props.asset.id}
                />
                <AssetInformationCard
                  equipmentProfile={this.props.asset}
                  isMobile={false}
                  useMetric={this.props.useMetric}
                  equipId={this.props.asset.id}
                />
                <MetricsSection
                  useMetric={this.props.useMetric}
                  timezone="denver"
                  equipmentProfile={this.props.asset}
                  isMobile={this.props.isMobile}
                />
                <AttributesSection
                  customAttributes={
                    this.props.asset.customAttributes
                      ? this.props.asset?.customAttributes
                      : []
                  }
                />
              </>
            ) : null}
          </div>

          {/* Right Hand Side of Asset Profile Page */}
          <div style={{ maxWidth: 325, margin: '0 0 5px 0' }}>
            {this.props.asset ? (
              <>
                <ProfileImageUploader
                  images={
                    this.props.asset.imageUrl
                      ? this.props.asset.imageUrl
                      : []
                  }
                  category={this.props.asset.category}
                />
                <FlagsSection flags={this.props.asset.flags} />
                <DeviceSection
                  equipmentProfile={this.props.asset}
                  isMobile={this.props.isMobile}
                />
                <UserSection
                  assignedTo={this.props.asset.operator}
                  equipId={this.props.asset.id}
                  handleAddUser={() => console.log()}
                />
                <RentalRatesSection
                  equipId={this.props.asset.id}
                  rentalRates={this.props.asset.rentalRates}
                  isMobile={this.props.isMobile}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

export default EquipmentInfoDesktop
