import AccessTime from '@mui/icons-material/AccessTime'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AddAlertIcon from '@mui/icons-material/AddAlert'
import AddIcon from '@mui/icons-material/Add'
import AddUserIcon from '@mui/icons-material/PersonAdd'
import ArchiveIcon from '@mui/icons-material/Archive'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import BuildingIcon from '@mui/icons-material/LocationCity'
import BusinessIcon from '@mui/icons-material/Business'
import CalendarIcon from '@mui/icons-material/DateRange'
import CalibrateIcon from '@mui/icons-material/Timeline'
import CameraIcon from '@mui/icons-material/Videocam'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckIcon from '@mui/icons-material/Check'
import ClientsIcon from '@mui/icons-material/People'
import CloseIcon from '@mui/icons-material/Close'
import CloudIcon from '@mui/icons-material/Cloud'
import CopyIcon from '@mui/icons-material/FileCopy'
import CreateIcon from '@mui/icons-material/Create'
import CsvIcon from '../../svg-icons/CsvIcon'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DeleteIcon from '@mui/icons-material/Delete'
import DescriptionIcon from '@mui/icons-material/Description'
import DeviceHubIcon from '@mui/icons-material/DeviceHub'
import DevicesIcon from '../../svg-icons/DevicesIcon'
import DomainIcon from '@mui/icons-material/Domain'
import DoneIcon from '@mui/icons-material/Done'
import DoubleFastForwardIcon from '../../svg-icons/DoubleFastForward'
import EditIcon from '@mui/icons-material/Edit'
import EmailIcon from '@mui/icons-material/Email'
import EquipmentIcon from '../../svg-icons/EquipmentIcon'
import EventsIcon from '@mui/icons-material/FormatAlignJustify'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FastForwardIcon from '@mui/icons-material/FastForward'
import FilterIcon from '../../svg-icons/FilterIcon'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import FlagIcon from '../../svg-icons/FlagIcon'
import FuelIcon from '../../svg-icons/FuelIcon'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import GeofenceIcon from '../../svg-icons/GeofenceIcon'
import HistoryIcon from '@mui/icons-material/History'
import InfoIcon from '@mui/icons-material/Info'
import InvoiceIcon from '@mui/icons-material/LocalAtm'
import LastPageIcon from '@mui/icons-material/LastPage'
import LiveUpdatesIcon from '../../svg-icons/LiveUpdatesIcon'
import LocationIcon from '@mui/icons-material/LocationSearching'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import MaintenanceIcon from '../../svg-icons/MaintenanceIcon'
import MaptracIcon from '../../svg-icons/MaptracIcon'
import MenuIcon from '@mui/icons-material/Menu'
import MonitorIcon from '@mui/icons-material/Timeline'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MoreTimeIcon from '../../svg-icons/MoreTimeIcon'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NotificationsIcon from '@mui/icons-material/Notifications'
import OpacityIcon from '@mui/icons-material/Opacity'
import PartsIcon from '@mui/icons-material/Category'
import PasswordIcon from '@mui/icons-material/VpnKey'
import PauseIcon from '@mui/icons-material/Pause'
import PeopleIcon from '../../svg-icons/PeopleIcon'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PersonIcon from '@mui/icons-material/Person'
import PlaceIcon from '../../svg-icons/PlaceIcon'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PrintIcon from '@mui/icons-material/Print'
import PublishIcon from '@mui/icons-material/Publish'
import RefreshIcon from '@mui/icons-material/Refresh'
import ReportsIcon from '@mui/icons-material/Assessment'
import RestoreIcon from '@mui/icons-material/Restore'
import RpmIcon from '../../svg-icons/RpmIcon'
import SaveIcon from '@mui/icons-material/Save'
import SearchIcon from '@mui/icons-material/Search'
import ServiceIcon from '@mui/icons-material/Build'
import ServiceTaskIcon from '../../svg-icons/UpcomingMaintenanceIcon'
import SettingsIcon from '@mui/icons-material/Settings'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import SignalCellularOffIcon from '@mui/icons-material/SignalCellularOff'
import SiteIcon from '../../svg-icons/SiteIcon'
import TimelineIcon from '../../svg-icons/HeartbeatIcon'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import TranfserIcon from '@mui/icons-material/SwapHoriz'
import UndoIcon from '@mui/icons-material/Undo'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import WarningIcon from '@mui/icons-material/Warning'
import BatteryFullIcon from '@mui/icons-material/BatteryFull'
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert'
import CoordEditIcon from '../../svg-icons/CoordEditIcon'
import AddGeofenceIcon from '../../svg-icons/AddGeofenceIcon'
import CoordIcon from '../../svg-icons/CoordIcon'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import BarChartIcon from '@mui/icons-material/BarChart'
import MultilineChartIcon from '@mui/icons-material/MultilineChart'
import IgnitionIcon from '../../svg-icons/IgnitionIcon'
import Forward10Icon from '@mui/icons-material/Forward10'
import Replay10Icon from '@mui/icons-material/Replay10'

export type IStandardIcons =
  | 'account'
  | 'add'
  | 'addAlert'
  | 'addGeofence'
  | 'addUser'
  | 'archive'
  | 'arrowDown'
  | 'arrowDropDown'
  | 'arrowLeft'
  | 'arrowRight'
  | 'arrowUp'
  | 'back'
  | 'barChart'
  | 'calendar'
  | 'calibrate'
  | 'camera'
  | 'cancel'
  | 'chart'
  | 'check'
  | 'checkAll'
  | 'client'
  | 'clientInfo'
  | 'clients'
  | 'close'
  | 'cloud'
  | 'create'
  | 'coord'
  | 'csv'
  | 'dash'
  | 'delete'
  | 'description'
  | 'deviceHub'
  | 'devices'
  | 'domain'
  | 'done'
  | 'doubleFastForward'
  | 'duplicate'
  | 'edit'
  | 'editCoord'
  | 'ellipsisMenu'
  | 'equipment'
  | 'evaluate'
  | 'events'
  | 'expandLess'
  | 'expandMore'
  | 'fastForward'
  | 'filter'
  | 'firstPage'
  | 'flag'
  | 'fuel'
  | 'fullscreen'
  | 'grease'
  | 'geofence'
  | 'history'
  | 'ignition'
  | 'info'
  | 'invite'
  | 'invoice'
  | 'key'
  | 'lastPage'
  | 'liveUpdates'
  | 'location'
  | 'lowPriority'
  | 'maintenance'
  | 'maptrac'
  | 'menu'
  | 'monitor'
  | 'moreTimezone'
  | 'multilineChart'
  | 'navigateBefore'
  | 'navigateNext'
  | 'noSignal'
  | 'notifications'
  | 'overflowMenu'
  | 'parts'
  | 'password'
  | 'pause'
  | 'people'
  | 'place'
  | 'playArrow'
  | 'pricesheet'
  | 'print'
  | 'profile'
  | 'radar'
  | 'recenterIcon'
  | 'refresh'
  | 'reinstate'
  | 'reports'
  | 'rpm'
  | 'save'
  | 'search'
  | 'service'
  | 'serviceTask'
  | 'session'
  | 'settings'
  | 'signOut'
  | 'site'
  | 'sold'
  | 'subscription'
  | 'syncAlt'
  | 'timeline'
  | 'timezone'
  | 'transfer'
  | 'unfold'
  | 'undo'
  | 'upload'
  | 'user'
  | 'userProfile'
  | 'visibility'
  | 'visibilityOff'
  | 'warning'
  | 'workOrder'
  | 'yard'
  | 'batteryFull'
  | 'batteryAlert'

/** Standard icons used throughout rovitracker */
export const standardIcons = {
  account: BusinessIcon,
  add: AddIcon,
  addAlert: AddAlertIcon,
  addGeofence: AddGeofenceIcon,
  addUser: AddUserIcon,
  archive: ArchiveIcon,
  arrowDown: ArrowDownIcon,
  arrowDropDown: ArrowDropDownIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  arrowUp: ArrowUpIcon,
  back: ArrowBackIcon,
  barChart: BarChartIcon,
  calendar: CalendarIcon,
  calibrate: CalibrateIcon,
  camera: CameraIcon,
  cancel: CancelPresentationIcon,
  chart: ShowChartIcon,
  check: CheckIcon,
  checkAll: CheckCircleIcon,
  client: BuildingIcon,
  clientInfo: AssignmentIcon,
  clients: ClientsIcon,
  close: CloseIcon,
  cloud: CloudIcon,
  create: CreateIcon,
  coord: CoordIcon,
  csv: CsvIcon,
  dash: DashboardIcon,
  delete: DeleteIcon,
  description: DescriptionIcon,
  deviceHub: DeviceHubIcon,
  devices: DevicesIcon,
  domain: DomainIcon,
  done: DoneIcon,
  doubleFastForward: DoubleFastForwardIcon,
  duplicate: CopyIcon,
  edit: EditIcon,
  editCoord: CoordEditIcon,
  ellipsisMenu: MoreHorizIcon,
  equipment: EquipmentIcon,
  evaluate: AttachMoneyIcon,
  events: EventsIcon,
  expandLess: ExpandLessIcon,
  expandMore: ExpandMoreIcon,
  fastForward: FastForwardIcon,
  filter: FilterIcon,
  firstPage: FirstPageIcon,
  flag: FlagIcon,
  fuel: FuelIcon,
  fullscreen: FullscreenIcon,
  grease: OpacityIcon,
  geofence: GeofenceIcon,
  history: HistoryIcon,
  ignition: IgnitionIcon,
  info: InfoIcon,
  invite: PersonAddIcon,
  invoice: InvoiceIcon,
  key: VpnKeyIcon,
  lastPage: LastPageIcon,
  liveUpdates: LiveUpdatesIcon,
  location: LocationIcon,
  lowPriority: LowPriorityIcon,
  maintenance: MaintenanceIcon,
  maptrac: MaptracIcon,
  menu: MenuIcon,
  monitor: MonitorIcon,
  moreTimezone: MoreTimeIcon,
  multilineChart: MultilineChartIcon,
  navigateBefore: NavigateBeforeIcon,
  navigateNext: NavigateNextIcon,
  noSignal: SignalCellularOffIcon,
  notifications: NotificationsIcon,
  overflowMenu: MoreVertIcon,
  parts: PartsIcon,
  password: PasswordIcon,
  pause: PauseIcon,
  people: PeopleIcon,
  place: PlaceIcon,
  playArrow: PlayArrowIcon,
  pricesheet: AttachMoneyIcon,
  print: PrintIcon,
  profile: AssignmentIcon,
  radar: TrackChangesIcon,
  recenterIcon: GpsFixedIcon,
  refresh: RefreshIcon,
  reinstate: RestoreIcon,
  reports: ReportsIcon,
  rpm: RpmIcon,
  save: SaveIcon,
  search: SearchIcon,
  service: ServiceIcon,
  serviceTask: ServiceTaskIcon,
  session: PersonIcon,
  settings: SettingsIcon,
  signOut: ExitToAppIcon,
  site: SiteIcon,
  sold: AttachMoneyIcon,
  subscription: EmailIcon,
  syncAlt: SyncAltIcon,
  timeline: TimelineIcon,
  timezone: AccessTime,
  transfer: TranfserIcon,
  unfold: UnfoldMoreIcon,
  undo: UndoIcon,
  upload: PublishIcon,
  user: PersonIcon,
  userProfile: AccountCircleIcon,
  visibility: Visibility,
  visibilityOff: VisibilityOff,
  warning: WarningIcon,
  workOrder: AssignmentIcon,
  yard: BusinessIcon,
  batteryFull: BatteryFullIcon,
  batteryAlert: BatteryAlertIcon,
  forward10: Forward10Icon,
  replay10: Replay10Icon,
}
