import React from 'react'
import '../styles/pages/Login.scss'
import TextField from '../components/textBoxs/TextField'
import Button from '../components/buttons/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'
import { Theme } from '@mui/material/styles/createTheme'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { darkMode } from '../components/ux/roviTheme'
import ReactHelmet from '../components/ReactHelmet'
import LoadingOverlay from '../components/LoadingOverlay'
import { ERoutes } from '../components/routes/CheckRoutes'
import { Auth } from 'aws-amplify'
import WidthViewport from '../components/ux/WidthViewport'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '../components/buttons/IconButton'

const DarkTheme = (theme: Theme) =>
  createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#0078FF',
      },
      background: {
        paper: darkMode ? theme.palette.grey[700] : theme.palette.grey[100],
      },
    },
  })

interface IProps {}

interface IState {
  emailError: string
  passwordError: string
  email: string
  password: string
  loading: boolean
  passwordVisible: boolean
  loginError: string
}

class Login extends React.Component<IProps, IState> {
  state: IState = {
    emailError: '',
    passwordError: '',
    email: '',
    password: '',
    loading: false,
    passwordVisible: false,
    loginError: '',
  }

  // "Chrome" for Android and browser, "CriOS" for iOS Chrome app
  isChrome =
    navigator.userAgent.indexOf('Chrome') > -1 ||
    navigator.userAgent.indexOf('CriOS') > -1

  onSubmitForm = () => {}

  onChangeEmail = () => {}

  onChangePassword = () => {}

  async signIn() {
    this.setState({ loading: true })
    try {
      await Auth.signIn(this.state.email, this.state.password)
    } catch (err) {
      console.log(err)
      switch (err.code) {
        case 'NetworkError':
          this.setState({
            emailError:
              'Our Servers are having Issues, please allow a few hours or less to resolve the issue sorry for the inconvience',
          })
          break
        case 'wrongEmail':
          this.setState({ emailError: 'Email was invalid' })
          break
        default:
          this.setState({ emailError: err.code })
          break
      }
      this.setState({ loading: false })
    }
  }

  onLogin(event?: any) {
    /**
     * This preventDefault is required otherwise it will refresh the
     * page in the middle of cognito submission, returning a networkError
     */
    event?.preventDefault()
    this.signIn()
  }

  render() {
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <Paper className="outerContainer" square>
            <ReactHelmet title="Login" />
            <LoadingOverlay loading={this.state.loading} size={35} />
            <h1 className="logoh1">
              <a title="Rovi Tracker" href="https://www.rovitracker.com">
                <img
                  src="https://s3.amazonaws.com/assets.rovitracker.cloud/images/rovitrackerLogos/rovi-logo-320x204.png"
                  className="logo"
                  alt="Rovi tracker"
                />
              </a>
            </h1>

            <div
              className="loginContainer"
              style={{
                minWidth: isMobile ? undefined : 325,
                width: isMobile ? '100%' : undefined,
              }}
            >
              <ThemeProvider theme={DarkTheme}>
                <Paper className="loginInner" square>
                  <div className="loginTitle">
                    <Typography variant="h5" align="center">
                      Login
                    </Typography>
                  </div>
                  <form className="login-form">
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      autoComplete="email"
                      error={!!this.state.emailError}
                      helperText={this.state.emailError}
                      style={{ width: isMobile ? '100%' : 256 }}
                      type="email"
                      onChange={(event) =>
                        this.setState({ email: event.target.value })
                      }
                    />
                    <div className="loginTextpassword">
                      <TextField
                        id="password"
                        name="password"
                        label="Password"
                        autoComplete="current-password"
                        error={!!this.state.passwordError}
                        helperText={this.state.passwordError}
                        style={{ width: isMobile ? '100%' : 256 }}
                        type={this.state.passwordVisible ? 'text' : 'password'}
                        onChange={(event) =>
                          this.setState({ password: event.target.value })
                        }
                        onEnter={this.onLogin.bind(this)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              id="loginVisibilityToggle"
                              variant="appbar"
                              icon={
                                this.state.passwordVisible
                                  ? 'visibility'
                                  : 'visibilityOff'
                              }
                              onClick={() =>
                                this.setState({
                                  passwordVisible: !this.state.passwordVisible,
                                })
                              }
                            />
                          </InputAdornment>
                        }
                      />
                    </div>

                    <div className="signInContainer">
                      <Link href={ERoutes.forgot} className="forgotPassword">
                        Forgot password?
                      </Link>

                      <Button
                        id="LoginSignInButton"
                        variant="contained"
                        color="primary"
                        onClick={this.onLogin.bind(this)}
                      >
                        Sign in
                      </Button>
                    </div>
                  </form>
                </Paper>

                {!this.isChrome && (
                  <div className="chromeBroswerRec">
                    <a href="https://www.google.com/chrome/">Chrome Browser</a>{' '}
                    Recommended
                  </div>
                )}

                <Paper className="bottomInner" square>
                  <div className="signUpOuter">
                    <div className="notaMem">Not a member yet?</div>
                    <Link href={ERoutes.signup} className="signupNow">
                      Sign up now
                    </Link>
                  </div>

                  <div className="freeClient">
                    For a free client account, please contact your vendor for an
                    invitation.
                  </div>
                </Paper>
              </ThemeProvider>
            </div>
          </Paper>
        )}
      </WidthViewport>
    )
  }
}

export default Login
