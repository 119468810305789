import React from 'react'
import WidthViewport from '../ux/WidthViewport'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import { ListImagePlace } from '../ux/ListImage'
import Flag from '../ux/Flag'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import { colors } from '../ux/roviTheme'
import { backend_api, headersAPI } from '../../constants/api'
import LoadingOverlay from '../LoadingOverlay'

interface IDeviceList {
  deviceESN: string
  device: string
}

interface IAreaList {
  areaId: string
  areaName: string
  areaImageUrl?: string
  flags?: {
    flagId: string
    flagName: string
  }[]
  areaDevices?: IDeviceList[]
}

interface IPlacesList {
  placeId: string
  placeName: string
  placeAddress: string
  placeImageUrl?: string
  placeAreas: IAreaList[]
}

const styles = createStyles({
  listItemContainer: {
    width: '100%',
    margin: '10px 0 12px',
  },
  titleRowContainer: {
    display: 'flex',
    height: 72,
    cursor: 'pointer',
  },
  placeName: {
    fontSize: 18,
    height: 34,
    display: 'flex',
    alignItems: 'flex-end',
  },
  placeAddress: {
    color: colors.textGrey,
    height: 22,
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: 14,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
  },
  areaName: {
    fontSize: 16,
    height: 34,
    display: 'flex',
    alignItems: 'flex-end',
  },
})

interface IProps extends WithStyles<typeof styles> {}

interface IState {
  placesVal: IPlacesList[]
  placesLoading: boolean
}

class PlacesTab extends React.Component<IProps, IState> {
  state: IState = {
    placesVal: [],
    placesLoading: true,
  }

  grabPlaces() {
    fetch(backend_api + '/places', { method: 'GET', headers: headersAPI })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          placesVal: response,
          placesLoading: false,
        })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.grabPlaces()
  }

  render() {
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <>
            <LoadingOverlay loading={this.state.placesLoading} />
            <div
              style={
                isMobile
                  ? { width: '100vw', marginTop: 58, padding: '10px 12px' }
                  : { padding: '10px 12px' }
              }
            >
              {this.state.placesVal.map((place: IPlacesList, key) => (
                <Paper
                  className={this.props.classes.listItemContainer}
                  key={key}
                >
                  <Link className={this.props.classes.titleRowContainer} href="/">
                    <ListImagePlace
                      imageUrl={place.placeImageUrl}
                      containerStyle={{ margin: '0 16px', alignSelf: 'center' }}
                    />

                    <div className={this.props.classes.flexColumn}>
                      <div className={this.props.classes.placeName}>
                        {place.placeName}
                      </div>
                      <div className={this.props.classes.placeAddress}>
                        {place.placeAddress}
                      </div>
                    </div>
                  </Link>

                  {place.placeAreas.map((area: IAreaList, areaKey) => (
                    <div key={areaKey}>
                      <Divider />

                      <div style={{ padding: 16, paddingTop: 0 }}>
                        <Link
                          className={this.props.classes.titleRowContainer}
                          href="/"
                        >
                          <ListImagePlace
                            imageUrl={area.areaImageUrl}
                            containerStyle={{
                              margin: '0 16px',
                              alignSelf: 'center',
                            }}
                          />

                          <div className={this.props.classes.flexColumn}>
                            <div className={this.props.classes.areaName}>
                              {area.areaName}
                            </div>
                            <div className={this.props.classes.flexRow}>
                              {area.flags
                                ? area.flags.map((flag, flagKey) => (
                                    <div style={{ margin: 3 }} key={flagKey}>
                                      <Flag
                                        key={flagKey}
                                        flagId={flag.flagId}
                                        name={flag.flagName}
                                      />
                                    </div>
                                  ))
                                : null}
                            </div>
                          </div>
                        </Link>

                        {/* <AssetSensorChips assetId={`area:${area.id}`} /> */}
                      </div>
                    </div>
                  ))}
                </Paper>
              ))}
            </div>
          </>
        )}
      </WidthViewport>
    )
  }
}

export default withStyles(styles)(PlacesTab)
