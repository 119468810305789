import React from 'react'
import { TextField, Theme } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import { FlagDialogViewType } from './FlagDialog'
import RulesContainer from './RulesContainer'
import Margin from 'src/components/ux/Margin'
import {
  NewFlag,
  IRule,
  flagConditionRuleFormatter,
  getNewEmptyFlag,
  isAnyTime,
} from 'src/constants/flagRules'
import {
  CategoryWithAssets,
} from 'src/types/category'
import IconButton from 'src/components/buttons/IconButton'
import { IEquipEntity, IGeofenceEntity } from '../../../types/maptrac/playback'
import CategoryChip from '../../ux/CategoryChip'
import LineButton from '../../buttons/LineButton'

interface IMainViewProps extends WithStyles<typeof styles> {
  flag: NewFlag
  onChangeView: (newView: FlagDialogViewType, rule: IRule) => void
  onRemoveRule: (rule: IRule) => void
  onChangeRule: (fields: Partial<NewFlag>) => void
  categories: CategoryWithAssets[]
  geofences: Record<string, IGeofenceEntity>
  useMetricMeasurement?: boolean
}

interface Asset {
  asset_id: string
  asset_name: string
}

const MainView = (props: IMainViewProps) => {
  const categoryMap: Record<string, CategoryWithAssets> = props.categories.reduce(
    (map: Record<string, CategoryWithAssets>, category: CategoryWithAssets) => {
      return {
        ...map,
        [category.id]: category,
      }
    }, {}
  )

  const assetMap = props.categories.reduce<Record<string, Asset>>(
    (map: Record<string, Asset>, category: CategoryWithAssets) => {
      const tempAssetMap = category.asset_infos.reduce(
        (tempMap, asset) => {
          return {
            ...tempMap,
            [asset.asset_id]: asset,
          }
        },
        {}
      )

      return {
        ...map,
        ...tempAssetMap,
      }
    }, {}
  )

  const onChangeFlagName = (newValue: string) => {
    props.onChangeRule({ name: newValue })
  }

  const flagRules = props.flag.flag_rules.data
  return (
    <>
      <TextField
        id="name"
        autoFocus
        label="Flag Name"
        value={props.flag.name}
        onChange={(e) => onChangeFlagName(e.target.value)}
        className={props.classes.name}
        placeholder="i.e. Low Fuel"
        variant="standard"
      />
      {flagRules.map((rule) => (
        <div key={rule.id} className={props.classes.ruleRow}>
          <div className={props.classes.ruleBoxes}>
            <RulesContainer
              onClick={() => props.onChangeView('assets', rule)}
              label="Applies to:"
            >
              {
                rule.apply_to_all_assets
                && <div key="all" className={props.classes.ruleTextSection}>
                  Any Asset
                </div>
              }

              {props.flag.flag_category_assignments.data.map((categoryAssignment) => {
                if (categoryAssignment.rule_id !== rule.id) {
                  return (<></>)
                }

                const category: CategoryWithAssets = categoryMap[categoryAssignment.category_id]
                  || {
                    id: -1,
                    name: 'Loading category...',
                  }
                return (
                  <CategoryChip
                    key={category.id}
                    name={
                      category.name + (category.deleted ? ' (Deleted)' : '')
                    }
                    color={category.color}
                    icon={category.icon}
                    style={{ margin: 2 }}
                    button
                    disabled
                  />
                )
              })}

              {props.flag.flag_asset_assignments.data.map((assetAssignment) => {
                if (assetAssignment.rule_id !== rule.id) {
                  return (<></>)
                }

                const asset = assetMap[assetAssignment.asset_id]
                  || {
                    asset_id: -1,
                    asset_name: 'Loading asset...',
                  }
                return (
                  <div
                    key={assetAssignment.asset_id}
                    className={props.classes.ruleTextSection}
                  >
                    {asset.asset_name}
                  </div>
                )
              })}
            </RulesContainer>
            <Margin width={1} />
            <RulesContainer
              onClick={() => props.onChangeView('condition', rule)}
              label="When:"
            >
              {rule.condition.conditions.length > 1
                ? `${rule.condition.type.toUpperCase()} are true`
                : null}
              {rule.condition.conditions.map((condition, conditionIndex) => (
                <div
                  key={conditionIndex}
                  className={props.classes.ruleTextSection}
                >
                  {flagConditionRuleFormatter(
                    condition,
                    props.geofences,
                    props.useMetricMeasurement
                  )}
                </div>
              ))}
            </RulesContainer>
            <Margin width={1} />
            <RulesContainer
              label="During:"
              onClick={() => props.onChangeView('time', rule)}
            >
              {rule.time.map((time, timeIndex) =>
                isAnyTime(time) ? (
                  <div
                    key={timeIndex}
                    className={props.classes.ruleTextSection}
                  >
                    Any Time
                  </div>
                ) : (
                  <div
                    key={timeIndex}
                    className={props.classes.ruleTextSection}
                  >
                    {time.days.join(', ').toUpperCase()} - {time.hours[0]} to{' '}
                    {time.hours[1]}
                  </div>
                )
              )}
            </RulesContainer>
          </div>
          {flagRules.length > 1 && (
            <div className={props.classes.button}>
              <IconButton
                variant="row"
                icon="delete"
                onClick={() => props.onRemoveRule(rule)}
              />
            </div>
          )}
        </div>
      ))}
      <LineButton
        onClick={() =>
          props.onChangeRule({
            flag_rules: {
              data: [
                ...flagRules,
                ...getNewEmptyFlag().flag_rules.data,
              ]
            },
          })
        }
      >
        Add Ruleset
      </LineButton>
    </>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    flex: {
      flex: 1,
    },
    name: {
      marginBottom: 10,
    },
    rulesContainer: {
      padding: 10,
      boxShadow: 'inset 2px 2px 5px 0px rgba(0,0,0,0.2)',
    },
    ruleRow: {
      marginBottom: 10,
      display: 'flex',
    },
    ruleBoxes: {
      display: 'flex',
      boxShadow: theme.shadows[2],
      maxHeight: 160,
      flex: 1,
    },
    ruleTextSection: {
      margin: 4,
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 10,
    },
  })

export default withStyles(styles)(MainView)
