import React from 'react'
import '../../styles/pages/OtherUser.scss'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { IUserInfo } from '../../types/account/user'
import { assetProfile } from '../../constants/urlBuilder'
import { colors } from '../../components/ux/roviTheme'

interface IProps {
  userData: IUserInfo
  loading: boolean
}

class AssignedAssets extends React.Component<IProps> {
  state = {}

  render() {
    return (
      <TableContainer component={Paper} className="otherUserTable">
        <Typography variant="h6" component="div" className="otherUserTitle">
          Assigned Assets:
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Assets</TableCell>
              <TableCell>Make/Model</TableCell>
              <TableCell>Client/Site</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.userData.assignedAssets.length > 0 ? (
              this.props.userData.assignedAssets.map((asset, id) => (
                <TableRow hover key={id + asset.id}>
                  <TableCell>
                    <Link href={assetProfile(asset.id)}>{asset.name}</Link>
                  </TableCell>
                  <TableCell>
                    <div>
                      {asset.Make}
                      <br />
                      <span
                        style={{
                          color: colors.textGrey,
                        }}
                      >
                        {asset.Model}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    {asset.agreement ? (
                      <>
                        <div>
                          {asset.agreement.client.name}
                          <br />
                          <span
                            style={{
                              color: colors.textGrey,
                            }}
                          >
                            {asset.agreement.site.name}
                          </span>
                        </div>
                      </>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))
            ) : !this.props.loading ? (
              <TableRow>
                <TableCell colSpan={3}>No Assets Assigned</TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default AssignedAssets
