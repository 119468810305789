import React from 'react'
import CategoryAvatar from '../../../ux/CategoryAvatar'
import AssetListItem from './AssetListItem'
import MessageOverlay from '../../../ux/MessageOverlay'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import { colors } from '../../../ux/roviTheme'
import { LoadingIcon } from '../../../LoadingOverlay'
import {
  IAssetList,
  IAsset,
  IAssetClicked,
} from '../../../../types/maptrac/Assets'
import { Virtuoso } from 'react-virtuoso'
import { AssetTabAsset } from '../../../inventory/AssetsTabTypes'
import {
  getAssetImage,
  getAssetFlags,
} from '../../../../api/asset'

const LiveMaptracListAsset = (props: {
  data: IAssetList[]
  onClick: (val: IAssetClicked) => void
  onHoverIn: (id: any) => void
  onHoverOut: () => void
  loading: boolean
  searchString: string
  isMobile: boolean
  width: number
  height: number
}) => {
  return (
    <>
      <MessageOverlay belowTheSurface isVisible={props.data.length === 0}>
        {props.loading ? <LoadingIcon loading /> : 'No Assets'}
      </MessageOverlay>
      {
        props.data.length
        ? <Virtuoso
            totalCount={props.data.length}
            initialItemCount={props.data.length}
            style={{
              height: props.isMobile
                ? props.height - 320
                : props.height - 163,
            }}
            /** this value is smaller than the inventory page due to maptrac also being rendered on screen at the same time, this one is rendering a maximum of 50 at a time */
            overscan={3800}
            itemContent={(index) => {
              const assetList = props.data[index]
              if (assetList.assets.length === 0) {
                return <div style={{ visibility: 'hidden' }}>no data warning</div>
              }

              return (
                <List
                  key={index}
                  subheader={
                    <ListSubheader
                      style={{
                        background: colors.bg,
                        zIndex: 2,
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CategoryAvatar
                          size={24}
                          color={assetList.category.color}
                          icon={assetList.category.icon}
                        />
                        <div
                          title={assetList.category.name}
                          style={{ marginLeft: 10 }}
                        >
                          {`${assetList.category.name} (${assetList.assets.length})`}
                        </div>
                      </div>
                    </ListSubheader>
                  }
                >
                  {assetList.assets.map((asset: AssetTabAsset) => {
                    const assetImage = getAssetImage(asset)
                    const assetInfo = asset.asset_info
                    const flags = getAssetFlags(asset, [])
                    const snapshot = asset.snapshots?.[0]
                    const gps = snapshot?.estimated_lat_lng || {
                      lat: 0,
                      lng: 0,
                    }
                    const user = assetInfo?.user

                    return (
                      <AssetListItem
                        key={asset.id}
                        id={asset.id}
                        name={assetInfo?.asset_name || ''}
                        make={assetInfo?.asset_make || ''}
                        model={assetInfo?.asset_model || ''}
                        imageUrl={assetImage?.image_url || ''}
                        icon={assetList.category.icon}
                        // onClick={() => props.onClick(asset.id)}
                        onClick={() =>
                          props.onClick({ assetId: asset.id, gps })
                        }
                        tasks={asset.maintenance_task_performeds}
                        user={user}
                        hasCamera={asset.hasCamera}
                        battery={asset.battery}
                        ignition={asset.ignition}
                        onHoverIn={() => props.onHoverIn(asset.id)}
                        onHoverOut={props.onHoverOut}
                        flags={flags}
                      />
                    )
                  })}
                </List>
              )
            }}
          />
        : null
      }
    </>
  )
}

export default LiveMaptracListAsset
