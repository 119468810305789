import React, {useState} from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import TextField from '../../textBoxs/TextField'
import Button from '../../buttons/Button'
import ColorPicker from '../../ux/ColorPicker'
import IconPicker from '../../ux/IconPicker'
import InputLabel from '@mui/material/InputLabel'
import {
  ICategory,
  TCategoryIcons,
} from '../../../types/category'

const styles = (theme: Theme) =>
  createStyles({
    TextFieldMobile: {
      width: '100%',
      maxWidth: 256,
      marginBottom: 0,
    },
    TextField: {
      width: 256,
      marginBottom: 0,
    },
  })

const AddEditCategoryDialog = (props: {
  dialogOpen: boolean
  onClose: () => void
  onSave: (category: ICategory) => void
  type: 'edit' | 'add'
  isMobile?: boolean
  category: ICategory
  classes: any
}) => {
  const [category, setCategory] = useState({...props.category} as ICategory)
  function updateCategoryValue(
    key: keyof ICategory,
    value: any
  ) {
    setCategory({
      ...category,
      [key]: value,
    })
  }

  function handleClose() {
    props.onClose()
  }

  function onSubmit() {
    props.onSave(category)
    props.onClose()
  }

  function isSaveButtonDisabled() {
    return !category.name
      && !category.icon
      && !category.color
  }

  return (
    <Dialog
      open={props.dialogOpen}
      onClose={handleClose}
      fullWidth={props.isMobile}
    >
      <DialogTitle>
        {props.type === 'edit' ? 'Edit' : 'Add'} Category
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          label="Name"
          required
          className={
            props.isMobile
              ? props.classes.TextFieldMobile
              : props.classes.TextField
          }
          value={category.name}
          onChange={(event) => updateCategoryValue('name', event.target.value)}
          id="CategoryNameInputField"
        />
        <div style={{ marginTop: 20, display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <InputLabel shrink required>
              Color
            </InputLabel>
            <ColorPicker
              id="categoryColor"
              color={category.color}
              onChange={(color) => updateCategoryValue('color', color)}
              isMobile={props.isMobile}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <InputLabel shrink required>
              Icon
            </InputLabel>
            <IconPicker
              id="iconPickerButton"
              icon={category.icon}
              onChange={(icon) => updateCategoryValue('icon', icon)}
              isMobile={props.isMobile}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={isSaveButtonDisabled()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(AddEditCategoryDialog)
