import React from 'react'
import '../../styles/components/recentActivityComponents/recentActivity.scss'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import IconButton from '../buttons/IconButton'
import Typography from '@mui/material/Typography'
import { ERoutes } from '../routes/CheckRoutes'
import { backend_api, headersAPI } from '../../constants/api'
import { LoadingIcon } from '../LoadingOverlay'
import EllipsisText from '../ux/EllipsisText'
import moment from 'moment'

interface IState {
  activityList: Array<{
    user: string
    timeAgo: string
    what: string
    asset: string
    id: string
  }>
  loading: boolean
}

interface IProps {}

class recentActivity extends React.Component<IProps> {
  state: IState = {
    activityList: [],
    loading: true,
  }
  async grabRecentActivity() {
    await fetch(backend_api + '/recentActivity', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          activityList: response,
          loading: false,
        })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.grabRecentActivity()
  }

  render() {
    return (
      <>
        <div className="RecentActivityBox">
          <div className="ActivityRecentTitle">
            <Typography variant="h6">Recent Activity</Typography>
            {this.state.loading ? (
              <div style={{ marginLeft: 10 }}>
                <LoadingIcon loading />
              </div>
            ) : null}
            <IconButton
              id="recentActivityHistoryButton"
              icon="history"
              variant="appbar"
              href={ERoutes.history}
            />
          </div>
          {this.state.activityList.map((activity, key) => (
            <div key={key} className="ActivityCol">
              <div className="ActivityRow">
                <EllipsisText width={100}>
                  <Link href={ERoutes.equipment + '/' + activity.id}>
                    {activity.user}
                  </Link>
                </EllipsisText>
                <div>{moment(moment(activity.timeAgo).format()).fromNow()}</div>
              </div>
              <div className="ActivityLowerRow">
                <div>{activity.what}</div>
                <EllipsisText width={100}>
                  <Link href={ERoutes.equipment + '/' + activity.id}>
                    {activity.asset}
                  </Link>
                </EllipsisText>
              </div>
              <Divider />
            </div>
          ))}
        </div>
      </>
    )
  }
}

export default recentActivity
